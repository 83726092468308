import { DATA_TYPES, TABLE_SETTINGS } from '../../../utils/constants';
import Table from '../../Table/Table';

const SectionsTable = () => (
    <Table
        dataType={DATA_TYPES.section}
        tableName={TABLE_SETTINGS.category.name}
        tableHead={TABLE_SETTINGS.section.tableHead}
    />
);

export default SectionsTable;
