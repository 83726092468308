import { Stack } from '@mui/material';
import { Form as FormikForm } from 'formik';
import styled, { css } from 'styled-components';

const FORM_MAX_WIDTH = 640;
const TABLE_OFFSET = 50;

export const FormStyled = styled(FormikForm)`
    max-width: ${FORM_MAX_WIDTH}px;
    margin-left: -2rem;

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        margin-left: 0rem;
    }
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MarginContainer = styled.div`
    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        margin: 0 2rem;
    }
`;

export const MarginBlock = styled.div`
    margin: 1.5rem 0;
`;

export const NoPaddingBlock = styled.div`
    padding: 0;
`;

export const WidthContainer = styled.div`
    width: 100%;
    max-width: ${({ width }) => width};

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        max-width: 100%;
    }
`;

export const MarginInline = styled.div`
    ${({ size }) =>
        css`
            margin: 0 ${size ?? 1}rem;
        `}
    ${({ left, size }) =>
        left &&
        css`
            margin: 0 0 0 ${size ?? 1}rem;
        `}
    ${({ right, size }) =>
        right &&
        css`
            margin: 0 ${size ?? 1}rem 0 0;
        `}
`;

export const TitleContainer = styled.div`
    padding-bottom: 2rem;
`;

export const ButtonsContainer = styled.div`
    margin-top: 1rem;

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        padding-left: 2rem;
        padding-right: 2rem;
    }
`;

export const Field = styled.p`
    font-size: 1rem;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-left: 0.25rem;
`;

export const FieldContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const TableWrapper = styled.div`
    margin-bottom: 1rem;
    width: auto;

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        margin: 0 0 1rem 2rem;
        margin-right: 2rem;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

export const TextInfoBold = styled.span`
    font-weight: bold;
`;

export const TextInfo = styled.p``;

export const TableFormContainer = styled.div`
    width: 100%;
    height: 90%;

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        width: calc(100% - ${TABLE_OFFSET}px);
        margin-left: ${TABLE_OFFSET / 2}px;
    }
`;

export const StyledStack = styled(Stack)`
    margin-left: 2rem;

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        margin-left: 0;
    }
`;
