import { DATA_TYPES, TABLE_SETTINGS } from '../../../utils/constants';
import Table from '../../Table/Table';

const LogActivityTable = () => (
    <Table
        dataType={DATA_TYPES.logActivity}
        tableName={TABLE_SETTINGS.logActivity.name}
        tableHead={TABLE_SETTINGS.logActivity.tableHead}
    />
);

export default LogActivityTable;
