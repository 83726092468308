import { MenuItem, Select } from '@mui/material';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
    position: absolute;
    bottom: 5px;
    left: 5px;
    font-size: 12px;
    height: 35px;
`;

export const StyledMenuItem = styled(MenuItem)`
    font-size: 12px;
`;
