import Iconify from '../components/Iconify/Iconify';
import { CATEGORIES, NAV_LINKS } from './constants';
import { getPermissions } from './helpers';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const getNavConfig = (role) =>
    [
        {
            title: CATEGORIES.dashboard,
            path: NAV_LINKS.dashboard,
            icon: getIcon('eva:pie-chart-2-fill'),
            category: 0,
        },
        {
            title: CATEGORIES.administrators,
            path: NAV_LINKS.administrators,
            icon: getIcon('wpf:administrator'),
            category: 0,
        },
        {
            title: CATEGORIES.organizations,
            path: NAV_LINKS.organizations,
            icon: getIcon('octicon:organization-24'),
            category: 1,
        },
        {
            title: CATEGORIES.organizationUsers,
            path: NAV_LINKS.organizationUsers,
            icon: getIcon('gis:globe-users'),
            category: 1,
        },
        {
            title: CATEGORIES.participants,
            path: NAV_LINKS.participants,
            icon: getIcon('gridicons:multiple-users'),
            category: 1,
        },
        {
            title: CATEGORIES.participantGroups,
            path: NAV_LINKS.participantGroups,
            icon: getIcon('clarity:employee-group-line'),
            category: 1,
        },
        {
            title: CATEGORIES.questions,
            path: NAV_LINKS.questions,
            icon: getIcon('fluent:task-list-square-database-20-regular'),
            category: 2,
        },
        {
            title: CATEGORIES.packages,
            path: NAV_LINKS.packages,
            icon: getIcon('ph:package-light'),
            category: 2,
        },
        {
            title: CATEGORIES.quizScoring,
            path: NAV_LINKS.quizScoring,
            icon: getIcon('grommet-icons:scorecard'),
            category: 2,
        },
        {
            title: CATEGORIES.categories,
            path: NAV_LINKS.categories,
            icon: getIcon('ic:outline-category'),
            category: 3,
        },
        {
            title: CATEGORIES.grades,
            path: NAV_LINKS.grades,
            icon: getIcon('file-icons:easybuild'),
            category: 3,
        },
        {
            title: CATEGORIES.products,
            path: NAV_LINKS.products,
            icon: getIcon('carbon:product'),
            category: 4,
        },
        {
            title: CATEGORIES.orders,
            path: NAV_LINKS.orders,
            icon: getIcon('eva:shopping-cart-outline'),
            category: 4,
        },
        {
            title: CATEGORIES.logActivity,
            path: NAV_LINKS.logActivity,
            icon: getIcon('icon-park-outline:log'),
            category: 5,
        },
    ]
        .map((navItem) => ({
            ...navItem,
            visible: getPermissions(navItem.title, role).view,
        }))
        .filter((navItem) => navItem.visible);

export default getNavConfig;
