import { Stack, TextField } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { camelCase, isEqual, mapValues } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import { DATA_TYPES, FORM_TYPES, NAV_LINKS, TABLE_SETTINGS } from '../../../utils/constants';
import { createData, getDataById, updateData } from '../../../utils/fetchData';
import { getErrorLink, getTableFieldsObject, makeTitle } from '../../../utils/helpers';
import Form from '../../Form/Form';
import FormButtons from '../../Form/FormButtons/FormButtons';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';

const OrganizationForm = ({ formType, category }) => {
    const ORGANIZATION_SCHEMA = {
        name: '',
        short_name: '',
        nip: '',
        regon: '',
    };
    const NIP_LENGTH = 10;
    const REGON_LENGTH = 9;

    const [isFetching, setIsFetching] = useState(formType === FORM_TYPES.edit);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState(ORGANIZATION_SCHEMA);
    const { id: organizationId } = useParams();

    useEffect(() => {
        if (formType === FORM_TYPES.edit) {
            getDataById(DATA_TYPES.organization, organizationId)
                .then((res) => {
                    if (res.error) {
                        navigate(getErrorLink(camelCase(res.error)));
                    } else {
                        res = mapValues(res, (v) => (v === null ? '' : String(v)));
                        setValues(res);
                        setData(res);
                    }
                })
                .catch(() => navigate(NAV_LINKS.notFound))
                .finally(() => setIsFetching(false));
        }
    }, []);

    const checkLength = (val, maxLength) =>
        val && val.length === maxLength && Number(val).toString().length === maxLength;

    const DataSchema = Yup.object().shape({
        name: Yup.string()
            .min(4, t('organizationNameMinCharacters'))
            .max(255, t('organizationNameMaxCharacters'))
            .required(t('organizationNameRequired')),
        short_name: Yup.string()
            .max(20, t('organizationShortNameMaxCharacters'))
            .required(t('organizationShortNameRequired')),
        nip: Yup.string()
            .typeError(t('nipValidationType'))
            .test('len', t('nipValidationLength'), (val) => checkLength(val, NIP_LENGTH))
            .required(t('nipRequired')),
        regon: Yup.string()
            .nullable(true)
            .typeError(t('regonValidationType'))
            .test('len', t('regonValidationLength'), (val) => checkLength(val, REGON_LENGTH) || !val),
    });

    const formik = useFormik({
        initialValues: ORGANIZATION_SCHEMA,
        validationSchema: DataSchema,
        onSubmit: (values) =>
            formType === FORM_TYPES.add
                ? addOrganization(values)
                : updateOrganization(organizationId, values),
    });

    const {
        errors,
        touched,
        isValid,
        values,
        setValues,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
    } = formik;

    const addOrganization = (payload) => {
        const { nip, regon, ...data } = payload;

        createData(DATA_TYPES.organization, {
            ...data,
            nip: Number(nip),
            regon: regon ? Number(regon) : '',
        })
            .then((res) => {
                if (res.success) {
                    navigate(NAV_LINKS.organizations);
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                dispatch(openAlertError());
            })
            .finally(() => setSubmitting(false));
    };

    const updateOrganization = (id, payload) => {
        updateData(DATA_TYPES.organization, id, payload)
            .then((res) => {
                if (res.success) {
                    navigate(NAV_LINKS.organizations);
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                dispatch(openAlertError());
            })
            .finally(() => setSubmitting(false));
    };

    const isFormValid = () => isValid && Object.keys(touched).length && !isEqual(data, values);

    const createTextField = (field) => (
        <TextField
            key={field}
            id={field}
            name={field}
            label={t(getTableFieldsObject(TABLE_SETTINGS.organization.tableHead)[field])}
            value={values[field] ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched[field] && errors[field]}
            error={Boolean(touched[field] && errors[field])}
            onInput={() => setFieldTouched(field, true, true)}
        />
    );

    const generateTextFields = () => Object.keys(ORGANIZATION_SCHEMA).map((field) => createTextField(field));

    return isFetching ? (
        <LoadingOverlay fullScreen />
    ) : (
        <FormikProvider value={formik}>
            <Form title={makeTitle({ formType, category })} onSubmit={handleSubmit}>
                <Stack spacing={3} m={4}>
                    {generateTextFields()}
                    <FormButtons
                        navLink={NAV_LINKS.organizations}
                        formType={formType}
                        isFormValid={!!isFormValid()}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                    />
                </Stack>
            </Form>
        </FormikProvider>
    );
};

OrganizationForm.propTypes = {
    formType: PropTypes.oneOf([FORM_TYPES.add, FORM_TYPES.edit]).isRequired,
};

export default OrganizationForm;
