import { Button, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { WIDTHS } from '../../../../utils/constants';
import { CenterSpinner, getFormButtonsSx } from '../../../Form/FormButtons/FormButtons.styles';
import ModalFormButtonsContainer from './ModalFormButtons.styles';

const ModalFormButtons = ({ handleSubmit, handleClose, isFormValid, isSubmitting }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <ModalFormButtonsContainer>
            <Button variant="outlined" sx={getFormButtonsSx(theme)} onClick={handleClose}>
                {t('close')}
            </Button>
            <Button
                variant={'contained'}
                color={'primary'}
                disabled={!isFormValid() || isSubmitting}
                onClick={handleSubmit}
                sx={{ width: WIDTHS.button }}
            >
                {isSubmitting ? (
                    <CenterSpinner>
                        <ClipLoader size={15} color={theme.palette.common.white} />
                    </CenterSpinner>
                ) : (
                    t('apply')
                )}
            </Button>
        </ModalFormButtonsContainer>
    );
};

ModalFormButtons.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    isFormValid: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
};

export default ModalFormButtons;
