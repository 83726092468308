import PropTypes from 'prop-types';
import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { StyledImage } from './MediaImage.styles';

const MediaImage = ({ src }) => (
    <Zoom>
        <StyledImage src={src} />
    </Zoom>
);

MediaImage.propTypes = {
    src: PropTypes.string.isRequired,
};

export default MediaImage;
