import { useEffect, useState } from 'react';

const useBrowserWidth = () => {
    const [browserWidth, setBrowserWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => setBrowserWidth(window.innerWidth);

        if (typeof window !== 'undefined') {
            handleResize();
            window.addEventListener('resize', handleResize);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    return browserWidth;
};

export default useBrowserWidth;
