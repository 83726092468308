import React from 'react';
import { POINTS_INFO } from '../../utils/constants';
import { Gain, Loss, PointsPillWrapper } from './PointsPill.styles';

const PointsPill = ({ data, field }) => (
    <PointsPillWrapper>
        {POINTS_INFO.gain in data && <Gain>{data.gain[field]}</Gain>}
        {POINTS_INFO.loss in data && <Loss>{data.loss[field]}</Loss>}
    </PointsPillWrapper>
);

export default PointsPill;
