import styled from 'styled-components';
import DefaultOverlay from './DefaultOverlay';

const UserPackagesOverlay = styled(DefaultOverlay)`
    height: min-content;
    overflow: auto;

    tbody {
        tr {
            td {
                border: none;
                text-align: left;
            }
        }
    }
`;

export default UserPackagesOverlay;
