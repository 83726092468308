import { FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AutocompleteSelect from '../AutocompleteSelect/AutocompleteSelect';
import { SelectFormControl } from './SelectField.styles';

const SelectField = ({
    field,
    label,
    touched,
    errors,
    values,
    multiple,
    objectProperty,
    selectValues,
    handleChange,
    handleBlur,
    autocomplete,
    disabled,
    disableClearable,
    loading,
    maxWidth,
    hideOptions,
}) => {
    const SELECT_MAX_HEIGHT = 400;

    const { t } = useTranslation();

    const isSelectValuesObject = () =>
        selectValues !== null && typeof selectValues === 'object' && Array.isArray(selectValues) === false;

    const renderSelectValues = () =>
        isSelectValuesObject()
            ? Object.keys(selectValues).map((value, index) => (
                  <MenuItem key={index} value={selectValues[value]}>
                      {t(value)}
                  </MenuItem>
              ))
            : selectValues.map((value, index) => (
                  <MenuItem key={index} value={value?.id ?? value}>
                      {getValue(value)}
                  </MenuItem>
              ));

    const getLabel = (option) => {
        if (option?.short_name) {
            return option.short_name;
        }

        if (option?.name) {
            return option.name;
        }

        if (option?.title) {
            return option.title;
        }

        return '';
    };

    const getValue = (value) => {
        if (value?.short_name) {
            return value.short_name;
        }

        if (value?.name) {
            return value.name;
        }

        if (value?.title) {
            return value.title;
        }

        return value;
    };

    const getFieldTouched = () => {
        try {
            return Object.keys(objectProperty).length
                ? touched[field][objectProperty?.index][objectProperty?.property] &&
                      errors[field][objectProperty?.index][objectProperty?.property]
                : touched[field] && errors[field];
        } catch (e) {
            return '';
        }
    };

    if (!Object.keys(values)?.length) {
        return null;
    }

    return autocomplete ? (
        <AutocompleteSelect
            loading={loading}
            field={field}
            label={label}
            multiple={multiple}
            values={
                Object.keys(objectProperty).length
                    ? selectValues.filter(
                          (value) =>
                              !values[field].map((el) => el[objectProperty?.property]).includes(value.id)
                      )
                    : selectValues
            }
            value={
                selectValues.filter(
                    (value) =>
                        value.id ===
                        (Object.keys(objectProperty).length
                            ? values[field][objectProperty?.index][objectProperty?.property]
                            : values[field])
                )[0] ?? ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            maxWidth={maxWidth}
            helperText={getFieldTouched()}
            getOptionLabel={getLabel}
            error={Boolean(getFieldTouched())}
            onInput={() => {}}
            disabled={disabled}
            disableClearable={disableClearable}
        />
    ) : (
        <SelectFormControl key={field} hideOptions={hideOptions}>
            <InputLabel id={`${field}-label`}>{t(camelCase(label))}</InputLabel>
            <Select
                id={field}
                name={field}
                label={label}
                labelId={`${field}-label`}
                value={values[field]}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched[field] && errors[field])}
                MenuProps={{
                    style: {
                        maxHeight: SELECT_MAX_HEIGHT,
                    },
                }}
                disabled={disabled}
            >
                {renderSelectValues()}
            </Select>
            <FormHelperText error={Boolean(touched[field] && errors[field])}>
                {touched[field] && errors[field]}
            </FormHelperText>
        </SelectFormControl>
    );
};

SelectField.defaultProps = {
    touched: {},
    errors: {},
    handleBlur: () => {},
    disabled: false,
    disableClearable: false,
    objectProperty: {},
    selectValues: [],
    multiple: false,
    values: {},
    autocomplete: false,
    maxWidth: null,
    hideOptions: false,
};

SelectField.propTypes = {
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    touched: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
    objectProperty: PropTypes.object,
    selectValues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func,
    autocomplete: PropTypes.bool,
    disabled: PropTypes.bool,
    disableClearable: PropTypes.bool,
    multiple: PropTypes.bool,
    maxWidth: PropTypes.number,
    hideOptions: PropTypes.bool,
};

export default SelectField;
