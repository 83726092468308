import PropTypes from 'prop-types';
import { StyledMenuItem, StyledSelect } from './PaginationSelect.styles';

const PaginationSelect = ({ paginationSizes, selectedPaginationSize, changePaginationSize }) => {
    const renderPaginationOptions = () =>
        paginationSizes.map((size) => (
            <StyledMenuItem key={size} value={size}>
                {size}
            </StyledMenuItem>
        ));

    const menuPosition = { vertical: 'bottom', horizontal: 'left' };

    return (
        <StyledSelect
            value={selectedPaginationSize}
            onChange={changePaginationSize}
            MenuProps={{
                anchorOrigin: menuPosition,
                transformOrigin: menuPosition,
            }}
        >
            {renderPaginationOptions()}
        </StyledSelect>
    );
};

PaginationSelect.propTypes = {
    paginationSizes: PropTypes.array.isRequired,
    selectedPaginationSize: PropTypes.number.isRequired,
    changePaginationSize: PropTypes.func.isRequired,
};

export default PaginationSelect;
