import { DATA_TYPES, TABLE_SETTINGS } from '../../../utils/constants';
import Table from '../../Table/Table';

const ScoringTable = () => (
    <Table
        dataType={DATA_TYPES.quizScoring}
        tableName={TABLE_SETTINGS.quizScoring.name}
        tableHead={TABLE_SETTINGS.quizScoring.tableHead}
    />
);

export default ScoringTable;
