import { camelCase } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeAlert } from '../../actions/alertActions';
import { ALERT_TYPES, GRAMMATICAL_NUMBERS, MEDIA_USED_MESSAGE } from '../../utils/constants';
import { AlertDescription, AlertTitle, CloseButton, StyledPageAlert } from './PageAlert.styles';

const PageAlert = () => {
    const { t } = useTranslation();
    const { alertIsOpen, alertMessage, alertType } = useSelector((state) => state.alertReducer);
    const ALERT_DURATION = 5000;

    const dispatch = useDispatch();

    const hideAlert = () => dispatch(closeAlert());

    const getDefaultAlertMessage = () =>
        alertType === ALERT_TYPES.success ? t('successText') : t('errorText');

    const showMessage = () => {
        if (alertMessage?.error) {
            if (alertMessage.error === MEDIA_USED_MESSAGE) {
                const questionsId = alertMessage.data.questions.map((question) => question.id).join(', ');

                const type =
                    alertMessage.data.questions.length === 1
                        ? GRAMMATICAL_NUMBERS.singular
                        : GRAMMATICAL_NUMBERS.plural;

                return `${t(`${camelCase(MEDIA_USED_MESSAGE)}${type}`)}: ${questionsId}.`;
            }

            return t(camelCase(alertMessage.error), getDefaultAlertMessage());
        }

        if (alertMessage && typeof alertMessage === 'string') {
            return t(
                alertMessage.includes('.') ? alertMessage : camelCase(alertMessage),
                getDefaultAlertMessage()
            );
        }

        return getDefaultAlertMessage();
    };

    useEffect(() => {
        if (!alertMessage?.error) {
            let durationTimeout;

            if (alertIsOpen) {
                durationTimeout = setTimeout(hideAlert, ALERT_DURATION);
            }

            return () => clearTimeout(durationTimeout);
        }

        return () => {};
    }, [alertMessage, alertIsOpen]);

    if (alertIsOpen) {
        return (
            <StyledPageAlert severity={alertType}>
                <AlertTitle>{t(alertType)}</AlertTitle>
                <AlertDescription>{showMessage()}</AlertDescription>
                <CloseButton icon="eva:close-fill" onClick={hideAlert} />
            </StyledPageAlert>
        );
    }

    return <></>;
};

export default PageAlert;
