import { CLOSE_ALERT, OPEN_ALERT } from '../reducers/alertReducer';
import { ALERT_TYPES } from '../utils/constants';

export const openAlertSuccess = (alertMessage) => ({
    type: OPEN_ALERT,
    payload: { alertType: ALERT_TYPES.success, alertMessage },
});

export const openAlertError = (alertMessage) => ({
    type: OPEN_ALERT,
    payload: { alertType: ALERT_TYPES.error, alertMessage },
});
export const openAlertWarning = (alertMessage) => ({
    type: OPEN_ALERT,
    payload: { alertType: ALERT_TYPES.warning, alertMessage },
});

export const closeAlert = () => ({
    type: CLOSE_ALERT,
});
