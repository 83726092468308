/* eslint-disable no-restricted-syntax */

import { Box } from '@mui/material';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { AgGridReact } from 'ag-grid-react';
import i18next from 'i18next';
import { camelCase, isString, mapKeys } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { openAlertError, openAlertSuccess } from '../../../../actions/alertActions';
import useBrowserWidth from '../../../../hooks/useBrowserWidth';
import {
    ALL_DATA,
    COLUMN_WIDTHS,
    DATA_TYPES,
    FILTER_TYPES,
    FORM_TYPES,
    HTTP_ERRORS,
    NAV_LINKS,
    PACKAGE_PARTS,
    QUESTIONS_DIFFICULTY,
    TABLE_FIELDS,
    TABLE_FILTERS,
    TABLE_SETTINGS,
} from '../../../../utils/constants';
import { addData, getData, getQuestionList } from '../../../../utils/fetchData';
import {
    getQuestionDifficultyName,
    getQuizLink,
    getTrainingLink,
    isTableContentFit,
    makeTitle,
    uniqueEntriesArray,
} from '../../../../utils/helpers';
import AG_GRID_LOCALE from '../../../../utils/tableTranslations';
import { TitleContainer } from '../../../Form/Form.styles';
import FormButtons from '../../../Form/FormButtons/FormButtons';
import LoadingOverlay from '../../../LoadingOverlay/LoadingOverlay';
import SectionTitle from '../../../SectionTitle/SectionTitle';
import { TableWrapper } from '../../../Table/Table.styles';
import TableMoreMenu from '../../../Table/TableMoreMenu/TableMoreMenu';
import { TableContainer } from './TableForm.styles';

const TableForm = ({ dataType, formType, category }) => {
    const onGridReady = (params) => setGridApi(params.api);
    const [gridApi, setGridApi] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

    const [categories, setCategories] = useState([]);
    const [grades, setGrades] = useState([]);
    const [sections, setSections] = useState([]);
    const [checkedQuestions, setCheckedQuestions] = useState([]);
    const [filters, setFilters] = useState({});
    const [filteredTableData, setFilteredTableData] = useState([]);

    const currentBrowserWidth = useBrowserWidth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [elementId, setElementId] = useState();
    const [packageName, setPackageName] = useState('');
    const { id } = useParams();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const DEBOUNCE_TIME = 800;

    const FILTER_FIELDS = {
        grade: {
            id: 'grade_id',
            name: 'gradeName',
            key: 'grade',
        },
        category: {
            id: 'category_id',
            name: 'categoryName',
            key: 'category',
        },
        section: {
            id: 'section_id',
            name: 'sectionName',
            key: 'section',
            target: 'title',
        },
    };

    useEffect(() => {
        if (!gridApi) return;
        gridApi.forEachNode((node) => {
            checkedQuestions.forEach((questionId) => {
                if (node.data.id === questionId) node.setSelected(true);
            });
        });
    }, [checkedQuestions, tableData, filteredTableData, gridApi, filters]);

    useEffect(() => {
        getTableData();
        fetchAdditionalData();
    }, []);

    useEffect(() => {
        if (!tableData.length) return;
        setFilteredTableData(() =>
            tableData.filter((item) => {
                for (const filterKey in filters) {
                    if (filterKey in filters) {
                        let itemValue = item[filterKey];
                        let filterValue = filters[filterKey];
                        switch (filterKey) {
                            case FILTER_FIELDS.section.key:
                                if (!itemValue) return false;
                                if (isString(filterValue)) {
                                    itemValue = item[filterKey][FILTER_FIELDS.section.target];
                                    itemValue = itemValue.toLowerCase();
                                    filterValue = filterValue.toLowerCase();
                                    if (!itemValue.includes(filterValue)) {
                                        return false;
                                    }
                                }

                                itemValue = itemValue.id;
                                break;
                            case FILTER_FIELDS.category.name:
                                itemValue = item[FILTER_FIELDS.category.key].name;
                                break;
                            case FILTER_FIELDS.grade.name:
                                itemValue = item[FILTER_FIELDS.grade.key].name;
                                break;
                            default:
                        }
                        if (isString(itemValue)) {
                            itemValue = itemValue.toLowerCase();
                            filterValue = filterValue.toLowerCase();
                            if (!itemValue.includes(filterValue)) {
                                return false;
                            }
                        }
                        if ((!itemValue || itemValue !== filterValue) && !isString(itemValue)) {
                            return false;
                        }
                    }
                }
                return true;
            })
        );
    }, [filters]);

    useEffect(() => {
        if (location?.state?.isSuccess) {
            typeof window !== 'undefined' && window.history.replaceState(null, '');
        }
    }, [location]);

    useEffect(() => {
        if (tableData.length) {
            setColumnDefs(generateColumnDefs());
        } else if (gridApi) {
            gridApi.showNoRowsOverlay();
        }
    }, [gridApi, tableData, i18next.language]);

    useEffect(() => {
        if (gridApi) {
            gridApi.showLoadingOverlay();
            if (columnDefs.length) {
                gridApi.hideOverlay();
                setTimeout(() => {
                    isTableContentFit() && gridApi.sizeColumnsToFit();
                }, 100);
            }
        }
    }, [gridApi, columnDefs, currentBrowserWidth]);

    const getCellValue = (params, field) => {
        if (!params.data[field]) return '';
        switch (field) {
            case TABLE_FIELDS.category:
            case TABLE_FIELDS.grade:
                return params.data[field].name;
            case TABLE_FIELDS.difficulty:
                return t(getQuestionDifficultyName(params.data.difficulty));
            case TABLE_FIELDS.section:
                return params.data[field].title;
            default:
                return params.data[field];
        }
    };

    const getTooltipValue = (params, field) => {
        if (!params.data[field]) return '';
        switch (field) {
            case TABLE_FIELDS.category:
            case TABLE_FIELDS.grade:
                return params.data[field].name;
            case TABLE_FIELDS.difficulty:
                return t(getQuestionDifficultyName(params.data.difficulty));
            case TABLE_FIELDS.section:
                return params.data[field].title;
            default:
                return params.data[field];
        }
    };

    const getFilter = (field) => {
        switch (field) {
            case TABLE_FIELDS.difficulty:
                return selectFilter(field, QUESTIONS_DIFFICULTY);
            case TABLE_FIELDS.category:
                return selectFilter(field, categories);
            case TABLE_FIELDS.grade:
                return selectFilter(field, grades);
            case TABLE_FIELDS.section:
                return selectFilter(field, sections);
            default:
                return {
                    suppressAndOrCondition: true,
                    filterOptions: ['contains'],
                    buttons: ['reset'],
                    debounceMs: DEBOUNCE_TIME,
                };
        }
    };

    const selectFilter = (field, data) => {
        const getDisplayKey = (item) => {
            switch (field) {
                default:
                    return Object.keys(item).length ? item?.id : item;
            }
        };

        const getDisplayName = (item) => {
            switch (field) {
                case TABLE_FIELDS.organizations:
                    return item.short_name;
                case TABLE_FIELDS.organization:
                    return item.short_name;
                case TABLE_FIELDS.organizationName:
                    return item.short_name;
                case TABLE_FIELDS.participantGroups:
                    return item.name;
                case TABLE_FIELDS.participantGroupUser:
                    return item.name;
                case TABLE_FIELDS.category:
                    return item.name;
                case TABLE_FIELDS.grade:
                    return item.name;
                case TABLE_FIELDS.difficulty:
                    return t(getQuestionDifficultyName(item));
                case TABLE_FIELDS.section:
                    return item.title;
                default:
                    return t(item);
            }
        };

        const filterData = data.map((item) => ({
            displayKey: getDisplayKey(item),
            displayName: getDisplayName(item),
            predicate: () => true,
            numberOfInputs: 0,
        }));

        return {
            buttons: ['reset'],
            debounceMs: DEBOUNCE_TIME,
            closeOnApply: true,
            suppressAndOrCondition: true,
            filterOptions: isMultiSelectField(field)
                ? [
                      TABLE_FILTERS.empty,
                      {
                          displayKey: TABLE_FILTERS.contains,
                          displayName: TABLE_FILTERS.contains,
                          predicate: () => true,
                      },
                      ...filterData,
                  ]
                : [TABLE_FILTERS.empty, ...filterData],
        };
    };

    const isMultiSelectField = (field) =>
        field === TABLE_FIELDS.category || field === TABLE_FIELDS.grade || field === TABLE_FIELDS.section;

    const fetchAdditionalData = () => {
        if (dataType === DATA_TYPES.quiz || dataType === DATA_TYPES.training) {
            Promise.all([getData(DATA_TYPES.category, ALL_DATA), getData(DATA_TYPES.grade, ALL_DATA)])
                .then(([categoriesRes, gradesRes]) => {
                    if (categoriesRes.error || gradesRes.error) {
                        dispatch(openAlertError());
                    } else {
                        setCategories(categoriesRes.data);
                        setGrades(gradesRes.data);
                    }
                })
                .catch(() => dispatch(openAlertError()));
        }
    };

    const setCenterCell = () => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
    });

    const generateColumnDefs = () => {
        const colDefs = TABLE_SETTINGS.question.tableHead
            .filter((e) => e.field !== TABLE_FIELDS.id)
            .map((head) => ({
                field: head.field,
                headerName: t(head.headerName ?? head.field),
                headerTooltip: t(head.headerName ?? head.field),
                tooltipValueGetter: (params) => getTooltipValue(params, head.field),
                valueGetter: (params) => getCellValue(params, head.field),
                filterParams: () => getFilter(head.field),
                filter: true,
            }));

        colDefs.unshift({
            field: TABLE_FIELDS.ordinaryNumber,
            headerName: t(camelCase(TABLE_FIELDS.ordinaryNumber)),
            valueGetter: (params) => params.node.rowIndex + 1,
            maxWidth: COLUMN_WIDTHS.ordinaryNumber.small,
            minWidth: COLUMN_WIDTHS.ordinaryNumber.small,
            pinned: 'left',
            cellStyle: setCenterCell,
            sortable: false,
            filter: false,
        });

        colDefs.unshift({
            field: TABLE_FIELDS.checkbox,
            headerName: '',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            maxWidth: COLUMN_WIDTHS.checkbox,
            minWidth: COLUMN_WIDTHS.checkbox,
            sortable: false,
            filter: false,
        });

        colDefs.push({
            field: TABLE_FIELDS.tableMoreMenu,
            headerName: '',
            cellRenderer: TableMoreMenu,
            cellRendererParams: (params) => ({
                dataType: DATA_TYPES.quiz,
                itemId: params.data.id,
            }),
            maxWidth: COLUMN_WIDTHS.tableMoreMenu,
            minWidth: COLUMN_WIDTHS.tableMoreMenu,
            cellStyle: setCenterCell,
            sortable: false,
            filter: false,
        });

        return colDefs;
    };

    const getList = () => {
        switch (dataType) {
            case DATA_TYPES.quiz:
                return getQuestionList(PACKAGE_PARTS.quiz, id, ALL_DATA);
            case DATA_TYPES.training:
                return getQuestionList(PACKAGE_PARTS.training, id, ALL_DATA);
            default:
                handleError();
                throw new Error('Something went wrong in getList in TableForm.js');
        }
    };

    const getTableData = async () => {
        try {
            const questionsRes = await getData(DATA_TYPES.question, ALL_DATA);
            const questions = questionsRes.data;
            const sections = questions
                .filter((question) => question.section)
                .map((question) => question.section)
                .reduce((sum, currentSection) => {
                    const foundDuplicate = sum.find((section) => section.id === currentSection.id);
                    if (!foundDuplicate) {
                        return sum.concat([currentSection]);
                    }
                    return sum;
                }, []);

            const quizDetailsRes = await getList();
            setPackageName(quizDetailsRes?.package_name);
            setElementId(quizDetailsRes?.quiz_id ?? quizDetailsRes?.training_id);
            if (questionsRes.error) {
                handleError(questionsRes.error);
                return;
            }

            setCheckedQuestions((prev) =>
                prev?.length ? prev : quizDetailsRes.data.data.map((item) => item.question.id)
            );
            setTableData(questions);
            setSections(sections);
        } catch (error) {
            console.error(error);
            dispatch(openAlertError());
        }
    };

    const getNavLink = () => {
        switch (dataType) {
            case DATA_TYPES.quiz:
                return getQuizLink(id);
            case DATA_TYPES.training:
                return getTrainingLink(id);
            default:
                handleError();
                throw new Error('Something went wrong in getNavLink in TableForm.js');
        }
    };

    const handleError = (errorMessage) => {
        setIsSubmitting(false);
        if (camelCase(errorMessage) === HTTP_ERRORS.unauthorized.text) {
            navigate(NAV_LINKS.unauthorized, { replace: true });
        } else {
            dispatch(openAlertError(errorMessage));
            setTableData([]);
        }
    };

    const handleSubmit = () => {
        setIsSubmitting(true);
        switch (dataType) {
            case DATA_TYPES.quiz:
                return addQuizQuestion();
            case DATA_TYPES.training:
                return addTrainingQuestion();
            default:
                handleError();
                throw new Error('Something went wrong in handleSubmit in TableForm.js');
        }
    };

    const addTrainingQuestion = () => {
        addData(DATA_TYPES.training, {
            training_id: elementId,
            questions: checkedQuestions ?? [],
        })
            .then((res) => {
                if (res.success) {
                    navigate(getNavLink());
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => dispatch(openAlertError()))
            .finally(() => setIsSubmitting(false));
    };

    const addQuizQuestion = () => {
        addData(DATA_TYPES.quiz, {
            quiz_id: elementId,
            questions: checkedQuestions ?? [],
        })
            .then((res) => {
                if (res.success) {
                    navigate(getNavLink());
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => dispatch(openAlertError()))
            .finally(() => setIsSubmitting(false));
    };

    const changeKeys = (filterType, key) => {
        switch (key) {
            case TABLE_FIELDS.category:
                return FILTER_FIELDS.category[filterType];
            case TABLE_FIELDS.grade:
            case TABLE_FIELDS.grades:
                return FILTER_FIELDS.grade[filterType];

            default:
                return key;
        }
    };

    const onFilterChanged = () => {
        const filters = mapKeys(gridApi.getFilterModel(), (v, k) =>
            changeKeys(v?.filter ? FILTER_TYPES.name : FILTER_TYPES.id, k)
        );
        setFilters(
            Object.fromEntries(
                Object.keys(filters).map((field) => [field, filters[field].filter ?? filters[field].type])
            )
        );
    };

    const onSelectionChanged = (params) => {
        const unselectedRowsIds = params.api
            .getRenderedNodes()
            .filter((node) => !node.selected)
            .map((node) => node.data.id);
        const selectedRowsIds = params.api.getSelectedRows().map((row) => row.id);

        setCheckedQuestions((prev) =>
            uniqueEntriesArray(prev.filter((id) => !unselectedRowsIds.includes(id)).concat(selectedRowsIds))
        );
    };

    const filterOverlay = () => {
        const isFilter = !!Object.keys(filters).length;
        return `<span>${isFilter ? t('noResultForThisFilter') : t('defaultNoRows')}</span>`;
    };

    return (
        <TableContainer>
            <TitleContainer>
                <SectionTitle
                    tableMargin
                    title={`${packageName ? `${packageName} - ` : ''}${makeTitle({
                        formType,
                        category,
                    })}`}
                />
            </TitleContainer>
            <TableWrapper height={'90%'} className="ag-theme-material" dataType={dataType}>
                <AgGridReact
                    defaultColDef={{
                        sortable: true,
                        resizable: true,
                        autoHeight: true,
                        filter: true,
                        filterParams: {
                            buttons: ['reset'],
                            closeOnApply: true,
                            debounceMs: DEBOUNCE_TIME,
                        },
                    }}
                    onFilterChanged={onFilterChanged}
                    rowData={Object.keys(filters).length ? filteredTableData : tableData}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    tooltipShowDelay={0}
                    localeText={AG_GRID_LOCALE[i18n.language]}
                    animateRows
                    suppressDragLeaveHidesColumns
                    rowSelection={'multiple'}
                    suppressRowClickSelection
                    onSelectionChanged={onSelectionChanged}
                    overlayNoRowsTemplate={filterOverlay()}
                    loadingOverlayComponent={LoadingOverlay}
                />
            </TableWrapper>
            <Box sx={{ mt: 4 }}>
                <FormButtons
                    formType={FORM_TYPES.apply}
                    isFormValid
                    navLink={getNavLink()}
                    onSubmit={handleSubmit}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                />
            </Box>
        </TableContainer>
    );
};

export default TableForm;
