import { Box } from '@mui/material';
import styled from 'styled-components';

export const BUTTONS_DEFAULT_STYLES = {
    maxWidth: 150,
    minWidth: 100,
    ':disabled': {
        borderColor: 'transparent',
    },
};

export const getFormButtonsSx = () => ({
    ...BUTTONS_DEFAULT_STYLES,
    borderWidth: '2px',
    ':hover': {
        borderWidth: '2px',
    },
});

export const StyledBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        gap: 1rem;
        & button,
        & div,
        & div button {
            width: 100%;
            max-width: 100%;
        }
    }
`;

export const CenterSpinner = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-block: 5px;
`;
