import { DATA_TYPES, TABLE_SETTINGS } from '../../../utils/constants';
import Table from '../../Table/Table';

const GradesTable = () => (
    <Table
        dataType={DATA_TYPES.grade}
        tableName={TABLE_SETTINGS.grade.name}
        tableHead={TABLE_SETTINGS.grade.tableHead}
    />
);

export default GradesTable;
