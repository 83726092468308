import styled from 'styled-components';

export const WarningContainer = styled.div`
    display: ${({ displayWarning }) => (displayWarning ? 'flex' : 'none')};
    flex-direction: row;
    justify-content: end;
    width: ${({ fullWidth, width }) => width || (fullWidth ? '99%' : '90%')};
    margin-top: 0.5rem;

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        width: 100%;
    }
`;

export const WarningText = styled.p`
    color: ${({ theme }) => theme.palette.error.main};
    font-style: italic;
    font-size: 0.9375rem;
`;

export const WarningArrowContainer = styled.div`
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const WarningArrow = styled.img`
    width: 20px;
    height: 20px;
`;
