import { Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledLink = styled(Typography)`
    color: ${({ theme }) => theme.palette.primary.darker};
    cursor: pointer;
    align-self: flex-end;
`;

export const Claws = styled.img`
    width: 350px;
    height: 350px;
    position: absolute;
    opacity: 0.4;
    left: -55px;
    bottom: 20px;
    pointer-events: none;
    user-select: none;
`;
