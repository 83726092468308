import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import LanguagePopover from '../components/LanguagePopover/LanguagePopover';
import Logo from '../components/Logo/Logo';
import PageAlert from '../components/PageAlert/PageAlert';

const HeaderStyle = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 2rem',
}));

const SimpleLayout = () => (
    <>
        <HeaderStyle>
            <Logo />
            <LanguagePopover />
        </HeaderStyle>
        <PageAlert />
        <Outlet />
        <Footer />
    </>
);

export default SimpleLayout;
