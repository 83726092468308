import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page/Page';

const DashboardApp = () => {
    const { t } = useTranslation();

    return (
        <Page>
            <Container sx={{ m: 0 }}>
                <Typography variant="h5">{t('welcomeText')}</Typography>
            </Container>
        </Page>
    );
};

export default DashboardApp;
