import { Alert, AlertTitle as MuiAlertTitle } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import Iconify from '../Iconify/Iconify';

const revealAnimation = keyframes`
    0% {
        top: -30rem;
    }

    100% {
        top: 0;
    }
`;

export const StyledPageAlert = styled(Alert)`
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 50%;
    animation: ${revealAnimation} 1s ease-in-out;
    left: 25%;
    transform: translateX(-50%, -50%);

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        width: 100%;
        left: 0;
        transform: none;
    }
`;

export const CloseButton = styled(Iconify)`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0.9375rem;
    right: 0.9375rem;
    cursor: pointer;
`;

export const AlertTitle = styled(MuiAlertTitle)`
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const AlertDescription = styled.p``;
