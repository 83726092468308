import { alpha } from '@mui/material/styles';

const createGradient = (color1, color2) => `linear-gradient(to bottom, ${color1}, ${color2})`;

const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = {
    autocomplete: '#eaf0ec',
    lighter: '#7ea78f',
    light: '#538a69',
    main: '#286D44',
    dark: '#205736',
    darker: '#184129',
    contrastText: '#fff',
};

const SECONDARY = {
    lighter: '#f6f2e4',
    light: '#f3eedb',
    main: '#f0ead2',
    dark: '#c0bba8',
    darker: '#908c7e',
    contrastText: '#000',
};

const INFO = {
    lighter: '#D0F2FF',
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    darker: '#04297A',
    contrastText: '#fff',
};

const SUCCESS = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    darker: '#08660D',
    contrastText: GREY[800],
};

const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
    contrastText: GREY[800],
};

const ERROR = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#7A0C2E',
    contrastText: '#fff',
};

const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
};

const palette = {
    common: { black: '#07170D', white: '#fff', red: '#FF0000' },
    primary: { ...PRIMARY },
    secondary: { ...SECONDARY },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    grey: GREY,
    blue: '#1565c0',
    gradients: GRADIENTS,
    divider: GREY[500_24],
    text: {
        primary: '#07170D',
        secondary: GREY[600],
        disabled: GREY[500],
        dark: '#212b36',
        white: GREY[0],
    },
    background: {
        paper: '#fff',
        default: GREY[100],
        neutral: GREY[200],
        light: '#f9fafb',
        footer: 'linear-gradient(to right, #fff, #f2f3f5, #fff)',
    },
    border: 'rgba(145, 158, 171, 0.24)',
    tableBorder: '#dde2eb',
    rowBorder: '#e2e2e2',
    table: {
        hoverHeadCell: '#f2f2f2',
        hoverCell: '#f7f7f7',
        bg: '#fafafa',
    },
    action: {
        active: GREY[600],
        hover: GREY[500_8],
        selected: GREY[500_16],
        disabled: GREY[500_80],
        disabledBackground: GREY[500_24],
        focus: GREY[500_24],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
    actionButton: {
        background: PRIMARY.main,
        hoverBackground: PRIMARY.dark,
    },
};

export default palette;
