import { IconButton, Menu } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MenuContext } from '../../../context/MenuContext';
import { DATA_TYPES, NAV_LINKS, ORDER_STATUS } from '../../../utils/constants';
import { getShowLink } from '../../../utils/helpers';
import FileButton from '../../FileButton/FileButton';
import Iconify from '../../Iconify/Iconify';
import MenuItem from './MenuItem/MenuItem';

const TableMoreMenu = ({
    dataType,
    dataId,
    editData,
    permissions,
    deleteData,
    importFromFile,
    exportToFile,
    redirectToSubpage,
    data,
    addImage,
    copyLink,
    showModal,
    isAddImage,
}) => {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const userData = useSelector((state) => state.userReducer);
    const navigate = useNavigate();

    const menuPosition = { vertical: 'top', horizontal: 'right' };

    const editElement = () => {
        editData();
        setIsOpen(false);
    };

    const deleteElement = () => {
        deleteData();
        setIsOpen(false);
    };

    const uploadFromFile = (e) => {
        importFromFile(e);
        setIsOpen(false);
    };

    const exportToPdf = () => {
        exportToFile();
        setIsOpen(false);
    };

    const handleAddImage = () => {
        addImage();
        setIsOpen(false);
    };
    const copyUrl = () => {
        copyLink();
        setIsOpen(false);
    };
    const handleModal = () => {
        showModal();
        setIsOpen(false);
    };

    const isUserDataType = () =>
        dataType === DATA_TYPES.administrator || dataType === DATA_TYPES.organizationUser;

    const isCurrentLoggedUser = () => isUserDataType() && dataId === userData?.id;

    const isHidden = () => dataType === DATA_TYPES.order && ORDER_STATUS[3] === ORDER_STATUS[data?.status];

    return isCurrentLoggedUser() || isHidden() ? (
        <></>
    ) : (
        <MenuContext.Provider value={{ isOpen }}>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: {
                        width: dataType === DATA_TYPES.participantGroup ? 275 : 200,
                    },
                }}
                anchorOrigin={menuPosition}
                transformOrigin={menuPosition}
            >
                {dataType === DATA_TYPES.question && (
                    <MenuItem
                        title={'showQuestion'}
                        icon={'fluent:notebook-question-mark-20-regular'}
                        onClick={redirectToSubpage}
                    />
                )}

                {dataType === DATA_TYPES.quizResults && (
                    <MenuItem title={'exportResult'} icon={'bi:filetype-pdf'} onClick={exportToPdf} />
                )}

                {dataType === DATA_TYPES.question && (
                    <MenuItem title={'showUsage'} icon={'ic:outline-preview'} onClick={handleModal} />
                )}

                {dataType === DATA_TYPES.category && (
                    <MenuItem title={'sections'} icon={'bx:book-bookmark'} onClick={redirectToSubpage} />
                )}

                {dataType === DATA_TYPES.media && isAddImage && (
                    <MenuItem
                        title={'addImage'}
                        icon={'material-symbols:add-circle-outline'}
                        onClick={handleAddImage}
                    />
                )}
                {dataType === DATA_TYPES.media && (
                    <MenuItem title={'copyLink'} icon={'carbon:copy-link'} onClick={copyUrl} />
                )}

                {permissions?.manage &&
                    dataType !== DATA_TYPES.quiz &&
                    dataType !== DATA_TYPES.training &&
                    dataType !== DATA_TYPES.media &&
                    dataType !== DATA_TYPES.order &&
                    dataType !== DATA_TYPES.quizResults && (
                        <MenuItem title={'edit'} icon={'eva:edit-fill'} onClick={editElement} />
                    )}

                {permissions?.manage &&
                    dataType !== DATA_TYPES.quiz &&
                    dataType !== DATA_TYPES.training &&
                    dataType !== DATA_TYPES.package &&
                    dataType !== DATA_TYPES.order &&
                    dataType !== DATA_TYPES.quizResults && (
                        <MenuItem title={'delete'} icon={'eva:trash-2-outline'} onClick={deleteElement} />
                    )}

                {dataType === DATA_TYPES.participantGroup && (
                    <FileButton importFromFile={uploadFromFile} exportToFile={exportToPdf} />
                )}

                {dataType === DATA_TYPES.participantGroup && (
                    <MenuItem
                        title={'showParticipants'}
                        icon={'clarity:users-line'}
                        onClick={redirectToSubpage}
                    />
                )}

                {dataType === DATA_TYPES.package && data.quiz && (
                    <MenuItem title={'quiz'} icon={'ci:list-ul'} onClick={redirectToSubpage().quiz} />
                )}

                {dataType === DATA_TYPES.package && data.training && (
                    <MenuItem
                        title={'training'}
                        icon={'material-symbols:model-training-outline-rounded'}
                        onClick={redirectToSubpage().training}
                    />
                )}

                {(dataType === DATA_TYPES.quiz || dataType === DATA_TYPES.training) && (
                    <MenuItem
                        title={'show'}
                        icon={'bx:show'}
                        onClick={() => navigate(getShowLink(NAV_LINKS.questions, data.id))}
                    />
                )}

                {permissions?.view && dataType === DATA_TYPES.product && (
                    <MenuItem
                        title={'showProduct'}
                        icon={'fluent-mdl2:product-list'}
                        onClick={redirectToSubpage()}
                    />
                )}

                {permissions?.view && dataType === DATA_TYPES.organization && (
                    <MenuItem
                        title={'changePackages'}
                        icon={'ic:baseline-change-circle'}
                        onClick={redirectToSubpage()}
                    />
                )}

                {dataType === DATA_TYPES.order && (
                    <MenuItem title={'changeStatus'} icon={'tabler:status-change'} onClick={handleModal} />
                )}
            </Menu>
        </MenuContext.Provider>
    );
};

TableMoreMenu.propTypes = {
    dataType: PropTypes.string.isRequired,
    dataId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    permissions: PropTypes.object,
    editData: PropTypes.func,
    deleteData: PropTypes.func,
    importFromFile: PropTypes.func,
    exportToFile: PropTypes.func,
    redirectToSubpage: PropTypes.func,
};

export default TableMoreMenu;
