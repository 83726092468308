import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
`;

const NoRows = ({ children }) => <Span>{children}</Span>;

export default NoRows;
