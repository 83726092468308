import { Autocomplete, TextField, Tooltip, useTheme } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LOADING_SPINNER_TYPES } from '../../utils/constants';
import { getFieldTooltip } from '../../utils/helpers';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const AutocompleteSelect = ({
    field,
    label,
    values,
    value,
    onChange,
    onBlur,
    maxWidth,
    helperText,
    getOptionLabel,
    error,
    onInput,
    multiple,
    disabled,
    disableClearable,
    loading,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const style = {
        width: '100%',
        ...(maxWidth && { maxWidth }),
    };

    return (
        <Tooltip title={disabled ? t(getFieldTooltip(field.split('_')[0])) : ''}>
            <Autocomplete
                loading={loading}
                loadingText={<LoadingSpinner size={20} type={LOADING_SPINNER_TYPES.clip} />}
                id={field}
                key={field}
                name={field}
                multiple={multiple}
                options={values}
                value={value || (multiple ? [] : {})}
                getOptionLabel={getOptionLabel}
                onChange={values.length ? onChange : () => {}}
                filterSelectedOptions
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText={t('noOptions')}
                clearText={t('clear')}
                openText={t('open')}
                closeText={t('close')}
                onBlur={onBlur}
                disabled={disabled}
                disableClearable={disableClearable}
                style={style}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t(label)}
                        placeholder={t(label)}
                        helperText={helperText}
                        error={error}
                        onInput={onInput}
                    />
                )}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(getOptionLabel(option), inputValue, {
                        requireMatchAll: true,
                    });
                    const parts = parse(getOptionLabel(option), matches);

                    return (
                        <li {...props}>
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontSize: theme.typography.autocomplete.fontSize,
                                            fontWeight: part.highlight
                                                ? theme.typography.fontWeightBold
                                                : theme.typography.fontWeightRegular,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    );
                }}
            />
        </Tooltip>
    );
};

AutocompleteSelect.propTypes = {
    field: PropTypes.string,
    label: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    helperText: PropTypes.string,
    getOptionLabel: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    onInput: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    disableClearable: PropTypes.bool,
    loading: PropTypes.bool,
    maxWidth: PropTypes.number,
};

export default AutocompleteSelect;
