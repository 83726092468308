import { ListItemIcon, ListItemText, MenuItem as MuiMenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../../../../context/MenuContext';
import Iconify from '../../../Iconify/Iconify';

const MenuItem = ({ title, icon, onClick }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen } = useContext(MenuContext);

    useEffect(() => {
        const ANIMATION_DELAY = 1000;
        !isOpen && setTimeout(() => setIsLoading(false), ANIMATION_DELAY);
    }, [isOpen]);

    const onClickWrapper = async (event) => {
        setIsLoading(true);
        await onClick(event);
    };

    return (
        <MuiMenuItem disabled={isLoading} sx={{ color: 'text.secondary' }} onClick={onClickWrapper}>
            <ListItemIcon>
                <Iconify icon={icon} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={t(title)} primaryTypographyProps={{ variant: 'body2' }} />
        </MuiMenuItem>
    );
};

MenuItem.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default MenuItem;
