import styled, { css } from 'styled-components';

export const PAGINATION_PANEL_POSITION = 50;
const PAGINATION_PANEL_OFFSET = 1;

export const StyledPaginationPanel = styled.div`
    position: absolute;
    bottom: -${PAGINATION_PANEL_POSITION}px;
    width: calc(100% + ${2 * PAGINATION_PANEL_OFFSET}px);
    background-color: ${({ theme }) => theme.palette.common.white};
    height: 50px;
    border: 1px solid ${({ theme }) => theme.palette.tableBorder};
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: -${PAGINATION_PANEL_OFFSET}px;
`;

export const PaginationContainer = styled.div``;

export const PaginationSpan = styled.span`
    text-align: center;

    ${({ hideOnMobile, theme }) =>
        hideOnMobile &&
        css`
            ${theme.mediaBreakpoints.mobile} {
                display: none;
            }
        `}
`;
