import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import { Button, Container, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { USER_FORM_FIELDS } from '../../components/Forms/UserForm/UserForm';
import InfoField from '../../components/InfoField/InfoField';
import Page from '../../components/Page/Page';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import { NAV_LINKS } from '../../utils/constants';
import { isComplexUserData } from '../../utils/helpers';
import {
    ButtonsContainer,
    InfoRow,
    ProfileContainer,
    UserAvatarContainer,
    UserInfoContainer,
} from './UserProfile.styles';

const UserProfile = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.userReducer);

    return (
        <Page>
            <Container sx={{ m: 0 }}>
                <Typography variant="h5">{`${t('userProfile')} ${
                    isComplexUserData(user) ? `${user.first_name} ${user.last_name}` : ''
                }`}</Typography>
                <ProfileContainer>
                    <UserAvatarContainer>
                        <UserAvatar name={user.first_name} surname={user.last_name} />
                    </UserAvatarContainer>
                    <UserInfoContainer>
                        <InfoRow>
                            <InfoField title={'fullName'} value={`${user.first_name} ${user.last_name}`} />
                            <InfoField title={USER_FORM_FIELDS.sex} value={user.sex} processData />
                        </InfoRow>
                        <InfoRow>
                            <InfoField title={USER_FORM_FIELDS.email} value={user.email} />
                            <InfoField title={USER_FORM_FIELDS.role} value={user.role} processData />
                        </InfoRow>
                    </UserInfoContainer>
                </ProfileContainer>
                <ButtonsContainer>
                    <Button
                        variant="contained"
                        sx={{
                            maxWidth: 200,
                            mr: 5,
                            mb: 2,
                        }}
                        startIcon={<KeyIcon />}
                        onClick={() => navigate(NAV_LINKS.changePassword)}
                    >
                        {t('changePassword')}
                    </Button>

                    <Button
                        variant="contained"
                        sx={{
                            maxWidth: 150,
                            mb: 2,
                            backgroundColor: theme.palette.actionButton.background,
                            ':hover': {
                                bgcolor: theme.palette.actionButton.hoverBackground,
                                color: theme.palette.common.white,
                            },
                        }}
                        startIcon={<EditIcon />}
                        onClick={() => navigate(`${NAV_LINKS.userProfile}/edit`)}
                    >
                        {t('editProfile')}
                    </Button>
                </ButtonsContainer>
            </Container>
        </Page>
    );
};

export default UserProfile;
