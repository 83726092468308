import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

// eslint-disable-next-line
export const convertDraft = (nodeName, node) => {
    if (nodeName === 'img' && node instanceof HTMLImageElement) {
        const entityConfig = {};
        entityConfig.src = node.getAttribute ? node.getAttribute('src') || node.src : node.src;
        entityConfig.alt = node.alt;
        entityConfig.height = node.style.height;
        entityConfig.width = node.style.width;

        if (node.parentNode.style.textAlign) {
            entityConfig.alignment = node.parentNode.style.textAlign;
        }

        return {
            type: 'IMAGE',
            mutability: 'MUTABLE',
            data: entityConfig,
        };
    }
};

const htmlToEditorState = (html) => {
    const blocksFromHtml = htmlToDraft(html, convertDraft);
    const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks.filter(
            (contentBlock) =>
                !(
                    !contentBlock._map._root.entries[4][1] &&
                    contentBlock._map._root.entries[3][1]?._root?.entries[0][0] === 'text-align'
                )
        ),
        blocksFromHtml.entityMap
    );

    return EditorState.createWithContent(contentState);
};

export default htmlToEditorState;
