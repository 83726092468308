import { Navigate, useRoutes } from 'react-router-dom';
import OrderForm from './components/Forms/OrderForm/OrderForm';
import OrganizationForm from './components/Forms/OrganizationForm/OrganizationForm';
import PackagesForm from './components/Forms/PackagesForm/PackagesForm';
import TableForm from './components/Forms/PackagesForm/TableForm/TableForm';
import PackagesListForm from './components/Forms/PackagesListForm/PackagesListForm';
import ParticipantGroupForm from './components/Forms/ParticipantGroupForm/ParticipantGroupForm';
import PasswordForm from './components/Forms/PasswordForm/PasswordForm';
import ProductForm from './components/Forms/ProductForm/ProductForm';
import QuestionForm from './components/Forms/QuestionForm/QuestionForm';
import QuizParametersForm from './components/Forms/QuizParametersForm/QuizParametersForm';
import SectionForm from './components/Forms/SectionForm/SectionForm';
import SimpleForm from './components/Forms/SimpleForm/SimpleForm';
import UserForm from './components/Forms/UserForm/UserForm';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import AdministratorsTable from './components/Tables/AdministratorsTable/AdministratorsTable';
import CategoriesTable from './components/Tables/CategoriesTable/CategoriesTable';
import GradesTable from './components/Tables/GradesTable/GradesTable';
import GroupParticipantsTable from './components/Tables/GroupParticipantsTable/GroupParticipantsTable';
import LogActivityTable from './components/Tables/LogActivityTable/LogActivityTable';
import OrdersTable from './components/Tables/OrdersTable/OrdersTable';
import OrganizationUsersTable from './components/Tables/OrganizationUsersTable/OrganizationUsersTable';
import OrganizationsTable from './components/Tables/OrganizationsTable/OrganizationsTable';
import PackagesTable from './components/Tables/PackagesTable/PackagesTable';
import ParticipantGroupsTable from './components/Tables/ParticipantGroupsTable/ParticipantGroupsTable';
import ParticipantsTable from './components/Tables/ParticipantsTable/ParticipantsTable';
import ProductsTable from './components/Tables/ProductsTable/ProductsTable';
import QuestionsTable from './components/Tables/QuestionsTable/QuestionsTable';
import QuizResultsTable from './components/Tables/QuizResultsTable/QuizResultsTable';
import QuizzesTable from './components/Tables/QuizzesTable/QuizzesTable';
import ScoringTable from './components/Tables/ScoringTable/ScoringTable';
import SectionsTable from './components/Tables/SectionsTable/SectionsTable';
import TrainingsTable from './components/Tables/TrainingsTable/TrainingsTable';
import DashboardLayout from './layouts/DashboardLayout';
import SimpleLayout from './layouts/SimpleLayout';
import DashboardApp from './pages/DashboardApp/DashboardApp';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import LoginPage from './pages/LoginPage/LoginPage';
import RecoveryPasswordPage from './pages/RecoveryPasswordPage/RecoveryPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import SetProfilePage from './pages/SetProfilePage/SetProfilePage';
import UserProfile from './pages/UserProfile/UserProfile';
import {
    CATEGORIES,
    DATA_TYPES,
    FORM_TYPES,
    HTTP_ERRORS,
    NAV_LINKS,
    PERMISSIONS_TYPES,
    QUESTION_FORMS,
    ROUTES,
} from './utils/constants';

const Router = () =>
    useRoutes([
        {
            element: <SimpleLayout />,
            children: [
                {
                    path: ROUTES.absolute,
                    element: <Navigate to={NAV_LINKS.login} />,
                },
                { path: ROUTES.login, element: <LoginPage /> },
                { path: ROUTES.setProfile, element: <SetProfilePage /> },
                {
                    path: ROUTES.resetPassword,
                    element: <ResetPasswordPage />,
                },
                {
                    path: ROUTES.recoverPassword,
                    element: <RecoveryPasswordPage />,
                },
                {
                    path: ROUTES.notFound,
                    element: <ErrorPage error={HTTP_ERRORS.notFound} />,
                },
                {
                    path: ROUTES.unauthorized,
                    element: <ErrorPage error={HTTP_ERRORS.unauthorized} />,
                },
                {
                    path: ROUTES.asterisk,
                    element: <Navigate to={NAV_LINKS.notFound} />,
                },
            ],
        },
        {
            element: <DashboardLayout />,
            children: [
                { path: ROUTES.dashboard, element: <DashboardApp /> },
                {
                    path: ROUTES.userProfile.main,
                    element: <UserProfile />,
                },
                {
                    path: ROUTES.userProfile.edit,
                    element: (
                        <UserForm
                            formType={FORM_TYPES.edit}
                            dataType={DATA_TYPES.account}
                            category={DATA_TYPES.account}
                        />
                    ),
                },
                {
                    path: ROUTES.userProfile.changePassword,
                    element: (
                        <PasswordForm formType={FORM_TYPES.change} category={CATEGORIES.changePassword} />
                    ),
                },
                {
                    path: ROUTES.participants.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.participants}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <ParticipantsTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.participants.add,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.participants}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <UserForm formType={FORM_TYPES.add} dataType={DATA_TYPES.participant} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.participants.edit,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.participants}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <UserForm formType={FORM_TYPES.edit} dataType={DATA_TYPES.participant} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.organizations.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.organizations}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <OrganizationsTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.organizations.add,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.organizations}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <OrganizationForm formType={FORM_TYPES.add} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.organizations.edit,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.organizations}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <OrganizationForm formType={FORM_TYPES.edit} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.organizations.packages,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.userPackage}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <PackagesListForm formType={FORM_TYPES.edit} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.organizationUsers.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.organizationUsers}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <OrganizationUsersTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.organizationUsers.add,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.organizationUsers}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <UserForm formType={FORM_TYPES.add} dataType={DATA_TYPES.organizationUser} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.organizationUsers.edit,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.organizationUsers}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <UserForm formType={FORM_TYPES.edit} dataType={DATA_TYPES.organizationUser} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.participantGroups.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.participantGroups}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <ParticipantGroupsTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.participantGroups.add,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.participantGroups}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <ParticipantGroupForm formType={FORM_TYPES.add} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.participantGroups.edit,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.participantGroups}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <ParticipantGroupForm formType={FORM_TYPES.edit} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.participantGroups.participants.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.participantGroups}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <GroupParticipantsTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.participantGroups.participants.edit,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.participantGroups}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <UserForm formType={FORM_TYPES.edit} dataType={DATA_TYPES.participant} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.administrators.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.administrators}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <AdministratorsTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.administrators.add,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.administrators}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <UserForm formType={FORM_TYPES.add} dataType={DATA_TYPES.administrator} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.administrators.edit,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.administrators}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <UserForm formType={FORM_TYPES.edit} dataType={DATA_TYPES.administrator} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.questions.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.questions}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <QuestionsTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.questions.show,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.questions}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <QuestionForm key={QUESTION_FORMS.show} formType={FORM_TYPES.show} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.questions.add,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.questions}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <QuestionForm key={QUESTION_FORMS.add} formType={FORM_TYPES.add} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.questions.edit,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.questions}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <QuestionForm key={QUESTION_FORMS.edit} formType={FORM_TYPES.edit} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.categories.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.categories}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <CategoriesTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.categories.add,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.categories}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <SimpleForm formType={FORM_TYPES.add} dataType={DATA_TYPES.category} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.categories.edit,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.categories}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <SimpleForm formType={FORM_TYPES.edit} dataType={DATA_TYPES.category} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.grades.main,
                    element: (
                        <ProtectedRoute category={CATEGORIES.grades} permissionType={PERMISSIONS_TYPES.view}>
                            <GradesTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.grades.add,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.grades}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <SimpleForm formType={FORM_TYPES.add} dataType={DATA_TYPES.grade} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.grades.edit,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.grades}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <SimpleForm formType={FORM_TYPES.edit} dataType={DATA_TYPES.grade} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.categories.sections.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.sections}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <SectionsTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.categories.sections.add,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.sections}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <SectionForm formType={FORM_TYPES.add} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.categories.sections.edit,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.sections}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <SectionForm formType={FORM_TYPES.edit} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.packages.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.packages}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <PackagesTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.packages.add,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.packages}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <PackagesForm formType={FORM_TYPES.add} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.packages.edit,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.packages}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <PackagesForm formType={FORM_TYPES.edit} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.packages.quizzes.main,
                    element: (
                        <ProtectedRoute category={CATEGORIES.quizzes} permissionType={PERMISSIONS_TYPES.view}>
                            <QuizzesTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.packages.quizzes.questions,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.quizzes}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <TableForm dataType={DATA_TYPES.quiz} formType={FORM_TYPES.change} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.packages.quizzes.parameters,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.quizzes}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <QuizParametersForm />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.packages.quizzes.results,
                    element: (
                        <ProtectedRoute category={CATEGORIES.scoring} permissionType={PERMISSIONS_TYPES.view}>
                            <QuizResultsTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.packages.trainings.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.trainings}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <TrainingsTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.packages.trainings.questions,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.trainings}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <TableForm dataType={DATA_TYPES.training} formType={FORM_TYPES.change} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.quizScoring,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.quizScoring}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <ScoringTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.products.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.products}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <ProductsTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.products.add,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.products}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <ProductForm formType={FORM_TYPES.add} key={FORM_TYPES.add} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.products.edit,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.products}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <ProductForm formType={FORM_TYPES.edit} key={FORM_TYPES.edit} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.products.show,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.products}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <ProductForm formType={FORM_TYPES.show} key={FORM_TYPES.show} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.orders.main,
                    element: (
                        <ProtectedRoute category={CATEGORIES.orders} permissionType={PERMISSIONS_TYPES.view}>
                            <OrdersTable />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.orders.add,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.orders}
                            permissionType={PERMISSIONS_TYPES.manage}
                        >
                            <OrderForm formType={FORM_TYPES.add} key={FORM_TYPES.add} />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ROUTES.logActivity.main,
                    element: (
                        <ProtectedRoute
                            category={CATEGORIES.logActivity}
                            permissionType={PERMISSIONS_TYPES.view}
                        >
                            <LogActivityTable />
                        </ProtectedRoute>
                    ),
                },
            ],
        },
        {
            path: ROUTES.asterisk,
            element: <Navigate to={NAV_LINKS.notFound} replace />,
        },
    ]);

export default Router;
