import { useLocation, useNavigate } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Router from './routes';
import ThemeProvider from './theme';
import GlobalStyle from './theme/globalStyles';
import { history } from './utils/history';

const App = () => {
    history.navigate = useNavigate();
    history.location = useLocation();
    return (
        <ThemeProvider>
            <GlobalStyle />
            <ScrollToTop />
            <Router />
        </ThemeProvider>
    );
};

export default App;
