import { cloneElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { NAV_LINKS } from '../../utils/constants';
import { getPermissions } from '../../utils/helpers';

const ProtectedRoute = ({ category, permissionType, children }) => {
    const { role } = useSelector((state) => state.userReducer);

    if (!getPermissions(category, role)[permissionType]) {
        return <Navigate to={NAV_LINKS.unauthorized} replace />;
    }

    return cloneElement(children, { category });
};

export default ProtectedRoute;
