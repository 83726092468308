import SaveIcon from '@mui/icons-material/Save';
import { Modal, Typography, useTheme } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { camelCase, isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import useBrowserWidth from '../../../hooks/useBrowserWidth';
import {
    COLUMN_WIDTHS,
    DATA_TYPES,
    FORM_TYPES,
    ORDER_STATUS,
    OVERLAY_TYPES,
    TABLE_FIELDS,
} from '../../../utils/constants';
import { deleteData, getQuestionUsage } from '../../../utils/fetchData';
import { groupRows, isTableContentFit, setCenterCell } from '../../../utils/helpers';
import AG_GRID_LOCALE from '../../../utils/tableTranslations';
import CustomTable from '../../CustomTable/CustomTable';
import FormButtons from '../../Form/FormButtons/FormButtons';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';
import { ButtonsContainer } from '../Table.styles';
import DeleteButton from './DeleteButton/DeleteButton';
import ModalFormButtons from './ModalFormButtons/ModalFormButtons';
import QuestionDeletionHeader from './QuestionDeletionHeader/QuestionDeletionHeader';
import SimpleSelectForm from './SimpleSelectForm/SimpleSelectForm';
import {
    CustomTableModalWrapper,
    CustomTableWrapper,
    ModalWrapper,
    TableModalHeader,
    TableModalWrapper,
    Title,
} from './TableModal.styles';

const DEFAULT_COLUMN_WIDTH = 200;
const USER_PACKAGES_DEFAULT_COLUMN_WIDTH = 130;
const DESKTOP_WIDTH = 1366;

const tableHeadQuestions = [
    {
        field: 'package_name',
        headerName: 'packageName',
    },
    {
        field: TABLE_FIELDS.deleteButtonQuiz,
        headerName: 'quizzes',
    },
    {
        field: TABLE_FIELDS.deleteButtonTraining,
        headerName: 'training',
    },
];

const tableHeadUserPackages = [
    { field: TABLE_FIELDS.fullName },
    { field: TABLE_FIELDS.expiresAt },
    { field: TABLE_FIELDS.groups },
];

const TableModal = ({ dataType, modalData, open, handleClose, handleError, handleAction, isLoading }) => {
    const [columnDefs, setColumnDefs] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => setGridApi(params.api);
    const { t, i18n } = useTranslation();
    const currentBrowserWidth = useBrowserWidth();
    const dispatch = useDispatch();
    const theme = useTheme();

    const ROW_STYLES = {
        height: 15,
        padding: 5,
    };

    useEffect(() => {
        sanitizeGridApi();
        if (!open) return;
        setColumnDefs(generateColumnDefs());
        handleUserPackages();
        fetchData();
    }, [open]);

    const getFetchType = () => {
        switch (dataType) {
            case DATA_TYPES.question:
                return getQuestionUsage;
            default:
                throw new Error('No corresponding dataType for fetchType in TableModal.js');
        }
    };

    const sanitizeGridApi = () => {
        if (open) return;
        setGridApi(null);
    };

    const handleUserPackages = () => {
        if (DATA_TYPES.userPackages !== dataType) return;
        const filteredData = modalData.participants.filter((participant) => {
            const { payload } = modalData;
            const isCreate = payload.create.map((i) => i.user_id).includes(participant.id);
            const isDelete = payload.delete.includes(participant?.user_packages?.id);
            const isUpdate = payload.update.map((i) => i.user_id).includes(participant.id);
            if (isArray(participant.participant_groups)) {
                participant.participant_groups = participant?.participant_groups
                    .map((group) => group.name)
                    .join(', ');
            }
            participant[TABLE_FIELDS.fullName] = `${participant?.last_name} ${participant?.first_name}`;
            participant[TABLE_FIELDS.expiresAt] = participant?.user_packages?.expires_at;

            participant.type = { isUpdate, isDelete, isCreate };
            if (isUpdate || isDelete || isCreate) return true;
            return false;
        });

        const tableData = {
            create: filteredData.filter((participant) => participant.type.isCreate),
            update: filteredData.filter((participant) => participant.type.isUpdate),
            delete: filteredData.filter((participant) => participant.type.isDelete),
        };

        setTableData(tableData);
    };

    const getTableHead = () => {
        switch (dataType) {
            case DATA_TYPES.question:
                return tableHeadQuestions;
            case DATA_TYPES.userPackages:
                return tableHeadUserPackages;
            default:
                return [];
        }
    };

    const fetchData = async () => {
        if (DATA_TYPES.question !== dataType) return;
        try {
            setIsFetching(true);
            const fetchFunction = getFetchType();
            const res = await fetchFunction(modalData.id);
            if (res?.error) {
                handleError(t('errorText'));
                setTableData([]);
                throw new Error(`There was an error connecting to the server - TableModal.js`);
            }
            const quizData = res.result.quiz.map((item) => ({
                package_name: item.quiz.package.name,
                package_id: item.quiz.package.id,
                id: item.quiz_id,
                type: 'quiz',
                deletionId: item.id,
            }));
            const trainingData = res.result.training.map((item) => ({
                package_name: item.training.package.name,
                package_id: item.training.package.id,
                id: item.training_id,
                type: 'training',
                deletionId: item.id,
            }));
            const data = [...quizData, ...trainingData];
            const grouped = groupRows(data, 'package_name');
            const renamedGroups = Object.entries(grouped).map((group) => ({
                package_name: group[0],
                data: group[1],
            }));

            setTableData(renamedGroups);
        } catch (error) {
            console.error(error);
            handleError(t('error'));
        } finally {
            setIsFetching(false);
        }
    };

    const handleDelete = async (data, field, setIsLoading) => {
        const packageData = data.data;
        const packageName = data.package_name;
        let deletionData;
        try {
            switch (field) {
                case TABLE_FIELDS.deleteButtonQuiz:
                    [deletionData] = packageData.filter((item) => item.type === 'quiz');
                    break;
                case TABLE_FIELDS.deleteButtonTraining:
                    [deletionData] = packageData.filter((item) => item.type === 'training');
                    break;
                default:
                    throw new Error('wrong field');
            }
            const res = await deleteData(DATA_TYPES[deletionData.type], deletionData.deletionId);
            if (res.error) {
                throw new Error(res.error);
            }

            setTableData((prev) => {
                const filtered = prev.map((row) => {
                    if (row.package_name === packageName) {
                        const filteredData = row.data.filter(
                            (item) =>
                                !(
                                    deletionData.type === item.type &&
                                    deletionData.deletionId === item.deletionId
                                )
                        );
                        return {
                            package_name: row.package_name,
                            data: filteredData,
                        };
                    }
                    return row;
                });
                return filtered.filter((item) => item.data.length);
            });
            dispatch(openAlertSuccess('removeQuestionSuccess'));
        } catch (error) {
            console.error(error);
            dispatch(openAlertError('removeQuestionError'));
        } finally {
            setIsLoading(false);
        }
    };

    const getValue = (params, field) => {
        switch (field) {
            case TABLE_FIELDS.groups:
                return params.data[field].map((group) => group.name).join(', ');
            case TABLE_FIELDS.expirationDate:
                return params.data?.user_packages?.expires_at;
            default:
                return params.data[field];
        }
    };

    const getColumnWidth = (field) => {
        switch (field) {
            case TABLE_FIELDS.packageName:
                return COLUMN_WIDTHS.packageName;
            case TABLE_FIELDS.id:
                return COLUMN_WIDTHS.id;
            case TABLE_FIELDS.expiresAt:
                return COLUMN_WIDTHS.expiresAt;
            case TABLE_FIELDS.fullName:
                return COLUMN_WIDTHS.fullName;

            default:
                return dataType === DATA_TYPES.userPackages
                    ? USER_PACKAGES_DEFAULT_COLUMN_WIDTH
                    : DEFAULT_COLUMN_WIDTH;
        }
    };

    const generateColumnDefs = () => {
        const colDefs = getTableHead().map((head) => ({
            field: head.field,
            headerName: t(head.headerName ?? camelCase(head.field)),
            minWidth: getColumnWidth(head.field),
            maxWidth: dataType === DATA_TYPES.userPackages ? getColumnWidth(head.field) : null,
            width: getColumnWidth(head.field),
            headerTooltip: t(head.headerName ?? head.field),
            filter: false,
            sortable: false,
            cellRenderer:
                (head.field === TABLE_FIELDS.deleteButtonQuiz ||
                    head.field === TABLE_FIELDS.deleteButtonTraining) &&
                DeleteButton,
            cellRendererParams: (params) => ({
                onClick: handleDelete,
                text: t('delete'),
                data: params.data,
                field: params.colDef?.field,
            }),
            cellStyle: head.field !== TABLE_FIELDS.packageName && setCenterCell,
            cellClass: head.field !== TABLE_FIELDS.packageName && ['deleteButton'],
            valueGetter: (params) => getValue(params, head.field),
        }));

        return colDefs;
    };

    const generateSelectFields = () => [
        {
            field: 'status',
            label: 'status',
            selectValues: Object.entries(ORDER_STATUS).map((entry) => ({
                id: Number(entry[0]),
                name: t(entry[1]),
            })),
            initial: modalData.status,
        },
    ];

    const ValidationSchema = Yup.object().shape({
        status: Yup.number().required(t('statusRequired')),
    });

    const getTitle = (variant) => {
        if (variant) {
            switch (variant) {
                case 'create':
                    return t('addedPackages');
                case 'delete':
                    return t('deletedPackages');
                case 'update':
                    return t('dateChanged');

                default:
                    return '';
            }
        }
        switch (dataType) {
            case DATA_TYPES.question:
                return t('questionUsage');
            case DATA_TYPES.order:
                return t('orderStatus');
            case DATA_TYPES.userPackages:
                return `${t('summary')}`;
            default:
                console.warn('No corresponding dataType for title in TableModal.js');
                return '';
        }
    };

    useEffect(() => {
        gridApi &&
            gridApi.refreshCells({
                force: true,
                suppressFlash: true,
            });
    }, [tableData]);

    useEffect(() => {
        if (gridApi) {
            if (columnDefs.length) {
                if (currentBrowserWidth >= DESKTOP_WIDTH) {
                    gridApi.sizeColumnsToFit();
                } else {
                    setTimeout(() => {
                        isTableContentFit() && gridApi.sizeColumnsToFit();
                    }, 100);
                }
            }
        }
    }, [gridApi, columnDefs, currentBrowserWidth]);

    useEffect(() => {
        if (gridApi) {
            if (isFetching) {
                gridApi.showLoadingOverlay();
            } else if (!tableData.length) {
                gridApi.showNoRowsOverlay();
            } else {
                gridApi.hideOverlay();
            }
        }
    }, [gridApi, tableData, isFetching]);

    const isEmptyModal = () => {
        if (dataType === DATA_TYPES.userPackages) {
            return !tableData?.update?.length && !tableData?.delete?.length && !tableData?.create?.length;
        }
        return false;
    };

    const generateSummaryTable = () =>
        Object.entries(tableData).map((entry, index) => {
            const [key, rowData] = entry;
            return (
                Boolean(rowData.length) && (
                    <CustomTableWrapper key={index}>
                        <Title>{getTitle(key)}</Title>
                        <CustomTable
                            columnDefs={columnDefs}
                            rowData={rowData}
                            hideHeader
                            overlayType={OVERLAY_TYPES.userPackages}
                            rowStyles={ROW_STYLES}
                        />
                    </CustomTableWrapper>
                )
            );
        });

    return (
        <Modal onClose={handleClose} open={open}>
            <>
                {open && (
                    <ModalWrapper
                        {...(dataType === DATA_TYPES.order && {
                            style: {
                                padding: 0,
                            },
                        })}
                        gap={dataType === DATA_TYPES.userPackages && '0'}
                        height={dataType === DATA_TYPES.order ? 'min-content' : null}
                        maxWidth={dataType === DATA_TYPES.order ? '450px' : null}
                    >
                        {dataType === DATA_TYPES.question && (
                            <TableModalHeader>
                                <QuestionDeletionHeader
                                    modalData={modalData}
                                    tableData={tableData}
                                    handleAction={handleAction}
                                    handleClose={handleClose}
                                />
                            </TableModalHeader>
                        )}
                        {dataType !== DATA_TYPES.order && (
                            <TableModalHeader>
                                <Typography variant="h5">{getTitle()}</Typography>
                            </TableModalHeader>
                        )}
                        {dataType === DATA_TYPES.question && (
                            <TableModalWrapper showOverlay={!isFetching} className="ag-theme-material">
                                <AgGridReact
                                    onGridReady={onGridReady}
                                    rowData={tableData}
                                    columnDefs={columnDefs}
                                    loadingOverlayComponent={LoadingOverlay}
                                    localeText={AG_GRID_LOCALE[i18n.language]}
                                    suppressDragLeaveHidesColumns
                                ></AgGridReact>
                            </TableModalWrapper>
                        )}
                        {dataType === DATA_TYPES.userPackages && (
                            <CustomTableModalWrapper showOverlay={!isFetching} className="ag-theme-material">
                                {isEmptyModal() && (
                                    <Typography variant="h5" sx={{ color: theme.palette.grey[500] }}>
                                        {t('noChanges')}
                                    </Typography>
                                )}
                                {generateSummaryTable()}
                            </CustomTableModalWrapper>
                        )}
                        {dataType === DATA_TYPES.order && (
                            <SimpleSelectForm
                                selectFields={generateSelectFields()}
                                validationSchema={ValidationSchema}
                                onSubmit={handleAction}
                                renderButtons={(props) => (
                                    <ModalFormButtons handleClose={handleClose} {...props} />
                                )}
                            />
                        )}
                        {dataType === DATA_TYPES.userPackages && (
                            <ButtonsContainer width={'100%'}>
                                <FormButtons
                                    formType={FORM_TYPES.confirm}
                                    onBack={handleClose}
                                    isFormValid={!isEmptyModal()}
                                    onSubmit={handleAction}
                                    onSubmitText={t('saveChanges')}
                                    onSubmitIcon={<SaveIcon />}
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                />
                            </ButtonsContainer>
                        )}
                    </ModalWrapper>
                )}
            </>
        </Modal>
    );
};

export default TableModal;
