import styled from 'styled-components';
import { FormTableWrapper } from '../../Table/Table.styles';

export const FieldContainer = styled.div`
    width: 100%;
    max-width: 400px;
`;

export const BoxContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ?? 'start'};
    gap: 2rem;
    flex: 1;

    ${({ theme }) => theme.mediaBreakpoints.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const TextInfo = styled.p`
    white-space: nowrap;
    ${({ theme }) => theme.mediaBreakpoints.tablet} {
        margin-top: 0.5rem;
    }
    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        margin-top: 0rem;
    }
`;

export const ActionFieldContainer = styled.div`
    width: '100%';
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        flex-direction: column;
        ${OptionsContainer} {
            & > * {
                width: 100%;
            }
        }
    }
`;

export const ButtonsContainer = styled.div`
    margin-top: 3rem;
    width: ${({ width }) => width ?? '90%'};

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        width: 100%;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        margin: 0 2rem;
    }
`;

export const PackagesTableWrapper = styled(FormTableWrapper)`
    width: calc(100% + 4rem);
    margin-left: -2rem;

    .ag-row-last .ag-cell {
        border-bottom: 1px solid ${({ theme }) => theme.palette.rowBorder} !important;
    }

    .ag-cell-focus {
        user-select: text;
        border: 1px solid transparent !important;
    }

    .disabled-header-checkbox {
        opacity: 0.5;
        pointer-events: none;
    }

    .ag-row:has(.ag-disabled) .ag-checkbox-input-wrapper.ag-checked::after {
        color: gray;
    }
`;

export const TableWrapper = styled.div`
    margin: 0 2rem;
`;
