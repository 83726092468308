import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { saveUserData } from '../actions/userActions';
import DashboardNavbar from '../components/DashboardNavbar/DashboardNavbar';
import DashboardSidebar from '../components/DashboardSidebar/DashboardSidebar';
import Footer from '../components/Footer/Footer';
import PageAlert from '../components/PageAlert/PageAlert';
import { NAV_LINKS } from '../utils/constants';
import { getUser } from '../utils/fetchData';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

const DashboardLayout = () => {
    const [open, setOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userReducer);

    const redirectToLogin = () => navigate(NAV_LINKS.login);

    useEffect(() => {
        getUser()
            .then((res) => {
                if (res.error) {
                    redirectToLogin();
                } else {
                    if (!user?.email) {
                        const { id, first_name, last_name, email, role, sex } = res;

                        dispatch(
                            saveUserData({
                                id,
                                first_name,
                                last_name,
                                email,
                                role,
                                sex,
                            })
                        );
                    }
                    setIsLoggedIn(true);
                }
            })
            .catch(redirectToLogin);
    }, []);

    if (isLoggedIn) {
        return (
            <>
                <RootStyle>
                    <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
                    <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
                    <MainStyle>
                        <PageAlert />
                        <Outlet />
                    </MainStyle>
                </RootStyle>
                <Footer />
            </>
        );
    }

    return <></>;
};

export default DashboardLayout;
