import styled from 'styled-components';

export const ProfileContainer = styled.div`
    margin-top: 2rem;
    display: flex;

    ${({ theme }) => theme.mediaBreakpoints.tablet} {
        flex-direction: column;
        margin-top: 3rem;
    }
`;

export const UserAvatarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const UserInfoContainer = styled.div`
    margin-left: 2rem;
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.mediaBreakpoints.tablet} {
        margin-top: 4rem;
        margin-left: 0;
    }
`;

export const InfoRow = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ButtonsContainer = styled.div`
    margin-top: 3rem;
`;
