import styled from 'styled-components';

export const PointsPillWrapper = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr;
`;

const Pill = styled.span`
    font-size: 0.9rem;
    font-weight: bold;
    position: relative;
    line-height: initial;
    height: 100%;
    display: grid;
    place-items: center;
`;

export const Gain = styled(Pill)`
    border-color: #e2e2e2;
    border-right-style: solid;
    border-width: 1px;
`;

export const Loss = styled(Pill)``;
