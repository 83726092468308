import { Stack, TextField } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import { ALL_DATA, DATA_TYPES, FORM_TYPES, NAV_LINKS } from '../../../utils/constants';
import { createData, getData, getDataById, updateData } from '../../../utils/fetchData';
import { getSectionsLink, makeTitle } from '../../../utils/helpers';
import Form from '../../Form/Form';
import FormButtons from '../../Form/FormButtons/FormButtons';
import SelectField from '../../SelectField/SelectField';

const SectionForm = ({ formType, category }) => {
    const { id: categoryId, sectionId: dataId } = useParams();

    const SECTION_SCHEMA = {
        title: '',
        category_id: Number(categoryId),
        grade_id: '',
    };

    const SECTION_FIELDS = {
        title: 'title',
        grade: 'grade_id',
        grade_id: 'grade',
    };

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState(SECTION_SCHEMA);
    const [grades, setGrades] = useState([]);

    const navigateToSections = () => navigate(getSectionsLink(categoryId));

    useEffect(() => {
        if (formType === FORM_TYPES.edit) {
            getDataById(DATA_TYPES.section, dataId)
                .then((res) => {
                    if (res.error) {
                        navigate(NAV_LINKS.notFound);
                    } else {
                        const data = {
                            title: res.title,
                            grade_id: res.grade_id,
                            category_id: Number(categoryId),
                        };

                        setValues(data);
                        setData(data);
                    }
                })
                .catch(() => navigate(NAV_LINKS.notFound));
        }

        getData(DATA_TYPES.grade, ALL_DATA)
            .then((res) => {
                if (res.error) {
                    navigateToSections();
                    dispatch(openAlertError(res.error));
                } else {
                    setGrades(res.data);
                }
            })
            .catch(() => dispatch(openAlertError()));
    }, []);

    const DataSchema = Yup.object().shape({
        title: Yup.string().max(255, t('titleMaxCharacters')).required(t('titleRequired')),
        category_id: Yup.number().required(t('categoryRequired')),
        grade_id: Yup.number().required(t('gradesRequired')),
    });

    const formik = useFormik({
        initialValues: SECTION_SCHEMA,
        validationSchema: DataSchema,
        onSubmit: (values) => {
            formType === FORM_TYPES.add ? addData(values) : updateValues(dataId, values);
        },
    });

    const {
        errors,
        touched,
        isValid,
        values,
        setValues,
        setFieldTouched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
    } = formik;

    const addData = (payload) => {
        createData(DATA_TYPES.section, payload)
            .then((res) => {
                if (res.success) {
                    navigateToSections();
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                setSubmitting(false);
                dispatch(openAlertError());
            });
    };

    const updateValues = (id, payload) => {
        updateData(DATA_TYPES.section, id, payload)
            .then((res) => {
                if (res.success) {
                    navigateToSections();
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                setSubmitting(false);
                dispatch(openAlertError());
            });
    };

    const isFormValid = () => isValid && Object.keys(touched).length && !isEqual(data, values);

    return (
        <FormikProvider value={formik}>
            <Form title={makeTitle({ formType, category })} onSubmit={handleSubmit}>
                <Stack spacing={3} m={4}>
                    <TextField
                        key={SECTION_FIELDS.title}
                        id={SECTION_FIELDS.title}
                        name={SECTION_FIELDS.title}
                        label={t(SECTION_FIELDS.title)}
                        value={values[SECTION_FIELDS.title] ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched[SECTION_FIELDS.title] && errors[SECTION_FIELDS.title]}
                        error={Boolean(touched[SECTION_FIELDS.title] && errors[SECTION_FIELDS.title])}
                        onInput={() => setFieldTouched(SECTION_FIELDS.title, true, true)}
                    />
                    <SelectField
                        key={SECTION_FIELDS.grade}
                        field={SECTION_FIELDS.grade}
                        label={SECTION_FIELDS.grade_id}
                        touched={touched}
                        errors={errors}
                        values={values}
                        selectValues={grades}
                        handleChange={(e, value) => {
                            setFieldValue(SECTION_FIELDS.grade, value?.id ?? '');
                            formType === FORM_TYPES.add && setFieldTouched(SECTION_FIELDS.grade, true, false);
                        }}
                        handleBlur={handleBlur}
                        autocomplete
                    />
                    <FormButtons
                        navLink={getSectionsLink(categoryId)}
                        formType={formType}
                        isFormValid={!!isFormValid()}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                    />
                </Stack>
            </Form>
        </FormikProvider>
    );
};

SectionForm.propTypes = {
    formType: PropTypes.oneOf([FORM_TYPES.add, FORM_TYPES.edit]).isRequired,
};

export default SectionForm;
