import PropTypes from 'prop-types';
import { useRef } from 'react';
import MenuItem from '../Table/TableMoreMenu/MenuItem/MenuItem';
import { FileInput } from './FileButton.styles';

const FileButton = ({ importFromFile, exportToFile }) => {
    const ACCEPTED_FILE_TYPES = '.csv, .xlsx';
    const uploadInputRef = useRef(null);

    return (
        <>
            <FileInput
                ref={uploadInputRef}
                type="file"
                accept={ACCEPTED_FILE_TYPES}
                onChange={importFromFile}
            />
            <MenuItem
                title={'importFromFile'}
                icon={'ant-design:file-add-outlined'}
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
            />
            <MenuItem title={'exportToFile'} icon={'bi:filetype-pdf'} onClick={exportToFile} />
        </>
    );
};

FileButton.propTypes = {
    importFromFile: PropTypes.func.isRequired,
    exportToFile: PropTypes.func.isRequired,
};

export default FileButton;
