import styled, { css } from 'styled-components';

export const FieldValue = styled.p`
    font-size: 1.125rem;

    ${(enabled) =>
        enabled
            ? css`
                  color: ${({ theme }) => theme.palette.text.secondary};
                  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
              `
            : css`
                  color: ${({ theme, value }) =>
                      value ? theme.palette.text.secondary : theme.palette.text.disabled};
                  font-weight: ${({ theme, value }) =>
                      value ? theme.typography.fontWeightRegular : theme.typography.fontWeightThin};
              `}
`;

export const FieldContainer = styled.div`
    min-width: 250px;
    margin: 0.5rem;

    &:last-child {
        min-width: 100px;
    }
`;

export const FieldTitle = styled.p`
    font-size: 1.25rem;
`;
