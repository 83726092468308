import { styled } from '@mui/material/styles';

const OFFSET = 122;

export const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

export const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',

    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    minHeight: `calc(100vh - ${OFFSET}px)`,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),

    [theme.breakpoints.down('md')]: {
        justifyContent: 'start',
    },
}));
