import { Button } from '@mui/material';
import Iconify from '../../Iconify/Iconify';

const ExpandButton = ({ onClick, expanded }) => (
    <Button onClick={onClick} disabled={expanded}>
        <Iconify
            icon={expanded ? 'ic:round-keyboard-arrow-down' : 'ic:round-keyboard-arrow-right'}
            sx={{ fontSize: '2rem' }}
        ></Iconify>
    </Button>
);
export default ExpandButton;
