import { Tab, TextField } from '@mui/material';
import styled from 'styled-components';

export const ModalWrapper = styled.div``;

export const ModalContainer = styled.div`
    width: 90%;
    max-width: 600px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ButtonsContainer = styled.div`
    margin-top: 2rem;
    position: absolute;
    bottom: 0;
    width: calc(100% - 3rem);
    margin-bottom: ${({ marginBottom }) => marginBottom ?? 0};
`;

export const StyledTextField = styled(TextField)`
    width: 100%;
    margin-top: 1rem;
`;

export const StyledTab = styled(Tab)`
    pointer-events: auto !important;
    flex: 1;
`;

export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
`;
