import styled from 'styled-components';

export const ContentStyle = styled.div`
    max-width: 480px;
    margin: auto;
    min-height: calc(100vh - 250px - 8rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 8rem;
    margin-bottom: 8rem;
`;

export const ErrorContainer = styled.div`
    display: flex;
    margin: 3rem;
    font-size: 4rem;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    letter-spacing: 0.5rem;
`;
