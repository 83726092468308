import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { StyledOverlay } from './LoadingOverlay.styles';

const LoadingOverlay = ({ fullScreen }) => {
    const theme = useTheme();

    return (
        <StyledOverlay fullScreen={fullScreen}>
            <LoadingSpinner size={40} color={theme.palette.common.red} loading />
        </StyledOverlay>
    );
};

LoadingOverlay.defaultProps = {
    fullScreen: false,
};

LoadingOverlay.propTypes = {
    fullScreen: PropTypes.bool,
};

export default LoadingOverlay;
