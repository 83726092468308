import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowIcon from '../../assets/icons/arrow-up.svg';
import { WarningArrow, WarningArrowContainer, WarningContainer, WarningText } from './WarningInfo.styles';

const WarningInfo = ({ displayWarning, warningText, fullWidth, width }) => {
    const { t } = useTranslation();

    return (
        <WarningContainer displayWarning={displayWarning} width={width} fullWidth={fullWidth}>
            <WarningText>{t(warningText)}</WarningText>
            <WarningArrowContainer>
                <WarningArrow src={ArrowIcon} />
            </WarningArrowContainer>
        </WarningContainer>
    );
};

WarningInfo.defaultProps = {
    displayWarning: false,
    warningText: '',
    fullWidth: false,
};

WarningInfo.propTypes = {
    displayWarning: PropTypes.bool,
    warningText: PropTypes.string,
    fullWidth: PropTypes.bool,
};

export default WarningInfo;
