import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const editorStateToHtml = (editorState) => {
    const DEFAULT_HTML = '<p></p>';
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const convertedHtml = html.substring(0, html.length - 1);

    return convertedHtml === DEFAULT_HTML ? '' : convertedHtml;
};

export default editorStateToHtml;
