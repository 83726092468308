import { useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isArray, isObject } from 'lodash';
import { ANSWER_SCHEMA } from '../../../utils/constants';
import { groupRows } from '../../../utils/helpers';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import GroupedTableRow from '../GroupedTableRow/GroupedTableRow';
import NoRows from '../NoRows/NoRows';

const TOOLTIP_ID = 'custom_tooltip';
const DEFAULT_TOOLTIP_SHOW_DELAY = 400;
const DEFAULT_TOOLTIP_CLASS_NAME = 'custom_tooltip_class';

const TableBase = ({
    columnDefs,
    rowData,
    tooltipShowDelay = DEFAULT_TOOLTIP_SHOW_DELAY,
    onCellValueChanged: onCellChangePropCallback,
    supressOnCellChange,
    selected,
    isFetching = false,
    hideHeader,
    rowStyles,
}) => {
    let timeoutId;
    const theme = useTheme();
    const tableRef = useRef(null);
    const tHeadRef = useRef(null);
    const [groups, setGroups] = useState([]);
    const { t } = useTranslation();
    const validRowData = isArray(rowData) ? rowData : [rowData];

    const TABLE_BASE_STYLES = {
        height: '100%',
        position: 'relative',
    };

    const makeStyle = (col) => {
        const staticRowStyles = isObject(rowStyles) && rowStyles;

        const colStyles = Object.fromEntries(
            Object.entries(col).filter((item) => {
                const STYLES = ['maxWidth', 'minWidth', 'width', 'height'];
                const key = item.at(0);
                return STYLES.includes(key);
            })
        );

        return { ...colStyles, ...staticRowStyles };
    };

    const handleTooltipPosition = (e) => {
        const existingTooltip = document.getElementById(TOOLTIP_ID);
        if (!existingTooltip) return;
        existingTooltip.style.left = `${e.pageX + 20}px`;
        existingTooltip.style.top = `${e.pageY + 20}px`;
    };

    const showTooltip = (e, toolTip) => {
        const existingTooltip = document.getElementById(TOOLTIP_ID);
        const body = document.getElementsByTagName('body')[0];
        if (e.target.contains(existingTooltip)) return;
        if (existingTooltip) {
            existingTooltip.outerHTML = '';
        }
        const htmlTooltip = document.createElement('span');
        htmlTooltip.appendChild(document.createTextNode(toolTip));

        htmlTooltip.setAttribute('id', TOOLTIP_ID);
        htmlTooltip.setAttribute('class', DEFAULT_TOOLTIP_CLASS_NAME);

        timeoutId = setTimeout(() => {
            body.appendChild(htmlTooltip);
            htmlTooltip.style.left = `${e.pageX + 20}px`;
            htmlTooltip.style.top = `${e.pageY + 20}px`;
        }, tooltipShowDelay);
    };

    const deleteTooltip = () => {
        clearTimeout(timeoutId);
        const existingTooltip = document.getElementById(TOOLTIP_ID);
        if (!existingTooltip) return;
        existingTooltip.outerHTML = '';
    };

    const onCellChange = () => {
        if (!onCellChangePropCallback) return;
        onCellChangePropCallback({ tableRef });
    };

    const handleOnCellChange = () => {
        if (supressOnCellChange) return;
        onCellChange();
    };

    const checkCellRendererParams = (crp, rowData) => {
        if (!crp) {
            return { ...rowData };
        }
        if (typeof crp === 'function') {
            return { ...crp({ ...rowData }), ...rowData };
        }
        return { ...crp, ...rowData };
    };

    const checkRowDataType = (row, field, column, paramsFromRowData) =>
        column?.valueGetter(paramsFromRowData) ?? row[field] ?? '';

    const cellContent = (column, paramsFromRowData, row) =>
        'cellRenderer' in column && typeof column.cellRenderer === 'function'
            ? column.cellRenderer({
                  ...paramsFromRowData,
              })
            : checkRowDataType(row, column.field, column, paramsFromRowData);

    const generateTableRows = () =>
        validRowData.map((row, indexRow) => {
            const trData = columnDefs.filter(Boolean).map((column, indexCol) => {
                const paramsFromRowData = {
                    ...checkCellRendererParams(column.cellRendererParams, {
                        rowIndex: indexRow,
                        node: { id: indexRow },
                        data: { ...row },
                        onCellChange,
                    }),
                };
                return (
                    <td
                        onChange={handleOnCellChange}
                        key={`td${indexRow}${indexCol}`}
                        className={column.field === ANSWER_SCHEMA.content ? 'content-cell' : column.cellClass}
                        style={makeStyle(column)}
                    >
                        {cellContent(column, paramsFromRowData, row, indexRow)}
                    </td>
                );
            });
            return (
                <tr className={selected?.index === indexRow ? 'selected' : ''} key={`tr${indexRow}`}>
                    {trData}
                </tr>
            );
        });

    const generateGroupedTableRows = () => {
        const { expanded } = columnDefs.filter(Boolean).filter((i) => i.rowGroup)[0];
        const grouped = groupRows(validRowData, groups[0].name);
        return Object.entries(grouped)
            .sort()
            .map((groupData, indexGroup) => {
                const groupName = groupData[0];
                const rows = groupData[1];
                const { id } = rows[0];
                if (!id) {
                    console.warn('ID is crucial for proper working of table.');
                }
                const group = rows.map((row, indexRow) => {
                    const cells = columnDefs.filter(Boolean).map((column, indexCol) => {
                        const paramsFromRowData = {
                            ...checkCellRendererParams(column.cellRendererParams, {
                                rowIndex: indexRow,
                                node: { id: indexRow },
                                data: { ...row },
                                onCellChange,
                            }),
                        };
                        return (
                            <td
                                onChange={handleOnCellChange}
                                key={`td${indexRow}${indexCol}${indexGroup}`}
                                className={
                                    column.field === ANSWER_SCHEMA.content ? 'content-cell' : column.cellClass
                                }
                                style={makeStyle(column)}
                            >
                                {cellContent(column, paramsFromRowData, row, indexRow)}
                            </td>
                        );
                    });
                    return <tr key={`${row.deletionId}${row.type}`}>{[cells]}</tr>;
                });
                return (
                    <GroupedTableRow key={`tr${groupName}${id}`} groupName={groupName} expandAll={expanded}>
                        {group}
                    </GroupedTableRow>
                );
            });
    };

    useEffect(() => {
        let groups = [];
        columnDefs.filter(Boolean).forEach((column) => {
            groups = [...groups, { name: column.field, isRowGroup: !!column.rowGroup }];
            setGroups(groups.filter((group) => group.isRowGroup));
        });
    }, [columnDefs]);

    const defineColumnDefs = () =>
        columnDefs.filter(Boolean).map((column, index) => (
            <th
                className={'ag-header-cell-text'}
                key={index}
                scope="col"
                style={makeStyle(column)}
                onMouseEnter={(e) => 'headerTooltip' in column && showTooltip(e, column.headerTooltip)}
                onMouseLeave={deleteTooltip}
                onMouseMove={handleTooltipPosition}
                data-colid={column.field}
            >
                {column.headerName ?? column.field}
            </th>
        ));

    if (!columnDefs || !validRowData || isFetching)
        return (
            <div className="loading-wrapper">
                <LoadingSpinner color={theme.palette.common.red} size={60} loading />
            </div>
        );

    return (
        <div style={TABLE_BASE_STYLES}>
            {!validRowData.length && <NoRows>{t('tableIsEmpty')}</NoRows>}
            <table ref={tableRef}>
                <thead style={hideHeader && { display: 'none' }}>
                    <tr ref={tHeadRef}>{defineColumnDefs()}</tr>
                </thead>
                <tbody>{groups.length ? generateGroupedTableRows() : generateTableRows()}</tbody>
            </table>
        </div>
    );
};

export default React.memo(TableBase);
