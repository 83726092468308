import SectionTitle from '../SectionTitle/SectionTitle';
import { FormStyled } from './Form.styles';

const Form = ({ children, title, ...props }) => (
    <FormStyled {...props}>
        {title && <SectionTitle title={title} />}
        {children}
    </FormStyled>
);

export default Form;
