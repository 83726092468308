import { t } from 'i18next';
import * as Yup from 'yup';

export const FIELDS = {
    email: 'email',
    password: 'password',
    nickname: 'nickname',
};

export const useValidators = () => {
    const emailValidator = Yup.string().email(t('emailRequirements'));
    const passwordValidator = Yup.string();
    const nicknameValidator = Yup.string();
    return {
        [FIELDS.email]: {
            required: emailValidator.required(t('emailRequired')),
            optional: emailValidator,
        },
        [FIELDS.password]: {
            required: passwordValidator.required(t('passwordRequired')),
            optional: passwordValidator,
            matches: passwordValidator
                .matches(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/, t('passwordValidation'))
                .required(t('passwordRequired')),
            confirmation: Yup.string()
                .oneOf([Yup.ref(FIELDS.password)], t('passwordsMatch'))
                .required(t('passwordRequired')),
        },
        [FIELDS.nickname]: {
            matches: nicknameValidator
                .min(3, t('nicknameMinCharacters'))
                .max(15, t('nicknameMaxCharacters'))
                .matches(/[a-zA-Z]+/, t('nicknameRequirements')),
        },
    };
};
