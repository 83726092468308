import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { closeAlert, openAlertError } from '../../../actions/alertActions';
import { saveUserData } from '../../../actions/userActions';
import { LOCAL_STORAGE_KEY, NAV_LINKS } from '../../../utils/constants';
import { loginUser } from '../../../utils/fetchData';
import { FIELDS, useValidators } from '../../../utils/validators';
import Iconify from '../../Iconify/Iconify';
import { StyledLink } from './LoginForm.styles';

const LoginForm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [showPassword, setShowPassword] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();
    const validators = useValidators();

    const LoginSchema = Yup.object().shape({
        [FIELDS.email]: validators[FIELDS.email].required,
        [FIELDS.password]: validators[FIELDS.password].required,
    });

    const formik = useFormik({
        initialValues: {
            [FIELDS.email]: '',
            [FIELDS.password]: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (data) => {
            dispatch(closeAlert());
            setIsFetching(true);
            loginUser({ email: data.email, password: data.password })
                .then((res) => {
                    if (res.success) {
                        const { id, first_name, last_name, email, role, sex } = res.user;

                        localStorage.setItem(LOCAL_STORAGE_KEY.token, res.token);

                        dispatch(
                            saveUserData({
                                id,
                                first_name,
                                last_name,
                                email,
                                role,
                                sex,
                            })
                        );
                        navigate(NAV_LINKS.dashboard, { replace: true });
                    } else {
                        dispatch(openAlertError('unauthorizedLogin'));
                    }
                })
                .catch(() => {
                    dispatch(openAlertError());
                })
                .finally(() => {
                    setSubmitting(false);
                    setIsFetching(false);
                });
        },
    });

    const {
        errors,
        touched,
        isValid,
        setFieldTouched,
        handleSubmit,
        handleBlur,
        getFieldProps,
        isSubmitting,
        setSubmitting,
    } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const [isFormValid, setIsFormValid] = useState(isValid && Object.keys(touched).length);

    useEffect(() => {
        setIsFormValid(isValid && Object.keys(touched).length);
    });

    return (
        <>
            <FormikProvider value={formik}>
                <Form style={{ zIndex: 1 }} autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <TextField
                            id={FIELDS.email}
                            name={FIELDS.email}
                            fullWidth
                            autoComplete={FIELDS.email}
                            type={FIELDS.email}
                            label={t(FIELDS.email)}
                            onBlur={handleBlur}
                            {...getFieldProps(FIELDS.email)}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            onInput={() => setFieldTouched(FIELDS.email, true, true)}
                        />

                        <TextField
                            id={FIELDS.password}
                            name={FIELDS.password}
                            fullWidth
                            autoComplete="current-password"
                            type={showPassword ? 'text' : 'password'}
                            label={t(FIELDS.password)}
                            onBlur={handleBlur}
                            {...getFieldProps(FIELDS.password)}
                            onInput={() => setFieldTouched(FIELDS.password, true, true)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword} edge="end">
                                            <Iconify
                                                icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />

                        <StyledLink variant="subtitle2" onClick={() => navigate(NAV_LINKS.recoverPassword)}>
                            {t('forgotPassword')}
                        </StyledLink>

                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isFetching}
                            disabled={!isFormValid || isSubmitting}
                        >
                            {t('signIn')}
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </>
    );
};

export default LoginForm;
