/* eslint-disable no-nested-ternary */
import { isEmpty } from 'lodash';
import { ALL_DATA, ANSWER_SCHEMA, DATA_TYPES, MANAGE_DATA, MANAGE_DATA_TYPES } from './constants';
import { getToken } from './helpers';
import { history } from './history';

const addFilters = (urlObject, filters) =>
    !isEmpty(filters) &&
    filters.split('&').forEach((filter) => {
        const [name, value] = filter.split('=');
        urlObject.searchParams.append(name, value);
    });

const METHODS = {
    get: 'GET',
    post: 'POST',
    put: 'PUT',
    delete: 'DELETE',
};

const fetchClient = (url, options) => {
    const headers = { 'Content-Type': 'application/json' };
    const token = getToken();
    if (token) headers.Authorization = `Bearer ${token}`;

    return fetch(url, { method: METHODS.get, headers, ...options }).then((res) => {
        if (!res.ok && res.status === 401) {
            history.navigate('/login');
        }
        return res.json();
    });
};

export const loginUser = (payload) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/login`, {
        method: METHODS.post,
        body: JSON.stringify(payload),
    });

export const logoutUser = () =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/logout`, {
        method: METHODS.post,
    });

export const getUser = () => fetchClient(`${process.env.REACT_APP_BACKEND_URL}/user/get`);

export const checkPasswordResetToken = (token) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/check-password-reset-token`, {
        method: METHODS.post,

        body: JSON.stringify({ token }),
    });

export const setProfile = (payload) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/set-profile`, {
        method: METHODS.post,
        body: JSON.stringify(payload),
    });

export const requestNewPassword = (email) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/request-new-password`, {
        method: METHODS.post,
        body: JSON.stringify({ email }),
    });

export const resetPassword = (payload) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/reset-password`, {
        method: METHODS.post,
        body: JSON.stringify(payload),
    });

export const changePassword = (payload) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/user/change-password`, {
        method: METHODS.put,
        body: JSON.stringify(payload),
    });

export const getDataById = (type, id, params = {}) => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/${type}/get/${id}`);
    !isEmpty(params) && url.searchParams.append(params?.name, params?.value);
    return fetchClient(url);
};

export const getData = (type, page = 1, perPage = 10, filters = '') => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/${type}/index`);
    url.searchParams.append('page', page === ALL_DATA ? '' : page);
    url.searchParams.append('perPage', page === ALL_DATA ? ALL_DATA : perPage);
    addFilters(url, filters);
    return fetchClient(url);
};

export const getParticipantsByGroup = (groupId, filters) => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/user/participant/group/${groupId}`);
    addFilters(url, filters);
    return fetchClient(url);
};

export const getOrganizationPackages = (organizationId) => {
    const url = new URL(
        `${process.env.REACT_APP_BACKEND_URL}/commerce/organization-package/list/${organizationId}`
    );
    url.searchParams.append('perPage', ALL_DATA);
    return fetchClient(url);
};

export const getUserPackageList = (organizationId) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/user/package/list/organization/${organizationId}`);

export const getQuiz = (packageId) => fetchClient(`${process.env.REACT_APP_BACKEND_URL}/quiz/${packageId}`);

export const getSectionsByCategory = (categoryId, paginationSize, filters) => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/section/category/${categoryId}`);
    url.searchParams.append('perPage', paginationSize);
    addFilters(url, filters);
    return fetchClient(url);
};

export const getQuizResults = async (quizId, resultOptions, page = 1, paginationSize, filters) => {
    const { organization, year, month } = resultOptions;
    const url = new URL(
        `${process.env.REACT_APP_BACKEND_URL}/survey/quiz/ranking/${quizId}/${organization}/${year}/${month}`
    );
    url.searchParams.append('page', page);
    url.searchParams.append('perPage', paginationSize);
    addFilters(url, filters);
    return fetchClient(url);
};

export const getQuestionList = (type, id, paginationSize, page, filters) => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/${type}/question/list/${id}`);
    url.searchParams.append('perPage', paginationSize === ALL_DATA ? ALL_DATA : paginationSize);
    url.searchParams.append('page', page);
    addFilters(url, filters);
    return fetchClient(url);
};

export const addData = (type, payload) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/${type}/add`, {
        method: METHODS.post,
        body: JSON.stringify(payload),
    });

export const createData = (type, payload) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/${type}/create`, {
        method: METHODS.post,
        body: JSON.stringify(payload),
    });

export const updateData = (type, id, payload) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/${type}/update/${id}`, {
        method: METHODS.put,
        body: JSON.stringify(payload),
    });

export const deleteData = (type, id) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/${type}/delete/${id}`, {
        method: METHODS.delete,
    });

export const changeData = (type, payload) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/${type}/change`, {
        method: METHODS.put,
        body: JSON.stringify(payload),
    });

export const createTest = (payload) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/test/create`, {
        method: METHODS.post,
        body: JSON.stringify(payload),
    });

export const manageUserOrganization = async (type, id, payload) => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/user/organization/${type}`);
    type === MANAGE_DATA_TYPES.detach && (url.pathname += `/${id}`);
    return fetchClient(url, {
        method: type === MANAGE_DATA_TYPES.detach ? METHODS.delete : METHODS.post,
        body: JSON.stringify(payload),
    });
};

export const getGroups = (organizationIds) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/participant-group/get`, {
        method: METHODS.post,
        body: JSON.stringify({
            organization_id: organizationIds,
        }),
    });

export const importUserParticipants = async (payload) => {
    const form = new FormData();
    form.append('file', payload.file);
    form.append('organization_id', payload.organization_id);
    form.append('group_id', payload.group_id);

    const res = await fetchClient(`${process.env.REACT_APP_BACKEND_URL}/user/participant/import`, {
        method: METHODS.post,
        body: form,
    });

    return res;
};

export const exportUserParticipants = (id) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/participant-group/export/${id}`);

export const getGroupParticipants = (groupId) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/user/participant/group/${groupId}`);

export const getLogActivityTypes = () =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/log-activity/types`);

export const getQuestionUsage = async (questionId) =>
    fetchClient(`${process.env.REACT_APP_BACKEND_URL}/question/used/${questionId}`);

export const manageQuestion = async (payload, id) => {
    const form = new FormData();
    const { ordinaryNumber, answers, ...answerFields } = ANSWER_SCHEMA;

    Object.keys(payload).forEach((element) => {
        if (element === ANSWER_SCHEMA.answers) {
            payload.answers.forEach((answer, index) => {
                const { id: answerId, ...fields } = answerFields;
                Object.values(id ? answerFields : fields).forEach((answerField) => {
                    answerField in answer &&
                        form.append(
                            `${answers}[${index}][${answerField}]`,
                            answerField === answerFields.isCorrect
                                ? Number(answer[answerField])
                                : answer[answerField]
                        );
                });
            });
        } else {
            form.append(element, payload[element]);
        }
    });

    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/${DATA_TYPES.question}`);
    if (id) {
        url.pathname += `/update/${id}`;
        url.searchParams.append('_method', METHODS.put);
    } else {
        url.pathname += `/create`;
    }
    const res = await fetchClient(url, {
        method: METHODS.post,
        body: form,
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    });

    return res;
};

export const manageMedia = async (type, media) => {
    let form;
    if (type === MANAGE_DATA.upload) {
        form = new FormData();
        form.append('media', media);
    }

    const authorizationHeader = { Authorization: `Bearer ${getToken()}` };
    const headers =
        type === MANAGE_DATA.upload
            ? authorizationHeader
            : { 'Content-Type': 'application/json', ...authorizationHeader };

    const res = await fetchClient(`${process.env.REACT_APP_BACKEND_URL}/media/${type}`, {
        method: type === MANAGE_DATA.upload ? 'post' : 'delete',
        headers,
        body: type === MANAGE_DATA.upload ? form : JSON.stringify({ media }),
    });
    return res;
};
