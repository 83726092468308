import styled from 'styled-components';
import { PREVIEW_SIZE } from '../MediaInput.styles';

export const PlayerWrapper = styled.div`
    max-width: ${PREVIEW_SIZE}px;
    max-height: ${PREVIEW_SIZE}px;

    iframe {
        width: ${PREVIEW_SIZE}px;
        height: ${PREVIEW_SIZE}px;
    }
`;
