import { Box, MenuItem, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCAL_STORAGE_KEY } from '../../utils/constants';
import LANGUAGES from '../../utils/languages';
import MenuPopover from '../MenuPopover/MenuPopover';
import { StyledIconButton } from './LanguagePopover.styles';

const LanguagePopover = () => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeWebsiteLanguage = (language) => {
        i18n.changeLanguage(language).then(() => {
            localStorage.setItem(LOCAL_STORAGE_KEY.language, language);
            handleClose();
        });
    };

    return (
        <>
            <StyledIconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    mr: 0.5,
                    backgroundColor: '#f2eded',
                    ...(open && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
                    }),
                }}
            >
                <img
                    src={LANGUAGES.filter((language) => language.value === i18n.language)[0].icon}
                    alt={LANGUAGES.filter((language) => language.value === i18n.language)[0].label}
                    style={{ width: '60%', height: '60%' }}
                />
            </StyledIconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Stack spacing={0.75}>
                    {LANGUAGES.map((option) => (
                        <MenuItem
                            key={option.value}
                            selected={option.value === i18n.language}
                            onClick={() => changeWebsiteLanguage(option.value)}
                        >
                            <Box
                                component="img"
                                alt={option.label}
                                src={option.icon}
                                sx={{ width: 28, mr: 2 }}
                            />

                            {t(option.label)}
                        </MenuItem>
                    ))}
                </Stack>
            </MenuPopover>
        </>
    );
};

export default LanguagePopover;
