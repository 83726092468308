import { FormGroup, Stack, TextField } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { camelCase, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import { ALL_DATA, DATA_TYPES, FORM_TYPES, NAV_LINKS, TABLE_SETTINGS } from '../../../utils/constants';
import { createData, getData, getDataById, updateData } from '../../../utils/fetchData';
import { capitalizeFirstLetter, getErrorLink, getTableFieldsObject, makeTitle } from '../../../utils/helpers';
import Checkbox from '../../Checkbox/Checkbox';
import Form from '../../Form/Form';
import FormButtons from '../../Form/FormButtons/FormButtons';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';
import SelectField from '../../SelectField/SelectField';

const PackagesForm = ({ formType, category }) => {
    const PACKAGE_SCHEMA = {
        name: '',
        description: '',
        category_id: '',
        grade_id: '',
        has_quiz: true,
        has_training: true,
    };
    const PACKAGE_FIELDS = {
        name: 'name',
        description: 'description',
        category_id: 'category',
        grade_id: 'grade',
        has_quiz: 'quiz',
        has_training: 'training',
    };

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState(PACKAGE_SCHEMA);
    const [categories, setCategories] = useState([]);
    const [grades, setGrades] = useState([]);
    const [isFetching, setIsFetching] = useState(formType === FORM_TYPES.edit);
    const [isFetchingAdditionalData, setIsFetchingAdditionalData] = useState(true);
    const [isDisabled, setIsDisabled] = useState({
        has_quiz: false,
        has_training: false,
    });
    const { id: packageId } = useParams();

    useEffect(() => {
        if (formType === FORM_TYPES.edit) {
            getDataById(DATA_TYPES.package, packageId)
                .then((res) => {
                    if (res.error) {
                        navigate(getErrorLink(camelCase(res.error)));
                    } else {
                        res = Object.fromEntries(
                            Object.entries(res).map((item) => {
                                const key = item.at(0);
                                const val = item.at(1);
                                if (key === PACKAGE_FIELDS.has_quiz || key === PACKAGE_FIELDS.has_training) {
                                    return [`has_${key}`, !!val];
                                }
                                return val === null ? [key, ''] : [key, val];
                            })
                        );
                        delete res.category;
                        delete res.grade;

                        const { has_quiz, has_training } = res;
                        if (has_quiz || has_training) {
                            setIsDisabled({
                                has_training,
                                has_quiz,
                            });
                        }
                        setValues(res);
                        setData(res);
                    }
                })
                .catch(() => navigate(NAV_LINKS.notFound))
                .finally(() => setIsFetching(false));
        }

        Promise.all([getData(DATA_TYPES.category, ALL_DATA), getData(DATA_TYPES.grade, ALL_DATA)])
            .then(([categoriesRes, gradesRes]) => {
                if (categoriesRes.error || gradesRes.error) {
                    navigate(NAV_LINKS.packages);
                    dispatch(openAlertError());
                } else {
                    setCategories(categoriesRes.data);
                    setGrades(gradesRes.data);
                }
            })
            .catch(() => {
                navigate(NAV_LINKS.notFound);
                dispatch(openAlertError());
            })
            .finally(() => setIsFetchingAdditionalData(false));
    }, []);

    const DataSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, t('packageNameMinCharacters'))
            .max(255, t('packageNameMaxCharacters'))
            .required(t('packageNameRequired')),
        description: Yup.string()
            .min(2, t('packageDescriptionMinCharacters'))
            .max(255, t('packageDescriptionMaxCharacters'))
            .nullable(true),
        category_id: Yup.number().required(t('categoryRequired')),
        grade_id: Yup.number().required(t('gradeRequired')),
        has_quiz: Yup.boolean().required(),
        has_training: Yup.boolean().required(),
    });

    const formik = useFormik({
        initialValues: PACKAGE_SCHEMA,
        validationSchema: DataSchema,
        onSubmit: (values) =>
            formType === FORM_TYPES.add ? addPackages(values) : updatePackages(packageId, values),
    });

    const {
        errors,
        touched,
        isValid,
        values,
        setValues,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        setSubmitting,
        isSubmitting,
    } = formik;

    const addPackages = (payload) => {
        createData(DATA_TYPES.package, {
            ...payload,
        })
            .then((res) => {
                if (res.success) {
                    navigate(NAV_LINKS.packages);
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                dispatch(openAlertError());
            })
            .finally(() => setSubmitting(false));
    };

    const updatePackages = (id, payload) => {
        updateData(DATA_TYPES.package, id, payload)
            .then((res) => {
                if (res.success) {
                    navigate(NAV_LINKS.packages);
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                dispatch(openAlertError());
            })
            .finally(() => setSubmitting(false));
    };

    const isFormValid = () => isValid && Object.keys(touched).length && !isEqual(data, values);

    const createTextField = (field) => (
        <TextField
            key={field}
            id={field}
            name={field}
            label={t(getTableFieldsObject(TABLE_SETTINGS.package.tableHead)[field])}
            value={values[field] ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched[field] && errors[field]}
            error={Boolean(touched[field] && errors[field])}
            onInput={() => setFieldTouched(field, true, true)}
            {...(PACKAGE_FIELDS[field] === PACKAGE_FIELDS.description
                ? {
                      multiline: true,
                      rows: 6,
                  }
                : {})}
        />
    );

    const createSelectField = (field, selectValues) => (
        <SelectField
            key={field}
            field={field}
            label={PACKAGE_FIELDS[field]}
            touched={touched}
            errors={errors}
            values={values}
            selectValues={selectValues}
            handleChange={(e, value) => {
                setFieldValue(field, value?.id ?? '');
                formType === FORM_TYPES.edit && setFieldTouched(field, true, false);
            }}
            handleBlur={handleBlur}
            autocomplete
        />
    );

    const createCheckboxField = (field) => (
        <Checkbox
            key={field}
            field={field}
            label={t(`labelPackage${capitalizeFirstLetter(PACKAGE_FIELDS[field])}`)}
            handleChange={(e, val) => {
                setFieldValue(field, val);
                setFieldTouched(field, true, false);
            }}
            values={values}
            disabled={isDisabled[field]}
        />
    );

    const getFieldType = () => {
        const { grade_id, category_id, has_quiz, has_training, ...textFields } = PACKAGE_FIELDS;

        return {
            text: Object.keys(textFields),
            select: Object.keys({ category_id, grade_id }),
            checkbox: Object.keys({ has_quiz, has_training }),
        };
    };

    const getSelectValues = (type) => {
        switch (type) {
            case PACKAGE_FIELDS.grade_id:
                return grades;
            case PACKAGE_FIELDS.category_id:
                return categories;
            default:
                return [];
        }
    };

    const generateTextFields = () => getFieldType().text.map((field) => createTextField(field));

    const generateSelectFields = () =>
        getFieldType().select.map((field) =>
            createSelectField(field, getSelectValues(PACKAGE_FIELDS[field]))
        );

    const generateCheckboxFields = () => (
        <FormGroup sx={{ paddingLeft: 0.5 }}>
            {getFieldType().checkbox.map((field) => createCheckboxField(field))}
        </FormGroup>
    );

    const isDataFetching = () => isFetching || isFetchingAdditionalData;

    return isDataFetching() ? (
        <LoadingOverlay fullScreen />
    ) : (
        <FormikProvider value={formik}>
            <Form title={makeTitle({ formType, category })} onSubmit={handleSubmit}>
                <Stack spacing={3} m={4}>
                    {generateTextFields()}
                    {generateSelectFields()}
                    {generateCheckboxFields()}
                    <FormButtons
                        navLink={NAV_LINKS.packages}
                        formType={formType}
                        isFormValid={!!isFormValid()}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                    />
                </Stack>
            </Form>
        </FormikProvider>
    );
};

PackagesForm.propTypes = {
    formType: PropTypes.oneOf([FORM_TYPES.add, FORM_TYPES.edit]).isRequired,
};

export default PackagesForm;
