import AddIcon from '@mui/icons-material/AddCircle';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import { Stack, TextField } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { FormikProvider, useFormik } from 'formik';
import { camelCase, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import useBrowserWidth from '../../../hooks/useBrowserWidth';
import {
    ALL_DATA,
    DATA_TYPES,
    DECIMAL_REGEX,
    FORM_TYPES,
    NAV_LINKS,
    NUMBER_REGEX,
    OVERLAY_TYPES,
} from '../../../utils/constants';
import { createData, getData, getDataById, updateData } from '../../../utils/fetchData';
import { getErrorLink, makeTitle, preparePayload } from '../../../utils/helpers';
import AG_GRID_LOCALE from '../../../utils/tableTranslations';
import CustomTable from '../../CustomTable/CustomTable';
import Form from '../../Form/Form';
import {
    Field,
    FieldContainer,
    FlexContainer,
    InputContainer,
    MarginBlock,
    MarginContainer,
    MarginInline,
    TableWrapper,
    TitleContainer,
} from '../../Form/Form.styles';
import FormButtons from '../../Form/FormButtons/FormButtons';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';
import SectionTitle from '../../SectionTitle/SectionTitle';
import SelectField from '../../SelectField/SelectField';
import { FormTableWrapper } from '../../Table/Table.styles';
import { StyledIconButton } from './ProductForm.styles';

const ProductForm = ({ formType, category }) => {
    const DEFAULT_PACKAGE = {
        package_id: '',
        quantity: '',
    };

    const PRODUCT_SCHEMA = {
        name: '',
        description: '',
        price: '',
        packages: [DEFAULT_PACKAGE],
    };

    const PACKAGE_FIELDS = {
        package: 'package',
        packageId: 'package_id',
        productPackageId: 'product_package_id',
        packageName: 'package_name',
        quantity: 'quantity',
    };

    const PRODUCT_FIELDS = {
        name: 'name',
        description: 'description',
        price: 'price',
        packages: 'packages',
    };

    const INFO_FIELDS = {
        description: 'description',
        data: 'data',
    };

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState(PRODUCT_SCHEMA);
    const [isFetching, setIsFetching] = useState(formType === FORM_TYPES.edit);
    const [isFetchingAdditionalData, setIsFetchingAdditionalData] = useState(true);
    const [packages, setPackages] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const { id: dataId } = useParams();
    const currentBrowserWidth = useBrowserWidth();
    const DESKTOP_WIDTH = 1366;

    useEffect(() => {
        if (formType === FORM_TYPES.edit || formType === FORM_TYPES.show) {
            getDataById(DATA_TYPES.product, dataId)
                .then((res) => {
                    if (res.error) {
                        navigate(getErrorLink(camelCase(res.error)));
                    } else {
                        const resData = prepareData(res, true);
                        setValues(resData);
                        setData(resData);
                    }
                })
                .catch(() => navigate(NAV_LINKS.notFound))
                .finally(() => setIsFetching(false));
        }

        getData(DATA_TYPES.package, ALL_DATA)
            .then((res) => {
                if (res.error) {
                    navigate(NAV_LINKS.products);
                    dispatch(openAlertError(res.error));
                } else {
                    setPackages(res.data);
                }
            })
            .catch(() => {
                navigate(NAV_LINKS.notFound);
                dispatch(openAlertError());
            })
            .finally(() => setIsFetchingAdditionalData(false));
    }, []);

    useEffect(() => {
        if (gridApi) {
            if (currentBrowserWidth >= DESKTOP_WIDTH) {
                gridApi.api.sizeColumnsToFit();
            }
        }
    }, [gridApi, currentBrowserWidth]);

    const isDecimal = (val) => (val ? DECIMAL_REGEX.test(val) && !val.endsWith('.') : true);

    const DataSchema = Yup.object().shape({
        name: Yup.string().min(4, t('nameMinCharacters')).required(t('nameRequired')),
        description: Yup.string().min(4, t('descriptionMinCharacters')).nullable(),
        price: Yup.string()
            .test('is-decimal', t('priceValidation'), isDecimal)
            .required(t('priceRequired'))
            .max(8, t('maxPrice')),
        packages: Yup.array().of(
            Yup.object()
                .shape({
                    package_id: Yup.number().required(t('packageRequired')),
                    quantity: Yup.number()
                        .moreThan(0, t('quantityValidation'))
                        .lessThan(1000, t('quantityValidationMax'))
                        .required(t('quantityRequired'))
                        .typeError(t('quantityValidation')),
                })
                .required()
        ),
    });

    const formik = useFormik({
        initialValues: PRODUCT_SCHEMA,
        validationSchema: DataSchema,
        onSubmit: (values) => (formType === FORM_TYPES.add ? addData(values) : updateValues(dataId, values)),
    });

    const {
        errors,
        touched,
        isValid,
        values,
        setValues,
        setFieldTouched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
    } = formik;

    const prepareData = (data, convert) => ({
        ...data,
        price: data.price.replace(convert ? '.' : ',', convert ? ',' : '.'),
        packages: data.packages.map((pack) => ({
            ...pack,
            package_name: pack?.package?.package_name,
        })),
    });

    const addData = (payload) => {
        createData(DATA_TYPES.product, prepareData(payload, false))
            .then((res) => {
                if (res.success) {
                    navigate(NAV_LINKS.products);
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                dispatch(openAlertError());
            })
            .finally(() => setSubmitting(false));
    };

    const updateValues = (id, payload) => {
        const convertPackages = (packagesList) =>
            packagesList.map((pack) => ({
                product_package_id: pack.id,
                package_id: pack.package_id,
                quantity: pack.quantity,
            }));

        const initialValues = prepareData(data, false);
        initialValues.packages = convertPackages(initialValues.packages);
        const updatedValues = prepareData(payload, false);
        updatedValues.packages = convertPackages(updatedValues.packages);

        updateData(
            DATA_TYPES.product,
            id,
            preparePayload(
                initialValues,
                updatedValues,
                PRODUCT_FIELDS.packages,
                PACKAGE_FIELDS.productPackageId
            )
        )
            .then((res) => {
                if (res.success) {
                    navigate(NAV_LINKS.products);
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                dispatch(openAlertError());
            })
            .finally(() => setSubmitting(false));
    };

    const getRowHeight = (params) => (params.data.description === t('description') ? 180 : 60);

    const generateInfoContainer = () => {
        const valuesObj = {};
        const { packages, ...fields } = PRODUCT_FIELDS;

        Object.keys(fields).map((field) => {
            Object.assign(valuesObj, {
                [PRODUCT_FIELDS[field]]: values[field],
            });
        });

        const tableValues = Object.keys(valuesObj).map((value) => ({
            description: t(value),
            data: valuesObj[value],
        }));

        const COLUMN_DEFS = [
            {
                field: INFO_FIELDS.description,
                headerName: t(INFO_FIELDS.description),
                headerTooltip: t(INFO_FIELDS.description),
                pinned: 'left',
                width: 100,
                cellClass: 'ag-cell-center-justify',
            },
            {
                field: INFO_FIELDS.data,
                headerName: t(INFO_FIELDS.data),
                headerTooltip: t(INFO_FIELDS.data),
                cellClass: 'ag-cell-center-justify',
            },
        ];

        return (
            <FormTableWrapper className="ag-theme-material" height={'357px'} isPreview>
                <AgGridReact
                    defaultColDef={{
                        sortable: false,
                        resizable: true,
                        autoHeight: true,
                        filter: false,
                        wrapText: true,
                    }}
                    rowData={tableValues}
                    onGridReady={setGridApi}
                    getRowHeight={getRowHeight}
                    columnDefs={COLUMN_DEFS}
                    tooltipShowDelay={0}
                    localeText={AG_GRID_LOCALE[i18n.language]}
                    suppressDragLeaveHidesColumns
                    stopEditingWhenCellsLoseFocus
                    loadingOverlayComponent={LoadingOverlay}
                />
            </FormTableWrapper>
        );
    };

    const isFormValid = () => isValid && Object.keys(touched).length && !isEqual(data, values);

    const createTextField = (field) => (
        <TextField
            key={field}
            id={field}
            name={field}
            label={t(field)}
            value={values[field] ?? ''}
            onChange={(e) => {
                if (field === PRODUCT_FIELDS.price) {
                    const val = e.target.value;
                    if (DECIMAL_REGEX.test(val) || !val) {
                        setFieldValue(field, val);
                    }
                } else {
                    handleChange(e);
                }
            }}
            onBlur={handleBlur}
            helperText={touched[field] && errors[field]}
            error={Boolean(touched[field] && errors[field])}
            onInput={() => setFieldTouched(field, true, true)}
            {...(field === PRODUCT_FIELDS.description
                ? {
                      multiline: true,
                      rows: 6,
                  }
                : {})}
        />
    );

    const generateTextFields = () =>
        Object.keys(PRODUCT_FIELDS)
            .filter((field) => field !== PRODUCT_FIELDS.packages)
            .map((field) => createTextField(field));

    const managePackages = (index) => {
        setFieldTouched(PRODUCT_FIELDS.packages, true, false);
        setFieldValue(
            PRODUCT_FIELDS.packages,
            index ? values.packages.filter((_, idx) => idx !== index) : [...values.packages, DEFAULT_PACKAGE]
        );
    };

    const getFieldPackagesQuantity = (index) => {
        try {
            return touched.packages[index].quantity && errors.packages[index].quantity;
        } catch (e) {
            return '';
        }
    };

    const generateColumnDefs = () => [
        {
            field: PACKAGE_FIELDS.packageName,
            headerName: t(PACKAGE_FIELDS.package),
            headerTooltip: t(PACKAGE_FIELDS.package),
        },
        {
            field: PACKAGE_FIELDS.quantity,
            headerName: t(PACKAGE_FIELDS.quantity),
            headerTooltip: t(PACKAGE_FIELDS.quantity),
        },
    ];

    const generateButtonsContainer = () => (
        <FormButtons
            navLink={NAV_LINKS.products}
            formType={formType}
            isFormValid={!!isFormValid()}
            disabled={isSubmitting}
            isLoading={isSubmitting}
        />
    );

    const generateSelectFields = () =>
        values?.packages?.map((_, index) => {
            const packageId = `${PRODUCT_FIELDS.packages}.${index}.${PACKAGE_FIELDS.packageId}`;
            const quantity = `${PRODUCT_FIELDS.packages}.${index}.${PACKAGE_FIELDS.quantity}`;

            return (
                <InputContainer key={index}>
                    <SelectField
                        key={packageId}
                        field={PRODUCT_FIELDS.packages}
                        label={t('package')}
                        values={values}
                        objectProperty={{
                            index,
                            property: PACKAGE_FIELDS.packageId,
                        }}
                        selectValues={packages}
                        handleChange={(e, value) => {
                            setFieldValue(packageId, value?.id ?? '');
                            setFieldTouched(packageId, true, false);
                        }}
                        autocomplete
                        errors={errors}
                        touched={touched}
                        disableClearable
                    />
                    <MarginInline left>
                        <TextField
                            key={quantity}
                            id={quantity}
                            name={quantity}
                            label={t(PACKAGE_FIELDS.quantity)}
                            value={values.packages[index].quantity ?? ''}
                            onChange={(e) => {
                                const val = e.target.value;
                                setFieldTouched(quantity, true, false);

                                if (NUMBER_REGEX.test(val) || !val) {
                                    setFieldValue(quantity, Number(val));
                                }
                            }}
                            helperText={getFieldPackagesQuantity(index)}
                            error={Boolean(getFieldPackagesQuantity(index))}
                            sx={{ minWidth: 40 }}
                        />
                    </MarginInline>
                    <StyledIconButton onClick={() => managePackages(index === 0 ? '' : index)}>
                        {index === 0 ? <AddIcon /> : <RemoveIcon />}
                    </StyledIconButton>
                </InputContainer>
            );
        });

    const isDataFetching = () => isFetching || isFetchingAdditionalData;

    return isDataFetching() ? (
        <LoadingOverlay fullScreen />
    ) : (
        <FormikProvider value={formik}>
            <FlexContainer>
                {formType === FORM_TYPES.show ? (
                    <>
                        <MarginContainer>
                            <TitleContainer>
                                <SectionTitle tableMargin title={makeTitle({ formType, category })} />
                            </TitleContainer>
                        </MarginContainer>
                        <MarginContainer>
                            <FieldContainer>
                                <Field>{t('detailedInfo')}</Field>
                            </FieldContainer>
                        </MarginContainer>
                        <TableWrapper>{generateInfoContainer()}</TableWrapper>
                        <MarginContainer>
                            <FieldContainer>
                                <Field>{t('packages')}</Field>
                            </FieldContainer>
                            <FormTableWrapper height={'100%'}>
                                <CustomTable
                                    columnDefs={generateColumnDefs()}
                                    rowData={values.packages}
                                    tooltipShowDelay={0}
                                    rowHeight={60}
                                    alignContent={'left'}
                                    overlayType={OVERLAY_TYPES.minContent}
                                />
                            </FormTableWrapper>
                            <MarginBlock>{generateButtonsContainer()}</MarginBlock>
                        </MarginContainer>
                    </>
                ) : (
                    <Form title={makeTitle({ formType, category })} onSubmit={handleSubmit}>
                        <Stack spacing={3} m={4}>
                            {generateTextFields()}
                            {generateSelectFields()}
                            {generateButtonsContainer()}
                        </Stack>
                    </Form>
                )}
            </FlexContainer>
        </FormikProvider>
    );
};

ProductForm.propTypes = {
    formType: PropTypes.oneOf([FORM_TYPES.add, FORM_TYPES.edit, FORM_TYPES.show]).isRequired,
};

export default ProductForm;
