/* eslint-disable no-return-await */
import { Stack } from '@mui/material';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { AgGridReact } from 'ag-grid-react';
import { camelCase, cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { openAlertError, openAlertSuccess, openAlertWarning } from '../../../actions/alertActions';
import useBrowserWidth from '../../../hooks/useBrowserWidth';
import {
    COLUMN_WIDTHS,
    DATA_TYPES,
    DEBOUNCE_TIME,
    DESKTOP_WIDTH,
    FORM_TYPES,
    NAV_LINKS,
    TABLE_FIELDS,
    USER_PACKAGE_STATUS,
    USER_ROLES,
} from '../../../utils/constants';
import { changeData, getOrganizationPackages, getUserPackageList } from '../../../utils/fetchData';
import { compareObjects, formatDate, isTableContentFit, makeTitle } from '../../../utils/helpers';
import AG_GRID_LOCALE from '../../../utils/tableTranslations';
import Datepicker from '../../Datepicker/Datepicker';
import { TextInfoBold } from '../../Form/Form.styles';
import FormButtons from '../../Form/FormButtons/FormButtons';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';
import SectionTitle from '../../SectionTitle/SectionTitle';
import SelectField from '../../SelectField/SelectField';
import AddButton from '../../Table/AddButton/AddButton';
import TableModal from '../../Table/TableModal/TableModal';
import WarningInfo from '../../WarningInfo/WarningInfo';
import {
    ActionFieldContainer,
    BoxContainer,
    ButtonsContainer,
    Container,
    FieldContainer,
    OptionsContainer,
    PackagesTableWrapper,
    TableWrapper,
    TextInfo,
} from './PackagesListForm.styles';

const PackagesListForm = ({ formType, category }) => {
    const user = useSelector((state) => state.userReducer);
    const [gridApi, setGridApi] = useState(null);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const onGridReady = (params) => setGridApi(params.api);
    const [selections, setSelections] = useState([]);
    const [packages, setPackages] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [modal, setModal] = useState({ open: false, data: null });
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isFormValid, setIsFormValid] = useState(null);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const currentBrowserWidth = useBrowserWidth();
    const dispatch = useDispatch();
    const { id: organizationId, packageId } = useParams();

    const USER_PACKAGE_FIELDS = {
        userPackageId: 'user_package_id',
        expiresAt: 'expires_at',
        createdAt: 'created_at',
        userId: 'user_id',
        packageId: 'package_id',
        status: 'status',
    };

    useEffect(() => {
        fetchData();
    }, []);

    const findUserPackage = (data) => {
        if (!data) return null;
        return data.user_packages.find((pack) => pack?.package_id === selectedPackage?.id);
    };

    const isUrlValid = (packages) => {
        if (!packages.length || packageId === 'packages') return;
        const isValidUrl = packages.find((p) => p.id === Number(packageId));
        if (!isValidUrl) navigate(NAV_LINKS.notFound);
    };

    const fetchData = async () => {
        setIsFetchingData(true);
        setGridApi(null);
        return await Promise.all([
            getOrganizationPackages(organizationId),
            getUserPackageList(organizationId),
        ])
            .then(([packagesRes, participantsRes]) => {
                if (packagesRes.error || participantsRes.error) {
                    navigate(NAV_LINKS.notFound);
                } else {
                    const packages = packagesRes.data.map((el) => ({
                        ...el.package,
                        quantity: el.quantity,
                        organizationId: el.organization_id,
                    }));
                    isUrlValid(packages);
                    setPackages(packages);
                    setParticipants(participantsRes.data);
                }
            })
            .catch(() => {
                dispatch(openAlertError());
                navigate(NAV_LINKS.notFound);
            })
            .finally(() => setIsFetchingData(false));
    };

    useEffect(() => {
        if (gridApi) {
            if (currentBrowserWidth >= DESKTOP_WIDTH) {
                gridApi.sizeColumnsToFit();
            } else {
                setTimeout(() => {
                    isTableContentFit() && gridApi.sizeColumnsToFit();
                }, 100);
            }
        }
    }, [gridApi, currentBrowserWidth]);

    useEffect(() => {
        setIsFormValid(null);
    }, [selectedPackage]);

    const prepareData = (data) =>
        data
            .map((person) => {
                const pack = findUserPackage(person);
                if (!pack) return null;
                const data = {
                    [USER_PACKAGE_FIELDS.userId]: person.id,
                    [USER_PACKAGE_FIELDS.expiresAt]: pack.expires_at,
                };
                if (pack.id) {
                    data[USER_PACKAGE_FIELDS.userPackageId] = pack.id;
                }
                return data;
            })
            .filter((el) => el)
            .flat();

    const isPayloadEmpty = () => {
        try {
            const data = payloadData();
            return !data.create.length && !data.update.length && !data.delete.length;
        } catch (e) {
            return true;
        }
    };

    const isValidSelectedUsers = () => {
        if (!gridApi || !selectedPackage || isPayloadEmpty()) return false;
        const validity = [];

        gridApi.forEachNode((node) => {
            if (!node.selected) return validity.push(true);
            const pack = findUserPackage(node.data);
            if (!pack) {
                return validity.push(false);
            }

            if (pack.expires_at === 'Invalid Date') {
                return validity.push(false);
            }

            if (
                Date.parse(getMinMaxDate(node).min) > Date.parse(pack.expires_at) ||
                Date.parse(getMinMaxDate(node).max) < Date.parse(pack.expires_at)
            ) {
                return validity.push(false);
            }

            if (!pack.expires_at) {
                return validity.push(false);
            }
            return validity.push(true);
        });
        return validity.every((el) => el);
    };

    const payloadData = () => {
        const updatedParticipants = [];
        const initialParticipants = [];

        gridApi.forEachNode((node) => {
            if (node.selected) {
                updatedParticipants.push(node.data);
            }
            initialParticipants.push(node.initialData);
        });

        const initialValues = prepareData(initialParticipants);
        const updatedValues = prepareData(updatedParticipants);

        return {
            organization_id: selectedPackage.organizationId,
            package_id: selectedPackage.id,
            delete: [],
            create: [],
            update: [],
            ...compareObjects(initialValues, updatedValues, USER_PACKAGE_FIELDS.userPackageId),
        };
    };

    const onSubmit = () => {
        setIsLoading(true);
        changeData(DATA_TYPES.userPackages, payloadData())
            .then((res) => {
                if (res?.success) {
                    dispatch(openAlertSuccess(res.success));
                    fetchData();
                    handleClose();
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch((err) => {
                console.error(err);
                dispatch(openAlertError(err.message));
            })
            .finally(() => setIsLoading(false));
    };

    const onRowSelected = () => setIsFormValid(gridApi.getSelectedRows().length);

    const getHeaderName = (field) =>
        field === TABLE_FIELDS.id ? TABLE_FIELDS.id.toUpperCase() : t(camelCase(field));

    const setCenterCell = () => ({
        display: 'flex',
        justifyContent: 'center',
    });

    const handleAssignmentDate = (params) => {
        const pack = findUserPackage(params.data);
        return formatDate(pack?.created_at ?? new Date().toISOString());
    };

    const getValue = (params, field) => {
        switch (field) {
            case TABLE_FIELDS.groups:
                return params.data[field].map((group) => group.name).join(', ');
            case TABLE_FIELDS.expirationDate:
                return formatDate(
                    params.data?.user_packages.find((pack) => pack.package_id === +packageId)?.expires_at
                );
            case TABLE_FIELDS.assignmentDate:
                return params.node.selected ? handleAssignmentDate(params) : '';
            default:
                return params.data[field];
        }
    };

    const getTooltipValue = (params, field) => {
        switch (field) {
            case TABLE_FIELDS.groups:
                return params.data[field].map((group) => group.name).join(', ');
            case TABLE_FIELDS.assignmentDate:
                return params.node.selected ? handleAssignmentDate(params) : '';
            default:
                return params.data[field];
        }
    };

    const dateComparator = (firstDate, secondDate) => {
        const firstDateNumber = firstDate && new Date(firstDate).getTime();
        const secondDateNumber = secondDate && new Date(secondDate).getTime();

        if (!firstDateNumber && !secondDateNumber) {
            return 0;
        }

        if (!firstDateNumber) {
            return -1;
        }

        if (!secondDateNumber) {
            return 1;
        }

        return firstDateNumber - secondDateNumber;
    };

    const generateColumnDefs = () => {
        const colDefs = [
            TABLE_FIELDS.id,
            TABLE_FIELDS.lastName,
            TABLE_FIELDS.firstName,
            TABLE_FIELDS.nickname,
            TABLE_FIELDS.groups,
            TABLE_FIELDS.assignmentDate,
            TABLE_FIELDS.expirationDate,
        ].map((field) => ({
            field,
            headerName: getHeaderName(field),
            headerTooltip: getHeaderName(field),
            tooltipValueGetter: (params) => getTooltipValue(params, field),
            minWidth: getTableColumnWidth(field),
            filter: field !== TABLE_FIELDS.assignmentDate && field !== TABLE_FIELDS.expirationDate,
            ...(field === TABLE_FIELDS.expirationDate && {
                comparator: (valueA, valueB) => dateComparator(valueA, valueB),
            }),
            valueGetter: (params) => getValue(params, field),
            cellStyle: setCenterCell,
            cellRenderer: (params) => {
                if (field === TABLE_FIELDS.expirationDate) {
                    return handleDatetimePickerRender(params);
                }
                return params.value;
            },
        }));

        colDefs.unshift({
            field: TABLE_FIELDS.selection,
            headerName: '',
            maxWidth: COLUMN_WIDTHS.selection,
            minWidth: COLUMN_WIDTHS.selection,
            sortable: false,
            filter: false,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            headerClass: !selectedPackage ? 'disabled-header-checkbox' : '',
            cellStyle: setCenterCell,
        });

        colDefs.unshift({
            field: TABLE_FIELDS.ordinaryNumber,
            headerName: t(camelCase(TABLE_FIELDS.ordinaryNumber)),
            valueGetter: (params) => params.node.rowIndex + 1,
            maxWidth: COLUMN_WIDTHS.ordinaryNumber.small,
            minWidth: COLUMN_WIDTHS.ordinaryNumber.small,
            pinned: 'left',
            sortable: false,
            filter: false,
            cellStyle: setCenterCell,
        });

        return colDefs;
    };

    useEffect(() => {
        if (gridApi) {
            if (isFetchingData) {
                gridApi.showLoadingOverlay();
            } else if (!participants.length) {
                gridApi.showNoRowsOverlay();
            } else {
                gridApi.hideOverlay();
            }
        }
    }, [gridApi, participants]);

    useEffect(() => {
        setIsFormValid(isValidSelectedUsers());
    });

    const getTableColumnWidth = (field) => {
        switch (field) {
            case TABLE_FIELDS.id:
                return COLUMN_WIDTHS.id;
            case TABLE_FIELDS.expirationDate:
                return COLUMN_WIDTHS.expirationDate;
            default:
                return 150;
        }
    };

    const isDisabled = (params, userRole) => {
        if (userRole === USER_ROLES.superAdmin) {
            return false;
        }

        const pack = findUserPackage(params.data);

        if (!pack) return false;
        if (pack.status !== USER_PACKAGE_STATUS.assigned) return true;

        const packCreatedAt = Date.parse(pack.created_at);
        const monthInMs = 30 * 24 * 60 * 60 * 1000;
        const dateNow = Date.now();

        const isExpired = dateNow - packCreatedAt >= monthInMs;

        if (isExpired) return true;
        return false;
    };

    const getMinMaxDate = (params) => {
        const pack = findUserPackage(params.data);
        const yearInMs = 365 * 24 * 60 * 60 * 1000;
        return {
            max: formatDate(new Date((!pack ? Date.now() : Date.parse(pack.created_at)) + yearInMs)),
            min: formatDate(!pack ? new Date() : pack.created_at),
        };
    };

    const handleDatetimePickerRender = (params) => {
        if (!selectedPackage) return null;
        if (!params.node.selected) return null;

        const pack = findUserPackage(params.data);

        const setSelectedDate = (date) => {
            if (!pack) {
                createUserPackage(params.data, date.format('YYYY-MM-DD'));
            } else {
                pack.expires_at = !date ? null : date.format('YYYY-MM-DD');
            }
            setIsFormValid(isValidSelectedUsers());
            params.api.refreshCells({ force: true });
        };

        const getSelectedDate = () => {
            const pack = params.data.user_packages.find((pack) => pack.package_id === selectedPackage.id);

            if (!pack) return null;
            return params.value ?? pack.expires_at;
        };

        return (
            <Datepicker
                simple
                minDate={getMinMaxDate(params).min}
                maxDate={getMinMaxDate(params).max}
                disabled={isDisabled(params, user?.role)}
                selectedDate={getSelectedDate()}
                setSelectedDate={setSelectedDate}
                tooltip={t('expirationDateTooltip')}
                isError={formatDate(new Date()) > pack?.expires_at}
            />
        );
    };

    const isCheckboxSelection = (params, packageToSelect) => {
        const syncSelectedPackage = packageToSelect ?? selectedPackage;
        const isSelected = params?.node ? params.node.selected : params.selected;
        const disableUnselected = isSelected ? true : getBalance() > 0;

        return !!syncSelectedPackage && disableUnselected && !isDisabled(params);
    };

    const getUnselectedExistingSelections = () => {
        const unselectedExistingSelections = [];

        gridApi.forEachNode((node) => {
            const person = node.data;
            if (node.selected) return;

            const pack = findUserPackage(person);
            if (pack?.id) {
                unselectedExistingSelections.push(person);
            }
        });
        return unselectedExistingSelections;
    };

    const getBalance = () => {
        if (!gridApi || !selectedPackage) return 0;
        const quantity = selectedPackage?.quantity;
        const createdSelections =
            gridApi.getSelectedRows()?.filter((person) => {
                const pack = findUserPackage(person);
                return !pack?.id;
            }) ?? [];

        const result = quantity - createdSelections.length + getUnselectedExistingSelections().length;
        return result < 0 ? 0 : result;
    };

    const handlePackageSelection = (value) => {
        if (!gridApi) return;
        navigate(`${NAV_LINKS.organizations}/${organizationId}/packages/${value.id}`, {
            replace: true,
        });

        gridApi.forEachNode((node) => {
            node.setRowSelectable(true);
            node.setSelected(false);

            const clonedData = cloneDeep(node.initialData);
            node.data = clonedData;

            const isPackageInUserPackages = !!clonedData.user_packages.find(
                (userPackage) => userPackage.package_id === value.id
            );

            if (isPackageInUserPackages) {
                node.setSelected(true);
            } else {
                node.setSelected(false);
            }
        });
    };

    const onSelectionChanged = (params) => {
        if (!selectedPackage) return;
        const selectedRows = params.api.getSelectedRows();
        setSelections(selectedRows.map((row) => row.id));

        const existingSelections = params.api.getSelectedRows().filter((person) => {
            const pack = findUserPackage(person);
            return !!pack?.id;
        });

        const isMoreThanPossibleQuantity =
            params.api.getSelectedRows().length >
            selectedPackage.quantity + existingSelections.length + getUnselectedExistingSelections().length;

        if (isMoreThanPossibleQuantity) {
            params.api.forEachNode((rowNode) => {
                rowNode.setSelected(false);
                if (!getBalance()) return;
                if (selections.includes(rowNode.data.id)) {
                    rowNode.setSelected(true);
                }
            });
            params.api.forEachNode((rowNode) => {
                if (!getBalance()) return;
                rowNode.setSelected(true);
            });
            dispatch(openAlertWarning('maxQuantity'));
        }

        params.api.forEachNode((rowNode) => {
            rowNode.setRowSelectable(isCheckboxSelection(rowNode));
            if (isDisabled(rowNode)) {
                const pack = findUserPackage(rowNode.data);
                if (pack?.id) {
                    rowNode.setRowSelectable(true);
                    rowNode.setSelected(true);
                    rowNode.setRowSelectable(false);
                }
            }
        });
    };

    const handleConfirmForAll = () => {
        if (!gridApi) return;
        gridApi.getRenderedNodes().forEach((node) => {
            if (!node.selected || !node.selectable || !selectedPackage) return;

            if (selectedDate && 'isValid' in selectedDate) {
                if (!selectedDate.isValid()) return;
            }

            const expirationDate = formatDate(selectedDate);
            const pack = findUserPackage(node.data);
            if (!pack) {
                createUserPackage(node.data, expirationDate);
            } else {
                pack.expires_at = expirationDate;
            }
            gridApi.refreshCells({ force: true });
        });
        setIsFormValid(isValidSelectedUsers());
    };

    const createUserPackage = (userReference, expirationDate) => {
        if (!expirationDate) {
            dispatch(openAlertError('expirationDateMustBeDefined'));
            return;
        }
        const userPackage = {
            [USER_PACKAGE_FIELDS.createdAt]: new Date().toISOString(),
            [USER_PACKAGE_FIELDS.expiresAt]: expirationDate,
            id: null,
            [USER_PACKAGE_FIELDS.packageId]: selectedPackage.id,
            [USER_PACKAGE_FIELDS.status]: USER_PACKAGE_STATUS.assigned,
            [USER_PACKAGE_FIELDS.userId]: userReference.id,
        };
        userReference.user_packages.push(userPackage);
    };

    const tooltipValueGetter = (params) => {
        if (params.node.selectable || !selectedPackage || !params.node.selected) return null;
        return t('userPackageDisabled');
    };

    const handleNextStep = () => {
        const participantsData = [];
        gridApi.forEachNode((node) => {
            const clonedData = cloneDeep(node.data);
            const pack = findUserPackage(clonedData);

            delete clonedData.organizations;
            delete clonedData.user_packages;

            participantsData.push({
                ...clonedData,
                user_packages: pack,
            });
        });

        setModal({
            open: true,
            data: {
                payload: payloadData(),
                participants: participantsData,
            },
        });
    };

    const handleClose = () => {
        setModal({ open: false, data: null });
    };

    const onFirstDataRendered = (params) => {
        params.api.forEachNode((node) => {
            node.initialData = cloneDeep(node.data);
        });
        if (packageId && packageId !== 'packages') {
            const packageData = packages.find((p) => p.id === Number(packageId));
            handlePackageSelection(packageData);
            setSelectedPackage(packageData);
            return;
        }
        selectedPackage && handlePackageSelection(selectedPackage);
    };

    return isFetchingData ? (
        <LoadingOverlay fullScreen />
    ) : (
        <Container>
            <TableModal
                dataType={DATA_TYPES.userPackages}
                modalData={modal.data}
                open={modal.open}
                handleClose={handleClose}
                handleAction={onSubmit}
                isLoading={isLoading}
            />
            <Stack spacing={3} mb={4}>
                <SectionTitle tableMargin title={makeTitle({ formType, category })} />
                <ActionFieldContainer>
                    <OptionsContainer>
                        <FieldContainer>
                            <SelectField
                                loading={isFetchingData}
                                autocomplete
                                key={'packages'}
                                field={'packages'}
                                label={t('package')}
                                selectValues={packages}
                                values={{ packages: selectedPackage?.id }}
                                disableClearable
                                handleChange={(e, value) => {
                                    handlePackageSelection(value);
                                    setSelectedPackage(value ?? '');
                                }}
                            />
                        </FieldContainer>
                        <BoxContainer>
                            <TextInfo>
                                <TextInfoBold>{`${t('balance')}: `}</TextInfoBold>
                                {getBalance()}
                            </TextInfo>
                        </BoxContainer>
                    </OptionsContainer>
                    <OptionsContainer justifyContent={'end'}>
                        <Datepicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                        <AddButton
                            title={t('setForSelected')}
                            icon={'line-md:confirm-circle'}
                            size={'medium'}
                            onClick={handleConfirmForAll}
                        />
                    </OptionsContainer>
                </ActionFieldContainer>
            </Stack>
            <TableWrapper>
                <PackagesTableWrapper className="ag-theme-material">
                    {isFetchingData ? (
                        <LoadingOverlay fullScreen />
                    ) : (
                        <AgGridReact
                            defaultColDef={{
                                sortable: true,
                                resizable: true,
                                autoHeight: true,
                                wrapText: true,
                                filter: true,
                                suppressMovable: true,
                                tooltipValueGetter,
                                tooltipShowDelay: 0,
                                filterParams: {
                                    suppressAndOrCondition: true,
                                    filterOptions: ['contains'],
                                    buttons: ['reset'],
                                    debounceMs: DEBOUNCE_TIME,
                                },
                            }}
                            rowData={participants}
                            columnDefs={generateColumnDefs()}
                            onGridReady={onGridReady}
                            onRowSelected={onRowSelected}
                            tooltipShowDelay={0}
                            localeText={{
                                ...AG_GRID_LOCALE[i18n.language],
                                noRowsToShow: t('noParticipantsToShow'),
                            }}
                            isRowSelectable={isCheckboxSelection}
                            onSelectionChanged={onSelectionChanged}
                            suppressRowClickSelection
                            suppressDragLeaveHidesColumns
                            rowSelection={'multiple'}
                            loadingOverlayComponent={LoadingOverlay}
                            onFirstDataRendered={onFirstDataRendered}
                        />
                    )}
                </PackagesTableWrapper>
            </TableWrapper>
            <WarningInfo
                warningText={'fillDatesWarning'}
                displayWarning={isFormValid === false && isPayloadEmpty() === false}
                fullWidth
            />
            <ButtonsContainer style={{ marginTop: '1rem' }} width={'100%'}>
                <FormButtons
                    formType={FORM_TYPES.nextStep}
                    isFormValid={!!isFormValid}
                    onSubmit={handleNextStep}
                />
            </ButtonsContainer>
        </Container>
    );
};

export default PackagesListForm;
