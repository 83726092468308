import PropTypes from 'prop-types';
import { SectionParagraph, SectionTitleWrapper } from './SectionTitle.styles';

const SectionTitle = ({ tableMargin, withMargin, title }) => (
    <SectionTitleWrapper tableMargin={tableMargin} withMargin={withMargin}>
        <SectionParagraph>{title}</SectionParagraph>
    </SectionTitleWrapper>
);

SectionTitle.defaultProps = {
    tableMargin: false,
    withMargin: false,
};

SectionTitle.propTypes = {
    tableMargin: PropTypes.bool,
    withMargin: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

export default SectionTitle;
