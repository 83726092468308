import { Box, IconButton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import AccountPopover from '../AccountPopover/AccountPopover';
import Iconify from '../Iconify/Iconify';
import LanguagePopover from '../LanguagePopover/LanguagePopover';
import Searchbar from '../Searchbar/Searchbar';
import { RootStyle, ToolbarStyle } from './DashboardNavbar.styles';

const DashboardNavbar = ({ onOpenSidebar }) => (
    <RootStyle>
        <ToolbarStyle>
            <IconButton
                onClick={onOpenSidebar}
                sx={{
                    ml: -1,
                    mr: 1,
                    color: 'text.primary',
                    display: { lg: 'none' },
                }}
            >
                <Iconify icon="eva:menu-2-fill" />
            </IconButton>

            <Searchbar />
            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1 }}>
                <LanguagePopover />
                <AccountPopover />
            </Stack>
        </ToolbarStyle>
    </RootStyle>
);

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
};

export default DashboardNavbar;
