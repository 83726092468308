export const SAVE_USER = 'SAVE_USER';

const initialState = {
    email: '',
    first_name: '',
    last_name: '',
    role: '',
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER:
            return {
                ...action.payload,
            };

        default:
            return state;
    }
};
