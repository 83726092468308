import styled from 'styled-components';

export const StyledFooter = styled.footer`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    background: ${({ theme }) => theme.palette.background.footer};
    border-top: 1px solid ${({ theme }) => theme.palette.border};
    border-top-style: dashed;
    position: absolute;
`;

export const FooterText = styled.p`
    color: ${({ theme }) => theme.palette.text.dark};
    font-size: 0.625rem;
    text-align: center;
`;
