import styled from 'styled-components';
import DefaultOverlay from './DefaultOverlay';

const BG_TYPE = 'hoverHeadCell';

const ModalOverlay = styled(DefaultOverlay)`
    border: 1px solid ${({ theme }) => theme.palette.tableBorder};
    border-radius: 8px;
    position: relative;

    .groupNameRow {
        td {
            display: flex;
            justify-content: left;
            align-items: center;
            border: none;
            gap: 1rem;
            max-width: 400px;
            position: relative;
            word-break: break-word;
        }
        &:hover {
            background-color: inherit;
        }
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.palette.tableBorder};
        background-color: ${({ theme }) => theme.palette.table[BG_TYPE]};
    }
    .deleteButton {
        padding: 0;
        button {
            border-radius: 0;
            width: 100%;
            height: 48px;
        }
    }

    table {
        background-color: ${({ theme }) => theme.palette.table[BG_TYPE]};
        border-radius: 0;
        width: 100%;
        tbody {
            tr {
                background: white;
                td {
                    height: auto;
                }
            }
        }
    }
`;

export default ModalOverlay;
