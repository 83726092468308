import { Stack, TextField } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { camelCase, isEqual, mapValues } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import { ALL_DATA, DATA_TYPES, FORM_TYPES, NAV_LINKS, TABLE_SETTINGS } from '../../../utils/constants';
import { createData, getData, getDataById, updateData } from '../../../utils/fetchData';
import { getErrorLink, getTableFieldsObject, makeTitle } from '../../../utils/helpers';
import Form from '../../Form/Form';
import FormButtons from '../../Form/FormButtons/FormButtons';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';
import SelectField from '../../SelectField/SelectField';

const ParticipantGroupForm = ({ formType, category }) => {
    const PARTICIPANT_GROUP_SCHEMA = {
        name: '',
        description: '',
        organization_id: '',
    };
    const PARTICIPANT_GROUP_FIELDS = {
        organization: 'organization',
        organizationId: 'organization_id',
    };
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isFetching, setIsFetching] = useState(formType === FORM_TYPES.edit);
    const [isFetchingAdditionalData, setIsFetchingAdditionalData] = useState(true);
    const [data, setData] = useState(PARTICIPANT_GROUP_SCHEMA);
    const [organizations, setOrganizations] = useState([]);
    const { id: participantGroupId } = useParams();

    useEffect(() => {
        if (formType === FORM_TYPES.edit) {
            getDataById(DATA_TYPES.participantGroup, participantGroupId)
                .then((res) => {
                    if (res.error) {
                        navigate(getErrorLink(camelCase(res.error)));
                    } else {
                        res = mapValues(res, (v) => (v === null ? '' : v));
                        setValues(res);
                        setData(res);
                    }
                })
                .catch(() => navigate(NAV_LINKS.notFound))
                .finally(() => setIsFetching(false));
        }

        getData(DATA_TYPES.organization, ALL_DATA)
            .then((res) => {
                if (res.error) {
                    navigate(NAV_LINKS.participantGroups);
                    dispatch(openAlertError(res.error));
                } else {
                    setOrganizations(res.data);
                    if (res.data.length === 1) {
                        setFieldValue(PARTICIPANT_GROUP_FIELDS.organizationId, res.data[0]?.id);
                    }
                }
            })
            .catch(() => {
                navigate(NAV_LINKS.notFound);
                dispatch(openAlertError());
            })
            .finally(() => setIsFetchingAdditionalData(false));
    }, []);

    const DataSchema = Yup.object().shape({
        name: Yup.string()
            .min(1, t('groupNameMinCharacters'))
            .max(40, t('groupNameMaxCharacters'))
            .required(t('groupNameRequired')),
        description: Yup.string().max(255, t('groupDescriptionMaxCharacters')),
        organization_id: Yup.number().required(t('organizationIdRequired')),
    });

    const formik = useFormik({
        initialValues: PARTICIPANT_GROUP_SCHEMA,
        validationSchema: DataSchema,
        onSubmit: (values) =>
            formType === FORM_TYPES.add
                ? addParticipantGroup(values)
                : updateParticipantGroup(participantGroupId, values),
    });

    const {
        errors,
        touched,
        isValid,
        values,
        setValues,
        setFieldTouched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
    } = formik;

    const addParticipantGroup = (payload) => {
        createData(DATA_TYPES.participantGroup, payload)
            .then((res) => {
                if (res.success) {
                    navigate(NAV_LINKS.participantGroups);
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                setSubmitting(false);
                dispatch(openAlertError());
            });
    };

    const updateParticipantGroup = (id, payload) => {
        updateData(DATA_TYPES.participantGroup, id, payload)
            .then((res) => {
                if (res.success) {
                    navigate(NAV_LINKS.participantGroups);
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                dispatch(openAlertError());
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const isFormValid = () => isValid && Object.keys(touched).length && !isEqual(data, values);

    const createTextField = (field) => (
        <TextField
            key={field}
            id={field}
            name={field}
            label={t(getTableFieldsObject(TABLE_SETTINGS.participantGroup.tableHead)[field])}
            value={values[field] ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched[field] && errors[field]}
            error={Boolean(touched[field] && errors[field])}
            onInput={() => setFieldTouched(field, true, true)}
        />
    );

    const generateTextFields = () =>
        Object.keys(PARTICIPANT_GROUP_SCHEMA)
            .filter((item) => item !== PARTICIPANT_GROUP_FIELDS.organizationId)
            .map((field) => createTextField(field));

    const generateSelectField = () =>
        organizations.length === 1 ? null : (
            <SelectField
                field={PARTICIPANT_GROUP_FIELDS.organizationId}
                label={PARTICIPANT_GROUP_FIELDS.organization}
                touched={touched}
                errors={errors}
                values={values}
                selectValues={organizations}
                handleChange={(e, value) => setFieldValue(PARTICIPANT_GROUP_FIELDS.organizationId, value.id)}
                handleBlur={handleBlur}
                autocomplete
            />
        );

    const isDataFetching = () => isFetching || isFetchingAdditionalData;

    return isDataFetching() ? (
        <LoadingOverlay fullScreen />
    ) : (
        <FormikProvider value={formik}>
            <Form title={makeTitle({ formType, category })} onSubmit={handleSubmit}>
                <Stack spacing={3} m={4}>
                    {generateTextFields()}
                    {generateSelectField()}
                    <FormButtons
                        navLink={NAV_LINKS.participantGroups}
                        formType={formType}
                        isFormValid={!!isFormValid()}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                    />
                </Stack>
            </Form>
        </FormikProvider>
    );
};

ParticipantGroupForm.propTypes = {
    formType: PropTypes.oneOf([FORM_TYPES.add, FORM_TYPES.edit]).isRequired,
};

export default ParticipantGroupForm;
