import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { FORM_TYPES } from '../../../utils/constants';
import { ButtonWrapper } from '../Form.styles';
import { BUTTONS_DEFAULT_STYLES, CenterSpinner, StyledBox, getFormButtonsSx } from './FormButtons.styles';

const FormButtons = ({
    formType,
    navLink,
    isFormValid,
    onSubmit,
    onBack,
    isLoading,
    onSubmitText,
    onSubmitIcon,
    disabled,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const submitButtonRef = useRef(null);

    const getIcon = (icon) => {
        if (icon) return icon;
        if (formType === FORM_TYPES.confirm) return '';
        if (formType === FORM_TYPES.delete) return <DeleteIcon />;
        return <EditIcon />;
    };

    return (
        <StyledBox>
            <Button
                variant="outlined"
                sx={getFormButtonsSx(theme)}
                startIcon={<BackIcon />}
                onClick={onBack || (() => navigate(navLink ?? -1))}
            >
                {t(formType === FORM_TYPES.confirm ? 'cancel' : 'back')}
            </Button>

            {formType !== FORM_TYPES.show && (
                <ButtonWrapper>
                    {formType !== FORM_TYPES.nextStep ? (
                        <Button
                            variant="contained"
                            {...(formType === FORM_TYPES.delete && {
                                color: 'error',
                            })}
                            sx={{
                                ...BUTTONS_DEFAULT_STYLES,
                                ...{
                                    borderColor: (theme) =>
                                        formType === FORM_TYPES.delete
                                            ? theme.palette.error.main
                                            : theme.palette.primary.main,
                                    borderWidth: 2,
                                    borderStyle: 'solid',
                                },
                                ...{
                                    width: isLoading
                                        ? submitButtonRef?.current?.getBoundingClientRect()?.width ?? 'auto'
                                        : 'auto',
                                },
                            }}
                            startIcon={!isLoading && getIcon(onSubmitIcon)}
                            disabled={disabled || !isFormValid}
                            type={'submit'}
                            onClick={onSubmit}
                            ref={submitButtonRef}
                        >
                            {!isLoading ? (
                                onSubmitText ?? t(formType === FORM_TYPES.edit ? t('save') : formType)
                            ) : (
                                <CenterSpinner>
                                    <ClipLoader size={15} color={theme.palette.common.white} />
                                </CenterSpinner>
                            )}
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            sx={BUTTONS_DEFAULT_STYLES}
                            endIcon={<ForwardIcon />}
                            disabled={disabled || !isFormValid}
                            type={'button'}
                            onClick={onSubmit}
                        >
                            {t('nextStep')}
                        </Button>
                    )}
                </ButtonWrapper>
            )}
        </StyledBox>
    );
};

FormButtons.propTypes = {
    formType: PropTypes.string.isRequired,
    navLink: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isFormValid: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    onSubmit: PropTypes.func,
    onBack: PropTypes.func,
};

FormButtons.defaultProps = {
    isLoading: false,
};

export default FormButtons;
