import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import { getYoutubeVideoId } from '../../../utils/helpers';
import { PlayerWrapper } from './YouTubePlayer.styles';

const YouTubePlayer = ({ videoId }) => {
    const { i18n } = useTranslation();

    return (
        <PlayerWrapper>
            <YouTube
                opts={{
                    playerVars: {
                        hl: i18n.language,
                        modestbranding: 1,
                        rel: 0,
                    },
                }}
                videoId={getYoutubeVideoId(videoId)}
            />
        </PlayerWrapper>
    );
};

YouTubePlayer.propTypes = {
    videoId: PropTypes.string.isRequired,
};

export default YouTubePlayer;
