import { Button } from '@mui/material';
import styled from 'styled-components';

export const PREVIEW_SIZE = 135;

export const MediaInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: ${({ margin }) => margin};
`;

export const MediaButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
`;

export const MediaButton = styled(Button)`
    width: fit-content;
`;

export const MediaPreviewContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: ${({ isPreview }) => (isPreview ? 0 : '2rem')};
    flex: 2;
`;

export const MediaInfoText = styled.p`
    font-style: italic;
`;
