import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const Iconify = ({ icon, sx, ...other }) => <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;

Iconify.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    sx: PropTypes.object,
};

export default Iconify;
