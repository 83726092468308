import { IconButton, InputAdornment, TextField } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import { FORM_TYPES, NAV_LINKS, USER_SEX } from '../../../utils/constants';
import { setProfile } from '../../../utils/fetchData';
import Form from '../../Form/Form';
import { StyledStack } from '../../Form/Form.styles';
import FormButtons from '../../Form/FormButtons/FormButtons';
import Iconify from '../../Iconify/Iconify';
import SelectField from '../../SelectField/SelectField';

const UserProfileForm = ({ token }) => {
    const USER_PROFILE_FORM_FIELDS = {
        firstName: 'first_name',
        lastName: 'last_name',
        sex: 'sex',
        password: 'password',
        password_confirmation: 'password_confirmation',
        token: 'token',
    };

    const USER_SCHEMA = {
        first_name: '',
        last_name: '',
        sex: '',
        password: '',
        password_confirmation: '',
        token,
    };

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();

    const USER_PROFILE_SCHEMA = Yup.object().shape({
        first_name: Yup.string().min(2, t('firstNameMinCharacters')).required(t('firstNameRequired')),
        last_name: Yup.string().min(2, t('lastNameMinCharacters')).required(t('lastNameRequired')),
        sex: Yup.string().required(t('genderRequired')),
        password: Yup.string().min(4, t('passwordValidationLength')).required(t('passwordRequired')),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref(USER_PROFILE_FORM_FIELDS.password)], t('passwordsMatch'))
            .required(t('passwordConfirmationRequired')),
        token: Yup.string().required(),
    });

    const formik = useFormik({
        initialValues: USER_SCHEMA,
        validationSchema: USER_PROFILE_SCHEMA,
        onSubmit: (values) => setUserProfile(values),
    });

    const setUserProfile = (values) => {
        setProfile(values)
            .then((res) => {
                if (res.success) {
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError());
                }
            })
            .catch(() => {
                setSubmitting(false);
                dispatch(openAlertError());
            })
            .finally(() => navigate(NAV_LINKS.login, { replace: true }));
    };

    const {
        errors,
        touched,
        isValid,
        values,
        getFieldProps,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
    } = formik;

    const isFormValid = () => isValid && Object.keys(touched).length;

    const createTextField = (field) => (
        <TextField
            key={field}
            id={field}
            name={field}
            label={t(camelCase(field))}
            value={values[field] ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched[field] && errors[field]}
            error={Boolean(touched[field] && errors[field])}
            onInput={() => setFieldTouched(field, true, true)}
        />
    );

    const getFieldType = () => {
        const { sex, role, password, password_confirmation, token, ...textFields } = USER_PROFILE_FORM_FIELDS;

        return {
            text: Object.values(textFields),
            select: Object.values({ sex }),
        };
    };

    const createSelectField = (field, selectValues) => (
        <SelectField
            key={field}
            field={field}
            label={USER_PROFILE_FORM_FIELDS[field]}
            touched={touched}
            errors={errors}
            values={values}
            selectValues={selectValues}
            handleChange={handleChange}
            handleBlur={handleBlur}
        />
    );

    const generateTextFields = () => getFieldType().text.map((field) => createTextField(field));

    const generateSelectFields = () =>
        getFieldType().select.map((field) => createSelectField(field, USER_SEX));

    const handleShowPassword = () => setShowPassword((show) => !show);

    const renderPasswordFields = () =>
        [USER_PROFILE_FORM_FIELDS.password, USER_PROFILE_FORM_FIELDS.password_confirmation].map(
            (passwordField) => (
                <TextField
                    key={passwordField}
                    id={passwordField}
                    name={passwordField}
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? 'text' : 'password'}
                    label={t(camelCase(passwordField))}
                    onBlur={handleBlur}
                    {...getFieldProps(passwordField)}
                    onInput={() => setFieldTouched(passwordField, true, true)}
                    InputProps={
                        passwordField === USER_PROFILE_FORM_FIELDS.password
                            ? {
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <IconButton onClick={handleShowPassword} edge="end">
                                              <Iconify
                                                  icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                                              />
                                          </IconButton>
                                      </InputAdornment>
                                  ),
                              }
                            : {}
                    }
                    error={Boolean(touched[passwordField] && errors[passwordField])}
                    helperText={touched[passwordField] && errors[passwordField]}
                />
            )
        );

    return (
        <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
                <StyledStack spacing={3} m={4}>
                    {generateTextFields()}
                    {generateSelectFields()}
                    {renderPasswordFields()}
                    <FormButtons
                        navLink={NAV_LINKS.login}
                        formType={FORM_TYPES.save}
                        isFormValid={!!isFormValid()}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                    />
                </StyledStack>
            </Form>
        </FormikProvider>
    );
};

UserProfileForm.formTypes = {
    token: PropTypes.string.isRequired,
};

export default UserProfileForm;
