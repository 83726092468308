import { EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import editorStateToHtml from '../../utils/draft-js/editorStateToHtml';
import useDebounce from '../../utils/useDebounce';
import HtmlPreview from './HtmlPreview/HtmlPreview';
import { EditorWrapper } from './Wysiwyg.styles';
import en from './translations/en';
import pl from './translations/pl';

const DEFAULT_OPTIONS = ['inline', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'];
const translations = { en, pl };

const Wysiwyg = ({
    initialState,
    height,
    options = DEFAULT_OPTIONS,
    isCustomTable,
    isPreview,
    isEdit,
    rowIndex,
    onSubmit,
    setCurrentlyEditing,
    currentlyEditing,
    field,
}) => {
    const {
        i18n: { language },
    } = useTranslation();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [tempEditorState, setTempEditorState] = useState(editorState);
    const debouncedTempEditorState = useDebounce(tempEditorState, 500);

    useEffect(() => {
        if (!initialState) return;
        setEditorState(initialState);
        setTempEditorState(initialState);
    }, [initialState]);

    useEffect(() => {
        setEditorState(debouncedTempEditorState);
    }, [debouncedTempEditorState]);

    useEffect(() => {
        onSubmit(rowIndex, editorStateToHtml(editorState), field);
        if (!setCurrentlyEditing) return;
        setCurrentlyEditing(false);
    }, [editorState]);

    return isPreview || (isEdit && !initialState) ? (
        <HtmlPreview editorState={editorState} isContainer={!isCustomTable} />
    ) : (
        <EditorWrapper field={field} height={height}>
            <Editor
                onBlur={() => {
                    if (currentlyEditing) return;
                    setCurrentlyEditing(false);
                }}
                editorState={tempEditorState}
                onEditorStateChange={(currentEditorState) => {
                    setCurrentlyEditing(true);
                    setTempEditorState(currentEditorState);
                }}
                localization={{
                    locale: language,
                    translations: translations[language],
                }}
                toolbar={{
                    options,
                    inline: {
                        options: ['bold', 'superscript', 'subscript'],
                    },
                    list: {
                        options: ['unordered', 'ordered'],
                    },
                }}
            />
        </EditorWrapper>
    );
};

Wysiwyg.propTypes = {
    setCurrentlyEditing: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    initialState: PropTypes.object,
    rowIndex: PropTypes.number,
    height: PropTypes.number,
    options: PropTypes.array,
    isPreview: PropTypes.bool,
    isCustomTable: PropTypes.bool,
    isEdit: PropTypes.bool,
};
export default Wysiwyg;
