import { Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerWrapper } from './Datepicker.styles';

const Datepicker = ({
    simple,
    selectedDate,
    setSelectedDate,
    disabled,
    maxDate,
    minDate,
    tooltip,
    isError,
}) => {
    const { t, i18n } = useTranslation();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <Tooltip enterDelay={800} placement="bottom" title={tooltip ?? ''}>
                <DatePickerWrapper>
                    <DatePicker
                        label={simple ? null : t('expirationDate')}
                        value={selectedDate}
                        onChange={setSelectedDate}
                        disabled={disabled}
                        minDate={minDate}
                        maxDate={maxDate}
                        renderInput={(params) => (
                            <TextField
                                variant={simple ? 'standard' : 'outlined'}
                                sx={
                                    simple
                                        ? {
                                              marginBlock: '0.5rem',
                                              '& .MuiInputBase-root': {
                                                  background: 'transparent',
                                                  marginRight: '1rem',
                                              },
                                          }
                                        : {}
                                }
                                {...params}
                                {...(isError && {
                                    helperText: t('packageExpired'),
                                })}
                            />
                        )}
                    />
                </DatePickerWrapper>
            </Tooltip>
        </LocalizationProvider>
    );
};

Datepicker.defaultProps = {
    simple: false,
};
Datepicker.propTypes = {
    simple: PropTypes.bool,
};

export default Datepicker;
