import { Autocomplete, Slide } from '@mui/material';
import styled from 'styled-components';

export const StyledSearchbar = styled(Autocomplete)`
    min-width: 600px;

    ${({ theme }) => theme.mediaBreakpoints.tablet} {
        min-width: 0;
        width: 95%;
        margin-right: 1rem;
    }
`;

export const StyledSlide = styled(Slide)`
    min-height: 100px;
`;
