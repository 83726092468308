import styled from 'styled-components';

const DefaultOverlay = styled.div`
    overflow-x: scroll;
    overflow-y: scroll;
    height: 100%;
    user-select: none;

    .loading-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .selected {
        background-color: ${({ theme }) => theme.palette.primary.autocomplete} !important;
    }

    table {
        background-color: ${({ theme }) => theme.palette.common.white};
        border-collapse: collapse;
        overflow: hidden;
        width: 100%;
        font-size: 13px;
        font-family: ${({ theme }) => theme.typography.fontFamily};
    }

    thead,
    tbody td:first-child {
        -webkit-font-smoothing: antialiased;
        color: rgba(0, 0, 0, 0.87);
        font-size: 13px;
        line-height: normal;
    }

    thead {
        tr {
            z-index: 5;
            height: 3rem;
            color: rgba(0, 0, 0, 0.54);
            text-align: ${({ alignContent }) => alignContent ?? 'center'};

            th {
                border: 1px solid ${({ theme }) => theme.palette.tableBorder};
                border-top: none;
                border-left: none;
                padding: 0 23px;

                &:last-child {
                    border-right: none;
                }
            }
            th:hover {
                background-color: ${({ theme }) => theme.palette.table.hoverHeadCell};
            }
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: ${({ theme }) => theme.palette.table.hoverCell};
            }
            td:first-child {
                border-left: none;
            }
            td {
                border: 1px solid ${({ theme }) => theme.palette.tableBorder};
                height: ${({ rowHeight }) => rowHeight ?? 142}px;
                text-align: ${({ alignContent }) => alignContent ?? 'center'};
                padding: 10px 23px;
                border-right: none;
                &.content-cell {
                    &:has(.rdw-editor-wrapper) {
                        padding: 0;
                    }
                    vertical-align: top;
                    text-align: left;
                    word-break: break-word;
                }
                .rdw-editor-toolbar {
                    border: none;
                    border-bottom: 1px solid #dde2eb;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                }
                .rdw-editor-main {
                    border: none;
                    border-top: 1px solid #dde2eb;
                }
                textarea {
                    resize: none;
                }
            }
            &:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }
`;

export default DefaultOverlay;
