import { Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import UserProfileForm from '../../components/Forms/UserProfileForm/UserProfileForm';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import Page from '../../components/Page/Page';
import { NAV_LINKS } from '../../utils/constants';
import { checkPasswordResetToken } from '../../utils/fetchData';
import { ContentStyle, RootStyle } from '../Page.styles';

const SetProfilePage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const token = location.search.split('=')[1];
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        checkPasswordResetToken(token)
            .then((res) => {
                if (res?.error) {
                    navigate(NAV_LINKS.unauthorized, {
                        state: { error: res.error },
                    });
                }
            })
            .catch(() => navigate(NAV_LINKS.unauthorized))
            .finally(() => setIsFetching(false));
    }, []);

    return isFetching ? (
        <LoadingOverlay fullScreen />
    ) : (
        <Page>
            <RootStyle>
                <Container maxWidth="sm">
                    <ContentStyle>
                        <Typography variant="h4" gutterBottom>
                            {t('setProfilePageTitle')}
                        </Typography>

                        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                            {t('setProfilePageText')}
                        </Typography>

                        <UserProfileForm token={token} />
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
};

export default SetProfilePage;
