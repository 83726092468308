import { ALERT_TYPES } from '../utils/constants';

export const OPEN_ALERT = 'OPEN_ALERT';
export const CLOSE_ALERT = 'CLOSE_ALERT';

const initialState = {
    alertIsOpen: false,
    alertType: ALERT_TYPES.success,
    alertMessage: '',
};

export const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_ALERT:
            return {
                alertIsOpen: true,
                alertType: action.payload.alertType,
                alertMessage: action.payload.alertMessage,
            };

        case CLOSE_ALERT:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};
