import { Link as RouterLink } from 'react-router-dom';
import { getMainPageLink } from '../../utils/helpers';
import { StyledImg } from './Logo.styles';

const Logo = () => (
    <RouterLink style={{ display: 'inline' }} to={getMainPageLink()}>
        <StyledImg src="/static/logo.svg" />
    </RouterLink>
);

export default Logo;
