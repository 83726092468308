import { DATA_TYPES, TABLE_SETTINGS } from '../../../utils/constants';
import Table from '../../Table/Table';

const ParticipantsTable = () => (
    <Table
        dataType={DATA_TYPES.participant}
        tableName={TABLE_SETTINGS.participant.name}
        tableHead={TABLE_SETTINGS.participant.tableHead}
    />
);

export default ParticipantsTable;
