import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledActionButton, StyledActionButtonWrapper, StyledActionIcon } from './ActionButton.styles';

const ActionButton = ({ isActive, onClick, title, icon, width, height }) => {
    const { t } = useTranslation();

    return (
        <StyledActionButtonWrapper>
            <StyledActionButton
                className={`rdw-option-wrapper ${isActive ? 'rdw-option-active' : ''}`}
                title={t(title)}
                onClick={onClick}
                width={width}
                height={height}
            >
                <StyledActionIcon src={icon} />
            </StyledActionButton>
        </StyledActionButtonWrapper>
    );
};

ActionButton.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default ActionButton;
