import { Box, Drawer, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useResponsive from '../../hooks/useResponsive';
import { USER_ROLES } from '../../utils/constants';
import { getKeyByValue, isComplexUserData } from '../../utils/helpers';
import getNavConfig from '../../utils/navConfig';
import Logo from '../Logo/Logo';
import NavSection from '../NavSection/NavSection';
import Scrollbar from '../Scrollbar/Scrollbar';
import UserAvatar from '../UserAvatar/UserAvatar';
import { AccountStyle, DRAWER_WIDTH, RootStyle } from './DasbhoardSidebar.styles';

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const user = useSelector((state) => state.userReducer);
    const theme = useTheme();
    const isDesktop = useResponsive('up', 'lg');

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                overflow: 'hidden',
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.palette.common.white,
                },
            }}
        >
            <Box sx={{ px: 2.5, py: 3, display: 'flex', justifyContent: 'center' }}>
                <Logo />
            </Box>

            <Box sx={{ mb: 5, mx: 2.5, mt: 1 }}>
                <AccountStyle>
                    <UserAvatar size={'small'} name={user.first_name} surname={user.last_name} />
                    <Box sx={{ ml: 2, userSelect: 'none' }}>
                        {isComplexUserData(user) && (
                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                {`${user.first_name} ${user.last_name}`}
                            </Typography>
                        )}
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {t(getKeyByValue(USER_ROLES, user.role))}
                        </Typography>
                    </Box>
                </AccountStyle>
            </Box>

            <NavSection navConfig={getNavConfig(user.role)} />
        </Scrollbar>
    );

    return (
        <RootStyle>
            <Drawer
                open={isOpenSidebar || isDesktop}
                onClose={onCloseSidebar}
                variant={isDesktop ? 'persistent' : 'temporary'}
                PaperProps={{
                    sx: {
                        width: DRAWER_WIDTH,
                        bgcolor: 'background.default',
                    },
                }}
            >
                {renderContent}
            </Drawer>
        </RootStyle>
    );
};

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

export default DashboardSidebar;
