import { Modal } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '../../../assets/icons/close-icon.svg';
import { DATA_TYPES, TABLE_SETTINGS } from '../../../utils/constants';
import Table from '../../Table/Table';
import { ActionIcon, ModalContainer } from './ImageGallery.styles';

const ImageGallery = ({ isOpen, handleClose }) => (
    <Modal open={isOpen} onClose={handleClose}>
        <ModalContainer>
            <ActionIcon src={CloseIcon} onClick={handleClose} />
            <Table
                dataType={DATA_TYPES.media}
                tableName={TABLE_SETTINGS.media.name}
                tableHead={TABLE_SETTINGS.media.tableHead}
            />
        </ModalContainer>
    </Modal>
);

ImageGallery.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default ImageGallery;
