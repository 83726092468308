import { Document, Page, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ParticipantResultPdf.styles';

const ParticipantResultPdf = ({ data }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <Text style={styles.infoContainer}>{`ID: ${data?.user_id}`}</Text>
                <Text style={styles.infoContainer}>{`${data?.last_name} ${data?.first_name}`}</Text>
                <Text style={styles.infoContainer}>{`Wynik: ${data?.score}`}</Text>
            </View>
        </Page>
    </Document>
);

ParticipantResultPdf.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ParticipantResultPdf;
