import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { USER_ROLES, USER_SEX } from '../../utils/constants';
import { getKeyByValue } from '../../utils/helpers';
import { USER_FORM_FIELDS } from '../Forms/UserForm/UserForm';
import { FieldContainer, FieldTitle, FieldValue } from './InfoField.styles';

const InfoField = ({ title, value, processData }) => {
    const { t } = useTranslation();

    const getUserDataValue = (field) => field ?? t('notSpecified');

    const getUserDataValueKey = (value) =>
        value
            ? t(getKeyByValue(title === USER_FORM_FIELDS.role ? USER_ROLES : USER_SEX, value))
            : t('notSpecified');

    return (
        <FieldContainer>
            <FieldTitle>{t(camelCase(title))}</FieldTitle>
            <FieldValue value={value}>
                {processData ? getUserDataValueKey(value) : getUserDataValue(value)}
            </FieldValue>
        </FieldContainer>
    );
};

InfoField.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    processData: PropTypes.bool,
};

export default InfoField;
