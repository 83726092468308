import styled, { css } from 'styled-components';

export const StyledOverlay = styled.div`
    ${({ fullScreen }) =>
        fullScreen &&
        css`
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        `};
`;
