import React, { useState } from 'react';
import ExpandButton from './ExpandButton.styles';

const GroupedTableRow = ({ groupName, children, expandAll }) => {
    const [expanded, setExpaned] = useState(expandAll);

    const handleExpand = (e) => {
        if (expandAll) return;
        e.stopPropagation();
        setExpaned((prev) => !prev);
    };

    return (
        <>
            <tr className={'groupNameRow'}>
                <td onDoubleClick={handleExpand}>
                    <ExpandButton onClick={handleExpand} expanded={expandAll} />
                    {groupName}
                </td>
            </tr>
            {expanded && children}
        </>
    );
};

export default GroupedTableRow;
