import styled from 'styled-components';

const ClipLoaderWrapper = styled.div`
    margin-bottom: 10px;
    display: flex;
    gap: 1rem;
    font-size: 0.9rem;
`;

export default ClipLoaderWrapper;
