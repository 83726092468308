import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RecoveryPasswordForm from '../../components/Forms/RecoveryPasswordForm/RecoveryPasswordForm';
import Page from '../../components/Page/Page';
import { ContentStyle, RootStyle } from '../Page.styles';

const RecoveryPasswordPage = () => {
    const { t } = useTranslation();

    return (
        <Page>
            <RootStyle>
                <Container maxWidth="sm">
                    <ContentStyle>
                        <Typography variant="h4" gutterBottom>
                            {t('recoveryPasswordPageTitle')}
                        </Typography>

                        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                            {t('recoveryPasswordPageText')}
                        </Typography>

                        <RecoveryPasswordForm />
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
};

export default RecoveryPasswordPage;
