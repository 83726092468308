import styled from 'styled-components';

export const Block = styled.div``;

export const ParamsBlock = styled.div`
    display: flex;
`;

export const ParamsText = styled.p`
    font-size: 0.9375rem;
    margin-bottom: 1rem;
`;
