import styled, { css } from 'styled-components';

export const StyledActionButton = styled.div`
    margin-bottom: 6px;
    height: 20px;

    ${({ width }) =>
        width &&
        css`
            width: ${width}px;
        `}

    ${({ height }) =>
        height &&
        css`
            height: ${height}px;
        `}
`;

export const StyledActionButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    height: auto;
`;

export const StyledActionIcon = styled.img`
    width: 15px;
    height: 15px;
`;
