import { pdf } from '@react-pdf/renderer';

const renderUrl = async (pdfDocument) =>
    new Promise((resolve, reject) => {
        pdf(pdfDocument)
            .toBlob()
            .then((blob) => {
                const url = URL.createObjectURL(blob);

                if (url && url?.length) {
                    resolve(url);
                } else {
                    reject(new Error('Error while generating URL from blob.'));
                }
            })
            .catch(() => reject(new Error('Error while generating PDF.')));
    });

const generatePdfFile = async (fileName, pdfDocument, data) =>
    new Promise((resolve, reject) => {
        renderUrl(pdfDocument, data)
            .then((generatedUrl) => {
                if (generatedUrl) {
                    const aTag = document.createElement('a');
                    aTag.href = generatedUrl;
                    aTag.style = 'display: none';
                    aTag.download = `${fileName}.pdf`;
                    document.body.appendChild(aTag);
                    aTag.click();
                    resolve(generatedUrl);
                } else {
                    reject(new Error('Error while generating URL.'));
                }
            })
            .catch(() => reject(new Error('Error while generating PDF.')));
    });

export default generatePdfFile;
