import { Box, Divider, List, useTheme } from '@mui/material';
import { groupBy } from 'lodash';
import PropTypes from 'prop-types';
import { matchPath, useLocation } from 'react-router-dom';
import { DATA_TYPES } from '../../utils/constants';
import NavItem from '../NavItem/NavItem';

const NavSection = ({ navConfig, ...other }) => {
    const CONTENT_HEIGHT = 175;
    const { pathname } = useLocation();
    const theme = useTheme();

    const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

    const drawCategory = (category, drawDivider) => (
        <div key={category}>
            {groupBy(navConfig, DATA_TYPES.category)[category].map((item) => (
                <NavItem key={item.title} item={item} active={match} />
            ))}
            {drawDivider && <Divider sx={{ backgroundColor: theme.palette.divider }} />}
        </div>
    );

    const drawNavItems = () =>
        [...new Set(navConfig.map((navItem) => navItem.category))].map((category, i, row) =>
            drawCategory(category, i + 1 !== row.length)
        );

    return (
        <Box
            {...other}
            sx={{
                height: `calc(100vh - ${CONTENT_HEIGHT}px)`,
                overflowY: 'scroll',
            }}
        >
            <List disablePadding sx={{ p: 1, mb: 10 }}>
                {drawNavItems()}
            </List>
        </Box>
    );
};

NavSection.propTypes = {
    navConfig: PropTypes.array,
};

export default NavSection;
