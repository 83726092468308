import { Avatar } from '@mui/material';
import styled, { css } from 'styled-components';

export const StyledAvatar = styled(Avatar)`
    ${({ size }) =>
        size === 'small'
            ? css`
                  width: 40px;
                  height: 40px;
                  font-size: 1.25rem;

                  ${({ theme }) => theme.mediaBreakpoints.laptop} {
                      width: 35px;
                      height: 35px;
                      font-size: 1rem;
                  }
              `
            : css`
                  width: 100px;
                  height: 100px;
                  font-size: 3rem;
              `}
`;
