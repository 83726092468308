import { Document, Page, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ParticipantsPdf.styles';

const ParticipantsPdf = ({ group, data, translate }) => {
    const renderElements = () =>
        data?.map((participant) => (
            <View wrap={false} style={styles.element} key={participant.id}>
                <View style={[styles.elementBody, { flex: 3 }]}>
                    <Text>{`${participant.last_name} ${participant.first_name}`}</Text>
                </View>
                <View style={styles.elementBody}>
                    <Text style={styles.elementTitle}>{translate('login')}</Text>
                    <Text style={styles.elementValue}>{`${participant.id}`}</Text>
                </View>
                <View style={styles.elementBody}>
                    <Text style={styles.elementTitle}>{translate('password')}</Text>
                    <Text style={styles.elementValue}>{`${participant.temp_password}`}</Text>
                </View>
            </View>
        ));

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={data?.length ? styles.header : {}}>
                    <Text style={styles.headerText}>{group}</Text>
                </View>
                <View>{renderElements()}</View>
                <View style={styles.footer} fixed>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) =>
                            `${translate('page')} ${pageNumber} ${translate('from')} ${totalPages}`
                        }
                    />
                </View>
            </Page>
        </Document>
    );
};

ParticipantsPdf.propTypes = {
    group: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    translate: PropTypes.func.isRequired,
};

export default ParticipantsPdf;
