import { Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import PasswordForm from '../../components/Forms/PasswordForm/PasswordForm';
import Page from '../../components/Page/Page';
import { NAV_LINKS } from '../../utils/constants';
import { checkPasswordResetToken } from '../../utils/fetchData';
import { ContentStyle, RootStyle } from '../Page.styles';

const ResetPasswordPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const token = location.search.split('=')[1];
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        checkPasswordResetToken(token)
            .then((res) => {
                if (res.success) {
                    setIsFetching(false);
                } else {
                    navigate(NAV_LINKS.unauthorized, {
                        state: { error: res.error },
                    });
                }
            })
            .catch(() => navigate(NAV_LINKS.unauthorized));
    }, []);

    return isFetching ? (
        <></>
    ) : (
        <Page>
            <RootStyle>
                <Container maxWidth="sm">
                    <ContentStyle>
                        <Typography variant="h4" gutterBottom>
                            {t('resetPasswordPageTitle')}
                        </Typography>

                        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                            {t('resetPasswordPageText')}
                        </Typography>

                        <PasswordForm token={token} />
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
};

export default ResetPasswordPage;
