import { Stack, TextField } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { camelCase, isEqual, mapValues } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import { saveUserData } from '../../../actions/userActions';
import {
    ALL_DATA,
    CATEGORIES,
    DATA_TYPES,
    FORM_TYPES,
    NAV_LINKS,
    ROUTES,
    USER_ROLES,
    USER_SEX,
} from '../../../utils/constants';
import { createData, getData, getDataById, getGroups, updateData } from '../../../utils/fetchData';
import { getKeyByValue, getNavLink, makeTitle } from '../../../utils/helpers';
import { FIELDS, useValidators } from '../../../utils/validators';
import AutocompleteSelect from '../../AutocompleteSelect/AutocompleteSelect';
import Form from '../../Form/Form';
import FormButtons from '../../Form/FormButtons/FormButtons';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';
import SelectField from '../../SelectField/SelectField';

export const USER_FORM_FIELDS = {
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    nickname: 'nickname',
    sex: 'sex',
    role: 'role',
    organizations: 'organizations',
    participantGroups: 'participant_groups',
};

const UserForm = ({ formType, dataType, category }) => {
    const QUERY_PARAMS = {
        groupId: 'groupId',
    };

    const { id, groupId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userReducer);
    const [user, setUser] = useState({});
    const [organizations, setOrganizations] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupsLoading, setGroupsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(
        formType === FORM_TYPES.edit && dataType !== DATA_TYPES.account
    );
    const [isFetchingAdditionalData, setIsFetchingAdditionalData] = useState(
        dataType === DATA_TYPES.organizationUser || dataType === DATA_TYPES.participant
    );
    const navLink = getNavLink(dataType, groupId);
    const location = useLocation();
    const userType = location?.pathname.split('/')[1];
    const validators = useValidators();

    const getDefaultRole = () => {
        switch (dataType) {
            case DATA_TYPES.administrator:
                return USER_ROLES.superAdmin;
            case DATA_TYPES.participant:
                return USER_ROLES.participant;
            case DATA_TYPES.organizationUser:
                return USER_ROLES.organizationAdmin;
            default:
                return '';
        }
    };

    const USER_SCHEMA = {
        first_name: '',
        last_name: '',
        email: '',
        sex: '',
        role: getDefaultRole(),
    };

    const getOrganizationUserInitialValues = () => ({
        add: {
            email: '',
            role: getDefaultRole(),
            organizations: [],
        },
        edit: {
            ...USER_SCHEMA,
            organizations: [],
        },
    });

    const getParticipantInitialValues = () => ({
        add: {
            email: '',
            role: getDefaultRole(),
            organizations: [],
            participant_groups: [],
        },
        edit: {
            ...USER_SCHEMA,
            organizations: [],
            participant_groups: [],
        },
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        if (formType === FORM_TYPES.edit && dataType !== DATA_TYPES.account) {
            getDataById(
                DATA_TYPES.user,
                id,
                dataType === DATA_TYPES.participant && groupId
                    ? {
                          name: QUERY_PARAMS.groupId,
                          value: groupId,
                      }
                    : {}
            )
                .then((res) => {
                    if (res.error) {
                        navigate(NAV_LINKS.unauthorized);
                    } else if (!Object.keys(res).length) {
                        navigate(NAV_LINKS.notFound);
                    } else {
                        res = mapValues(res, (v) => (v === null ? '' : v));

                        const { created_at, updated_at, email_verified_at, ...data } = res;

                        if (data?.id === userData?.id) {
                            navigate(NAV_LINKS.editProfile);
                        }

                        if (
                            (userType === ROUTES.administrators.main &&
                                data?.role === USER_ROLES.superAdmin) ||
                            (userType === ROUTES.participants.main &&
                                data?.role === USER_ROLES.participant) ||
                            (userType === ROUTES.participantGroups.main &&
                                data?.role === USER_ROLES.participant) ||
                            (userType === ROUTES.organizationUsers.main &&
                                data?.role === USER_ROLES.organizationAdmin)
                        ) {
                            setUser(data);
                        } else {
                            navigate(NAV_LINKS.notFound);
                        }
                    }
                })
                .catch(() => navigate(NAV_LINKS.notFound))
                .finally(() => setIsFetching(false));
        }

        if (dataType === DATA_TYPES.organizationUser || dataType === DATA_TYPES.participant) {
            getData(DATA_TYPES.organization, ALL_DATA)
                .then((res) => {
                    if (res.error) {
                        navigate(NAV_LINKS.notFound);
                        dispatch(openAlertError(res.error));
                    } else {
                        setOrganizations(res.data);
                    }
                })
                .catch(() => dispatch(openAlertError()))
                .finally(() => setIsFetchingAdditionalData(false));
        }
    };

    useEffect(() => {
        Object.keys(user).length &&
            formType === FORM_TYPES.edit &&
            dataType !== DATA_TYPES.account &&
            setValues(user);
    }, [user]);

    const BASIC_USER_ADD_SCHEMA = {
        email: Yup.string().email(t('emailRequirements')).required(t('emailRequired')),
        role: Yup.number().required(t('roleRequired')),
    };

    const BASIC_USER_EDIT_SCHEMA = {
        ...BASIC_USER_ADD_SCHEMA,
        first_name: Yup.string().min(2, t('firstNameMinCharacters')).required(t('firstNameRequired')),
        last_name: Yup.string().min(2, t('lastNameMinCharacters')).required(t('lastNameRequired')),
        sex: Yup.string().required(t('genderRequired')),
    };

    const getUserSchema = () => ({
        add: Yup.object().shape({
            ...BASIC_USER_ADD_SCHEMA,
        }),
        edit: Yup.object().shape({
            ...BASIC_USER_EDIT_SCHEMA,
        }),
    });

    const getOrganizationUserSchema = () => ({
        add: Yup.object().shape({
            ...BASIC_USER_ADD_SCHEMA,
            organizations: Yup.array().min(1, t('organizationRequired')),
        }),
        edit: Yup.object().shape({
            ...BASIC_USER_EDIT_SCHEMA,
            organizations: Yup.array().min(1, t('organizationRequired')),
        }),
    });

    const getParticipantSchema = () => ({
        add: Yup.object().shape({
            first_name: Yup.string().min(2, t('firstNameMinCharacters')).required(t('firstNameRequired')),
            last_name: Yup.string().min(2, t('lastNameMinCharacters')).required(t('lastNameRequired')),
            role: Yup.number().required(t('roleRequired')),
            organizations: Yup.array().min(1, t('organizationRequired')),
            participant_groups: Yup.array().min(1, t('participantGroupRequired')),
        }),
        edit: Yup.object().shape({
            first_name: Yup.string().min(2, t('firstNameMinCharacters')).required(t('firstNameRequired')),
            last_name: Yup.string().min(2, t('lastNameMinCharacters')).required(t('lastNameRequired')),
            [FIELDS.nickname]: validators[FIELDS.nickname].matches,
            email: Yup.string().email(t('emailRequirements')),
            role: Yup.number().required(t('roleRequired')),
            sex: Yup.string().required(t('genderRequired')),
            organizations: Yup.array().min(1, t('organizationRequired')),
            participant_groups: Yup.array().min(1, t('participantGroupRequired')),
        }),
    });

    const getInitialValues = () => {
        switch (dataType) {
            case DATA_TYPES.account:
                return userData;
            case DATA_TYPES.administrator:
                return getFormValues(USER_SCHEMA);
            case DATA_TYPES.organizationUser:
                return getFormValues(getOrganizationUserInitialValues()[formType]);
            case DATA_TYPES.participant:
                return getFormValues(getParticipantInitialValues()[formType]);
            default:
                return USER_SCHEMA;
        }
    };

    const getFormValues = (initialValues) => (formType === FORM_TYPES.edit ? user : initialValues);

    const getFormSchema = (schema) => (formType === FORM_TYPES.add ? schema.add : schema.edit);

    const getSchema = () => {
        switch (dataType) {
            case DATA_TYPES.account:
                return getUserSchema().edit;
            case DATA_TYPES.administrator:
                return getFormSchema(getUserSchema());
            case DATA_TYPES.organizationUser:
                return getFormSchema(getOrganizationUserSchema());
            case DATA_TYPES.participant:
                return getFormSchema(getParticipantSchema());
            default:
                return getUserSchema().add;
        }
    };

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: getSchema(),
        onSubmit: (values) => submitValues(values),
    });

    const {
        errors,
        touched,
        isValid,
        values,
        setValues,
        setFieldTouched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        validateField,
    } = formik;

    useEffect(() => {
        if (
            dataType === DATA_TYPES.participant &&
            formType === FORM_TYPES.edit &&
            Object.keys(values)?.length
        ) {
            setFieldValue(
                USER_FORM_FIELDS.participantGroups,
                values?.participant_groups?.filter((group) =>
                    values?.organizations
                        ?.map((organization) => organization.id)
                        ?.includes(group.organization_id)
                )
            );
            setFieldTouched(USER_FORM_FIELDS.participantGroups, '', false);
        }

        if (values?.organizations?.length) {
            const organizationIds = values?.organizations?.map((organization) => organization.id);
            setGroupsLoading(true);
            getGroups(organizationIds)
                .then((res) => {
                    if (!res.error) {
                        setGroups(res);
                    } else {
                        dispatch(openAlertError());
                    }
                })
                .catch(() => dispatch(openAlertError()))
                .finally(() => setGroupsLoading(false));
        } else {
            setGroups([]);
        }
    }, [values.organizations]);

    const submitValues = (payload) => {
        const { organizations, participant_groups, ...data } = payload;
        const organizationsId = organizations?.map((organization) => organization.id);
        const groupsId = participant_groups?.map((group) => group.id);
        const postData = {
            organizationUsers: {
                ...data,
                organization_id: organizationsId,
            },
            participants: {
                ...data,
                organization_id: organizationsId,
                group_id: groupsId,
            },
        };

        switch (dataType) {
            case DATA_TYPES.account:
                return updateData(DATA_TYPES.user, userData?.id, payload)
                    .then((res) => {
                        if (res.success) {
                            dispatch(saveUserData(payload));
                            navigate(NAV_LINKS.userProfile);
                            dispatch(openAlertSuccess(res.success));
                        } else {
                            dispatch(openAlertError(res.error));
                        }
                    })
                    .catch(() => dispatch(openAlertError()))
                    .finally(() => setSubmitting(false));
            case DATA_TYPES.administrator:
                if (formType === FORM_TYPES.edit) {
                    return updateData(DATA_TYPES.user, getUserData()?.id, payload)
                        .then((res) => {
                            if (res.success) {
                                navigate(NAV_LINKS.administrators);
                                dispatch(openAlertSuccess(res.success));
                            } else {
                                dispatch(openAlertError(res.error));
                            }
                        })
                        .catch(() => dispatch(openAlertError()))
                        .finally(() => setSubmitting(false));
                }

                return createData(DATA_TYPES.user, payload)
                    .then((res) => {
                        if (res.success) {
                            navigate(NAV_LINKS.administrators);
                            dispatch(openAlertSuccess(res.success));
                        } else {
                            dispatch(openAlertError(res.error));
                        }
                    })
                    .catch(() => dispatch(openAlertError()))
                    .finally(() => setSubmitting(false));

            case DATA_TYPES.organizationUser:
                if (formType === FORM_TYPES.edit) {
                    return updateData(
                        DATA_TYPES.user,
                        postData.organizationUsers.id,
                        postData.organizationUsers
                    )
                        .then((res) => {
                            if (res.success) {
                                navigate(NAV_LINKS.organizationUsers);
                                dispatch(openAlertSuccess(res.success));
                            } else {
                                dispatch(openAlertError(res.error));
                            }
                        })
                        .catch(() => dispatch(openAlertError()))
                        .finally(() => setSubmitting(false));
                }

                return createData(DATA_TYPES.user, postData.organizationUsers)
                    .then((res) => {
                        if (res.success) {
                            navigate(NAV_LINKS.organizationUsers);
                            dispatch(openAlertSuccess(res.success));
                        } else {
                            dispatch(openAlertError(res.error));
                        }
                    })
                    .catch(() => dispatch(openAlertError()))
                    .finally(() => setSubmitting(false));

            case DATA_TYPES.participant:
                if (formType === FORM_TYPES.edit) {
                    return updateData(DATA_TYPES.participant, postData.participants.id, postData.participants)
                        .then((res) => {
                            if (res.success) {
                                navigate(navLink);
                                dispatch(openAlertSuccess(res.success));
                            } else {
                                dispatch(openAlertError(res.error));
                            }
                        })
                        .catch(() => dispatch(openAlertError()))
                        .finally(() => setSubmitting(false));
                }

                return createData(DATA_TYPES.participant, postData.participants)
                    .then((res) => {
                        if (res.success) {
                            navigate(NAV_LINKS.participants);
                            dispatch(openAlertSuccess(res.success));
                        } else {
                            dispatch(openAlertError(res.error));
                        }
                    })
                    .catch(() => dispatch(openAlertError()))
                    .finally(() => setSubmitting(false));

            default:
                return payload;
        }
    };

    const getSelectValues = (type) => {
        switch (type) {
            case USER_FORM_FIELDS.role:
                return USER_ROLES;
            case USER_FORM_FIELDS.sex:
                return USER_SEX;
            default:
                return {};
        }
    };

    const isFormValid = () =>
        formType === FORM_TYPES.add
            ? isValid && Object.keys(touched).length
            : isValid && !isEqual(getUserData(), values);

    const isSuperAdmin = () => getUserData()?.role === USER_ROLES.superAdmin;

    const getUserData = () =>
        formType === FORM_TYPES.edit && dataType === DATA_TYPES.account ? userData : user;

    const createTextField = (field) => (
        <TextField
            key={field}
            id={field}
            name={field}
            label={t(camelCase(field))}
            value={values[field] ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched[field] && errors[field]}
            error={Boolean(touched[field] && errors[field])}
            onInput={() => setFieldTouched(field, true, true)}
        />
    );

    const getFieldType = () => {
        const { sex, role, email, nickname, organizations, participantGroups, ...textFields } =
            USER_FORM_FIELDS;

        const selectFields = { sex, role };

        switch (formType) {
            case FORM_TYPES.edit:
                let editFields = Object.values({ ...textFields });
                if (dataType === DATA_TYPES.participant) {
                    editFields = [...editFields, nickname];
                }

                editFields = [...editFields, email];
                return {
                    text: editFields,
                    select: Object.values(selectFields),
                };
            case FORM_TYPES.add:
                if (dataType === DATA_TYPES.participant) {
                    return {
                        text: Object.values(textFields),
                        select: Object.values({ role }),
                    };
                }

                return {
                    text: Object.values({ email }),
                    select: Object.values({ role }),
                };
            default:
                return {
                    text: Object.values(textFields),
                    select: Object.values(selectFields),
                };
        }
    };

    const createSelectField = (field, selectValues) => {
        const isRoleSelect = field === USER_FORM_FIELDS.role && DATA_TYPES.account;

        if (values[field] === undefined) {
            return null;
        }

        return (
            <SelectField
                key={field}
                field={field}
                label={USER_FORM_FIELDS[field]}
                touched={touched}
                errors={errors}
                values={values}
                selectValues={selectValues}
                handleChange={handleChange}
                handleBlur={handleBlur}
                disabled={isRoleSelect}
                hideOptions={isRoleSelect}
            />
        );
    };

    useEffect(() => {
        if (formType === FORM_TYPES.add) {
            if (organizations.length === 1) {
                setFieldValue(USER_FORM_FIELDS.organizations, organizations);
            }
            if (groups.length === 1) {
                setFieldValue(USER_FORM_FIELDS.participantGroups, groups);
            }
        }
    }, [organizations, groups]);

    const getOrganizationLabel = (option) => option.short_name;

    const getGroupLabel = (option) =>
        groups.length
            ? `${option.name} - ${
                  groups.filter((value) => value.id === option.id)[0]?.organization?.short_name
              }`
            : option.name;

    const generateOrganizationsSelect = () => {
        if (dataType === DATA_TYPES.organizationUser || dataType === DATA_TYPES.participant) {
            if (organizations.length === 1) {
                return null;
            }

            return (
                <AutocompleteSelect
                    multiple
                    field={USER_FORM_FIELDS.organizations}
                    label={getKeyByValue(USER_FORM_FIELDS, USER_FORM_FIELDS.organizations)}
                    values={organizations}
                    value={values?.organizations}
                    getOptionLabel={getOrganizationLabel}
                    onChange={(e, value) => {
                        const selectedOrganizations = value.map((org) => org.id);
                        setFieldValue(USER_FORM_FIELDS.organizations, value);
                        setFieldTouched(USER_FORM_FIELDS.organizations, true, false);
                        if (USER_FORM_FIELDS.participantGroups in values && selectedOrganizations.length) {
                            const filteredParticipantGroups = values.participant_groups.filter(
                                (group) =>
                                    !!selectedOrganizations.find((orgId) => orgId === group.organization_id)
                            );
                            setFieldValue(
                                USER_FORM_FIELDS.participantGroups,
                                filteredParticipantGroups,
                                false
                            );
                            validateField(USER_FORM_FIELDS.organizations);
                        }
                    }}
                    onBlur={handleBlur}
                    helperText={touched?.organizations && errors?.organizations}
                    error={Boolean(touched?.organizations && errors?.organizations)}
                    onInput={() => setFieldTouched(USER_FORM_FIELDS.organizations, true, true)}
                />
            );
        }

        return <></>;
    };

    const generateGroupsSelect = () => {
        if (dataType === DATA_TYPES.participant) {
            if (groups.length === 1) {
                if (USER_FORM_FIELDS.participantGroups in values) {
                    setFieldValue(USER_FORM_FIELDS.participantGroups, groups);
                }
                return <></>;
            }
            if (
                !values?.organizations?.length &&
                values?.participant_groups?.length &&
                USER_FORM_FIELDS.participantGroups in touched
            ) {
                setFieldValue(USER_FORM_FIELDS.participantGroups, []);
                setFieldTouched(USER_FORM_FIELDS.participantGroups, false, false);
            }

            return (
                <AutocompleteSelect
                    multiple
                    loading={groupsLoading}
                    field={USER_FORM_FIELDS.participantGroups}
                    label={getKeyByValue(USER_FORM_FIELDS, USER_FORM_FIELDS.participantGroups)}
                    values={groups}
                    value={values?.participant_groups}
                    getOptionLabel={getGroupLabel}
                    onChange={(e, value) => {
                        setFieldValue(USER_FORM_FIELDS.participantGroups, value);
                        setFieldTouched(USER_FORM_FIELDS.participantGroups, true, false);
                    }}
                    onBlur={handleBlur}
                    helperText={touched?.participant_groups && errors?.participant_groups}
                    error={Boolean(touched?.participant_groups && errors?.participant_groups)}
                    onInput={() => setFieldTouched(USER_FORM_FIELDS.participantGroups, true, true)}
                    disabled={!values?.organizations?.length}
                />
            );
        }

        return <></>;
    };

    const generateTextFields = () => getFieldType().text.map((field) => createTextField(field));

    const generateSelectFields = () =>
        getFieldType()
            .select.filter((field) =>
                dataType === DATA_TYPES.administrator ||
                dataType === DATA_TYPES.participant ||
                dataType === DATA_TYPES.organizationUser ||
                (dataType === DATA_TYPES.account && !isSuperAdmin())
                    ? field !== USER_FORM_FIELDS.role
                    : field
            )
            .map((field) => createSelectField(field, getSelectValues(field)));

    const isDataFetching = () => isFetching || isFetchingAdditionalData;

    return isDataFetching() ? (
        <LoadingOverlay fullScreen />
    ) : (
        <FormikProvider value={formik}>
            <Form
                title={makeTitle({
                    formType,
                    category: category === CATEGORIES.participantGroups ? CATEGORIES.participants : category,
                })}
                onSubmit={handleSubmit}
            >
                <Stack spacing={3} m={4}>
                    {generateTextFields()}
                    {generateSelectFields()}
                    {generateOrganizationsSelect()}
                    {generateGroupsSelect()}
                    <FormButtons
                        navLink={navLink}
                        formType={formType}
                        isFormValid={!!isFormValid()}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                    />
                </Stack>
            </Form>
        </FormikProvider>
    );
};

UserForm.propTypes = {
    formType: PropTypes.string.isRequired,
    dataType: PropTypes.string.isRequired,
};

export default UserForm;
