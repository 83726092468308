import PropTypes from 'prop-types';
import { StyledAvatar } from './UserAvatar.styles';

const UserAvatar = ({ name, surname, size }) => {
    const createInitials = (name, surname) => name.charAt(0).toUpperCase() + surname.charAt(0).toUpperCase();

    return <StyledAvatar size={size}>{name && surname && createInitials(name, surname)}</StyledAvatar>;
};

UserAvatar.propTypes = {
    name: PropTypes.string,
    surname: PropTypes.string,
    size: PropTypes.string,
};

export default UserAvatar;
