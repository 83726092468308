import { Stack } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ORDER_STATUS } from '../../../../utils/constants';
import SelectField from '../../../SelectField/SelectField';

const SimpleSelectForm = ({ selectFields, onSubmit, renderButtons }) => {
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape(
        Object.fromEntries(
            selectFields.map((field) => {
                switch (field.field) {
                    case 'status':
                        return [
                            field.field,
                            Yup.number().required(t('statusRequired')).typeError(t('statusRequired')),
                        ];
                    default:
                        console.warn('No validation schema for field: ', field.field);
                        return [];
                }
            })
        )
    );

    const formik = useFormik({
        initialValues: Object.fromEntries(selectFields.map((field) => [field.field, field?.initial ?? ''])),
        validationSchema,
        onSubmit,
    });

    const {
        errors,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
        handleBlur,
        handleSubmit,
        validateField,
        isSubmitting,
    } = formik;

    const isFormValid = () =>
        Boolean(Object.values(touched).filter((i) => i).length) &&
        !Object.values(errors).filter((i) => i).length &&
        Boolean(selectFields.map((field) => field.initial === values[field.field]).filter((i) => !i).length);

    const generateSelectFields = () =>
        selectFields.map((selectField, index) => {
            const { field, label, selectValues, initial } = selectField;
            return (
                <SelectField
                    key={index}
                    field={field}
                    label={label}
                    touched={touched}
                    errors={errors}
                    values={values}
                    disabled={ORDER_STATUS[initial] === 'completed'}
                    selectValues={selectValues}
                    handleChange={async (e, value) => {
                        await setFieldValue(field, value?.id ?? '');
                        await setFieldTouched(field, true);
                        validateField(field);
                    }}
                    handleBlur={handleBlur}
                    autocomplete
                />
            );
        });

    return (
        <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
                <Stack spacing={4} m={4}>
                    {generateSelectFields()}
                    {renderButtons({
                        handleSubmit,
                        isFormValid,
                        isSubmitting,
                    })}
                </Stack>
            </Form>
        </FormikProvider>
    );
};

export default SimpleSelectForm;
