import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const AnswerCheckbox = ({ isCorrect, onChange, disabled, ...rest }) => (
    <Checkbox
        checked={isCorrect}
        onChange={() => {
            const { rowIndex } = rest;
            onChange(rowIndex, !isCorrect);
        }}
        disabled={disabled}
    />
);
AnswerCheckbox.defaultProps = {
    disabled: false,
};

AnswerCheckbox.propTypes = {
    isCorrect: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default AnswerCheckbox;
