import styled from 'styled-components';
import { TableWrapper } from '../Table.styles';

export const ModalWrapper = styled.div`
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    gap: ${({ gap }) => gap ?? '2rem'};
    flex-direction: column;
    width: 60vw;
    max-width: ${({ maxWidth }) => maxWidth ?? '1000px'};
    height: ${({ height }) => height ?? '100%'};
    max-height: 80vh;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const TableModalWrapper = styled(TableWrapper)`
    width: 100%;
    height: ${({ height }) => height ?? '100%'};
    border: 1px solid ${({ theme }) => theme.palette.tableBorder};
    .ag-header-cell:not([aria-colindex='1']) .ag-header-cell-label {
        justify-content: center;
    }
    .deleteButton {
        padding: 0;
        button {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }
    .text-center {
        text-align: center;
    }
`;

export const CustomTableWrapper = styled.div`
    flex: 1;
    flex-direction: column;
`;

export const Title = styled.h6`
    font-size: 0.9rem;
    margin-block: 1rem;
`;

export const CustomTableModalWrapper = styled(TableWrapper)`
    width: 100%;
    height: 100%;
    overflow: auto;
    border: none;
    margin-block: 1rem;
`;

export const TableModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 1rem;

    .questionContent {
        display: flex;
        align-items: center;
        word-break: break-word;
        padding: 1rem;
        background-color: ${({ theme }) => theme.palette.background.neutral};
        border-radius: 8px;
    }
    .buttons {
        display: flex;
        gap: 1rem;
        align-items: center;
    }
    ${({ theme }) => theme.mediaBreakpoints.tablet} {
        flex-direction: column;
        gap: 0.5rem;
        .buttons {
            flex-direction: column;
            align-items: initial;
            button {
                width: 100%;
            }
        }
    }
`;
