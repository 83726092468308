import { Font, StyleSheet } from '@react-pdf/renderer';

import RobotoBold from '../../../assets/fonts/Roboto/Roboto-Bold.ttf';
import RobotoRegular from '../../../assets/fonts/Roboto/Roboto-Regular.ttf';
import RobotoThin from '../../../assets/fonts/Roboto/Roboto-Thin.ttf';

const BORDER = '1px dotted #c0c0c0';

Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: RobotoThin,
            fontWeight: 'thin',
        },
        {
            src: RobotoRegular,
        },
        {
            src: RobotoBold,
            fontWeight: 'bold',
        },
    ],
});

export default StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        fontFamily: 'Poppins',
        fontWeight: 'thin',
    },
    header: {
        borderBottom: BORDER,
    },
    headerText: {
        fontSize: 12,
        fontWeight: 'bold',
        padding: 20,
    },
    element: {
        paddingVertical: 20,
        borderBottom: BORDER,
        marginLeft: -1,
        display: 'flex',
        flexDirection: 'row',
    },
    elementBody: {
        fontSize: 10,
        marginLeft: 1,
        paddingHorizontal: 15,
        flex: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    elementTitle: {
        fontWeight: 'normal',
        flex: 2,
    },
    elementValue: {
        flex: 3,
    },
    footer: {
        marginTop: 'auto',
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pageNumber: {
        fontSize: 8,
    },
});
