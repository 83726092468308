import { Popover } from '@mui/material';
import PropTypes from 'prop-types';
import { ArrowStyle } from './MenuPopover.styles';

const MenuPopover = ({ children, sx, ...other }) => (
    <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
            sx: {
                p: 1,
                width: 200,
                overflow: 'inherit',
                ...sx,
            },
        }}
        {...other}
    >
        <ArrowStyle className="arrow" />

        {children}
    </Popover>
);

MenuPopover.propTypes = {
    children: PropTypes.node.isRequired,
    sx: PropTypes.object,
};

export default MenuPopover;
