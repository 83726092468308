import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMAGE_LINK_REGEX } from '../../utils/constants';
import MediaImage from './MediaImage/MediaImage';
import {
    MediaButton,
    MediaButtonsContainer,
    MediaInfoText,
    MediaInputContainer,
    MediaPreviewContainer,
} from './MediaInput.styles';
import MediaModal from './MediaModal/MediaModal';
import YouTubePlayer from './YouTubePlayer/YouTubePlayer';

const MediaInput = ({ field, mediaValue, onSubmit, margin, elementId, isPreview, buttonText }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();

    const handleModalOpen = () => setIsModalOpen(true);

    const handleModalClose = (clearValues) => {
        clearValues && clearValues();
        setIsModalOpen(false);
    };

    const clearMediaValue = () => onSubmit(elementId ?? field, '');

    const isImage = () => mediaValue.match(IMAGE_LINK_REGEX);

    const renderMedia = () => {
        if (mediaValue && isImage()) {
            return <MediaImage src={mediaValue} />;
        }

        if (mediaValue && !isImage()) {
            return <YouTubePlayer videoId={mediaValue} />;
        }

        return <></>;
    };

    const renderMediaButtons = () => {
        if (isPreview) {
            if (mediaValue) {
                return <></>;
            }
            return <MediaInfoText>{t('none')}</MediaInfoText>;
        }

        return (
            <MediaButtonsContainer>
                <MediaButton
                    variant="contained"
                    color={mediaValue ? 'warning' : 'primary'}
                    startIcon={mediaValue ? <EditIcon /> : <AddIcon />}
                    onClick={handleModalOpen}
                >
                    {t(mediaValue ? 'edit' : buttonText || 'add')}
                </MediaButton>
                {mediaValue && (
                    <MediaButton
                        sx={{ mt: 3 }}
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={clearMediaValue}
                    >
                        {t('delete')}
                    </MediaButton>
                )}
            </MediaButtonsContainer>
        );
    };

    return (
        <MediaInputContainer margin={margin}>
            {renderMediaButtons()}

            {mediaValue && (
                <MediaPreviewContainer isPreview={isPreview}>{renderMedia()}</MediaPreviewContainer>
            )}
            <MediaModal
                field={field}
                open={isModalOpen}
                handleClose={handleModalClose}
                onSubmit={onSubmit}
                mediaValue={mediaValue}
                elementId={elementId}
            />
        </MediaInputContainer>
    );
};

MediaInput.defaultProps = {
    margin: '0 0 0 0',
    elementId: null,
    isPreview: false,
};

MediaInput.propTypes = {
    mediaValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    margin: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    elementId: PropTypes.number,
    isPreview: PropTypes.bool,
};

export default MediaInput;
