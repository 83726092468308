import { useTranslation } from 'react-i18next';
import { APP_NAME, COMPANY_NAME } from '../../utils/constants';
import { FooterText, StyledFooter } from './Footer.styles';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <StyledFooter>
            <FooterText>
                {`${COMPANY_NAME} - ${APP_NAME}`} &copy;
                {` ${new Date().getFullYear()} `}
                {t('allRightsReserved')}
            </FooterText>
        </StyledFooter>
    );
};

export default Footer;
