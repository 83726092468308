import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';
import HashLoader from 'react-spinners/HashLoader';
import palette from '../../theme/palette';
import { LOADING_SPINNER_TYPES } from '../../utils/constants';
import ClipLoaderWrapper from './ClipLoaderWrapper.styles';
import { StyledLoadingWrapper } from './LoadingSpinner.styles';

const LoadingSpinner = ({ color, loading, size, type, text }) => (
    <StyledLoadingWrapper>
        {type === LOADING_SPINNER_TYPES.hash && <HashLoader color={color} loading={loading} size={size} />}
        {type === LOADING_SPINNER_TYPES.clip && (
            <ClipLoaderWrapper>
                <span>{text}</span>
                <ClipLoader color={color} loading={loading} size={size} />
            </ClipLoaderWrapper>
        )}
    </StyledLoadingWrapper>
);

LoadingSpinner.defaultProps = {
    loading: true,
    type: LOADING_SPINNER_TYPES.hash,
    size: 45,
    color: palette.common.red,
};

LoadingSpinner.propTypes = {
    color: PropTypes.string,
    loading: PropTypes.bool,
    size: PropTypes.number,
    type: PropTypes.oneOf([LOADING_SPINNER_TYPES.hash, LOADING_SPINNER_TYPES.clip]),
    text: PropTypes.string,
};

export default LoadingSpinner;
