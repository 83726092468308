import i18next from 'i18next';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import store from './store/store';
import commonEn from './translations/en.json';
import commonPl from './translations/pl.json';
import { LANGUAGE_PL, LOCAL_STORAGE_KEY } from './utils/constants';
import { ignoreLogs } from './utils/helpers';

ignoreLogs(['Autocomplete']);

i18next.init({
    interpolation: { escapeValue: false },
    lng: localStorage.getItem(LOCAL_STORAGE_KEY.language) ?? LANGUAGE_PL,
    resources: {
        en: {
            translation: commonEn,
        },
        pl: {
            translation: commonPl,
        },
    },
});

i18next.services.formatter.add('lowercase', (value) => value.toLowerCase());

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <HelmetProvider>
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </I18nextProvider>
        </Provider>
    </HelmetProvider>
);
