import { Stack } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { AgGridReact } from 'ag-grid-react';
import { Form, FormikProvider, useFormik } from 'formik';
import { camelCase, kebabCase, mapKeys } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../actions/alertActions';
import { EditorStateContext } from '../../context/EditorStateContext';
import useBrowserWidth from '../../hooks/useBrowserWidth';
import {
    ALL_DATA,
    COLUMN_WIDTHS,
    CURRENCY,
    DATA_TYPES,
    FILTER_TYPES,
    FORM_TYPES,
    HTTP_ERRORS,
    LIST_ORDER_STATUS,
    MANAGE_DATA,
    MEDIA_ALERTS,
    NAV_LINKS,
    ORDER_STATUS,
    ORDER_STATUS_NAMES,
    PACKAGE_PARTS,
    QUESTIONS_DIFFICULTY,
    ROUNDS_INFO,
    TABLE_FIELDS,
    TABLE_FILTERS,
    TABLE_OPTIONS,
    TEST_STATUS,
    USER_ORGANIZATIONS_ROLES,
    USER_ROLES,
    USER_SEX,
    getImageLink,
} from '../../utils/constants';
import {
    deleteData,
    exportUserParticipants,
    getData,
    getLogActivityTypes,
    getParticipantsByGroup,
    getQuestionList,
    getQuiz,
    getQuizResults,
    getSectionsByCategory,
    importUserParticipants,
    manageMedia,
    updateData,
} from '../../utils/fetchData';
import generatePdfFile from '../../utils/generatePdfFile';
import {
    formatDate,
    formatPrice,
    getEditLink,
    getGroupParticipantsLink,
    getKeyByValue,
    getMonths,
    getNavLink,
    getOrganizationPackagesLink,
    getPermissions,
    getQuestionDifficultyName,
    getQuizLink,
    getQuizParametersLink,
    getQuizResultsLink,
    getSectionsLink,
    getShowLink,
    getTrainingLink,
    getYears,
    isTableContentFit,
    renameDifficulty,
    setCenterCell,
    underscoreText,
} from '../../utils/helpers';
import AG_GRID_LOCALE from '../../utils/tableTranslations';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import FormButtons from '../Form/FormButtons/FormButtons';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import MediaImage from '../MediaInput/MediaImage/MediaImage';
import PaginationPanel from '../PaginationPanel/PaginationPanel';
import ParticipantResultPdf from '../PdfDocuments/ParticipantResultPdf/ParticipantResultPdf';
import ParticipantsPdf from '../PdfDocuments/ParticipantsPdf/ParticipantsPdf';
import PointsPill from '../PointsPill/PointsPill';
import SectionTitle from '../SectionTitle/SectionTitle';
import SelectField from '../SelectField/SelectField';
import AddButton from './AddButton/AddButton';
import {
    ButtonsContainer,
    TableButtonsContainer,
    TableContainer,
    TableName,
    TableNameContainer,
    TableWrapper,
} from './Table.styles';
import TableModal from './TableModal/TableModal';
import TableMoreMenu from './TableMoreMenu/TableMoreMenu';

const Table = ({ apiUrl, tableName, tableHead, dataType }) => {
    const TEST_STATUSES = [TEST_STATUS.new, TEST_STATUS.inProgress, TEST_STATUS.finished];
    const DESKTOP_WIDTH = 1200;
    const PAGINATION_SIZES = [5, 10, 50, 100, 250, 500];
    const DEFAULT_PAGINATION_SETTINGS = {
        page: 1,
        perPage: PAGINATION_SIZES[1],
    };

    const FILTER_FIELDS = {
        organizationShortName: 'organizationShortName',
        groupName: 'groupName',
        userFullName: 'userFullName',
        authorFullName: 'authorFullName',
        sectionTitle: 'sectionTitle',
        questionContent: 'questionContent',
        date: 'date',
        productName: 'productName',
        grade: {
            id: 'gradeId',
            name: 'gradeName',
            question: 'questionGradeName',
        },
        category: {
            id: 'categoryId',
            name: 'categoryName',
            question: 'questionCategoryName',
        },
        orderStatus: 'status',
        createdAt: 'createdAt',
    };

    const [gridApi, setGridApi] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [pageNumber, setPageNumber] = useState(DEFAULT_PAGINATION_SETTINGS.page);
    const [paginationPageSize, setPaginationPageSize] = useState(DEFAULT_PAGINATION_SETTINGS.perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [filters, setFilters] = useState({});
    const { t, i18n } = useTranslation();
    const [itemName, setItemName] = useState();
    const navigate = useNavigate();
    const currentBrowserWidth = useBrowserWidth();
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector((state) => state.userReducer);
    const permissions = getPermissions(tableName, user.role);
    const onGridReady = (params) => setGridApi(params.api);
    const [categories, setCategories] = useState([]);
    const [grades, setGrades] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [tableKey, setTableKey] = useState(0);
    const [logActivityTypes, setLogActivityTypes] = useState({
        object: {},
        array: [],
    });
    const [isFetchingInitial, setIsFetchingInitial] = useState(dataType === DATA_TYPES.quizResults);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isFetchingAdditionalData, setIsFetchingAdditionalData] = useState(false);
    const [isMultiSelect, setIsMultiSelect] = useState(false);
    const dataId = location?.pathname.split('/')[2];
    const elementId = location?.pathname.split('/')[4];
    const [modal, setModal] = useState({
        open: false,
        data: null,
    });
    const [deleteModal, setDeleteModal] = useState({
        open: false,
        action: null,
        data: null,
    });

    const editorContext = useContext(EditorStateContext);

    const currentDate = new Date();
    const years = getYears();
    const months = getMonths();

    const QUIZ_RESULTS_CONFIG = {
        organization: 'organization',
        year: 'year',
        month: 'month',
    };

    const validationSchema = Yup.object().shape({
        [QUIZ_RESULTS_CONFIG.organization]: Yup.number().required(t('organizationIdRequired')),
        [QUIZ_RESULTS_CONFIG.year]: Yup.number().required(t('yearRequired')),
        [QUIZ_RESULTS_CONFIG.month]: Yup.number().required(t('monthRequired')),
    });

    const formik = useFormik({
        initialValues: {
            organization: '',
            month: currentDate.getMonth() + 1,
            year: currentDate.getFullYear(),
        },
        validationSchema,
        validateOnMount: true,
    });

    const { values, errors, touched, isValid, setFieldValue, setFieldTouched, handleBlur } = formik;

    const getTestStatus = (startedAt, finishedAt) => {
        if (!startedAt && !finishedAt) {
            return t(TEST_STATUS.new);
        }
        if (startedAt && !finishedAt) {
            return t(TEST_STATUS.inProgress);
        }
        if (startedAt && finishedAt) {
            return t(TEST_STATUS.finished);
        }
        return '';
    };

    const getCellValue = (params, field) => {
        if (!params.data[field] && field !== TABLE_FIELDS.status && field !== TABLE_FIELDS.organizationName) {
            return params.data[field] === 0 ? 0 : '';
        }
        switch (field) {
            case TABLE_FIELDS.role:
                return t(getKeyByValue(USER_ROLES, params.data[field]));
            case TABLE_FIELDS.sex:
                return t(getKeyByValue(USER_SEX, params.data[field]));
            case TABLE_FIELDS.organization:
                return params.data[field].short_name ?? params.data[field].name;
            case TABLE_FIELDS.organizations:
                return params.data[field].map((org) => org.short_name).join(', ');
            case TABLE_FIELDS.createdAt:
                return formatDate(params.data[field], true);
            case TABLE_FIELDS.participantGroups:
                return params.data[field].map((group) => group.name).join(', ');
            case TABLE_FIELDS.category:
                return params.data[field].name;
            case TABLE_FIELDS.grade:
                return params.data[field].name;
            case TABLE_FIELDS.grades:
                return params.data[field].map((grade) => grade.name).join(', ');
            case TABLE_FIELDS.question:
                return params.data[field].content;
            case TABLE_FIELDS.user:
                return `${params.data[field].last_name} ${params.data[field].first_name}`;
            case TABLE_FIELDS.participantGroupUser:
                return params.data[field].participant_group.name;
            case TABLE_FIELDS.organizationName:
                return params.data.test_definition.organization.short_name;
            case TABLE_FIELDS.status:
                return getTestStatus(params.data.started_at, params.data.finished_at);
            case TABLE_FIELDS.difficulty:
                return t(getQuestionDifficultyName(params.data.difficulty));
            case TABLE_FIELDS.section:
                return params.data[field].title;
            case TABLE_FIELDS.price:
                return formatPrice(params.data[field]);
            case TABLE_FIELDS.product:
                return params.data[field].name;
            case TABLE_FIELDS.orderStatus:
                return t(ORDER_STATUS[params.data.status]);
            case TABLE_FIELDS.type:
                return t(
                    camelCase(logActivityTypes?.object[params.data[field]]),
                    logActivityTypes?.object[params.data[field]]
                );
            default:
                return params.data[field];
        }
    };

    const getTooltipValue = (params, field) => {
        if (!params.data[field] && field !== TABLE_FIELDS.status && field !== TABLE_FIELDS.organizationName) {
            return '';
        }

        switch (field) {
            case TABLE_FIELDS.role:
                return t(getKeyByValue(USER_ROLES, params.data[field]));
            case TABLE_FIELDS.sex:
                return t(getKeyByValue(USER_SEX, params.data[field]));
            case TABLE_FIELDS.organization:
                return params.data[field].name;
            case TABLE_FIELDS.organizations:
                return params.data[field].map((org) => org.name).join(', ');
            case TABLE_FIELDS.createdAt:
                return formatDate(params.data[field], true);
            case TABLE_FIELDS.participantGroups:
                return params.data[field].map((group) => group.name).join(', ');
            case TABLE_FIELDS.category:
                return params.data[field].name;
            case TABLE_FIELDS.grade:
                return params.data[field].name;
            case TABLE_FIELDS.grades:
                return params.data[field].map((grade) => grade.name).join(', ');
            case TABLE_FIELDS.question:
                return params.data[field].content;
            case TABLE_FIELDS.user:
                return `${params.data[field].last_name} ${params.data[field].first_name}`;
            case TABLE_FIELDS.participantGroupUser:
                return params.data[field].participant_group.name;
            case TABLE_FIELDS.organizationName:
                return params.data.test_definition.organization.name;
            case TABLE_FIELDS.status:
                return getTestStatus(params.data.started_at, params.data.finished_at);
            case TABLE_FIELDS.difficulty:
                return t(getQuestionDifficultyName(params.data.difficulty));
            case TABLE_FIELDS.image:
            case TABLE_FIELDS.link:
                return '';
            case TABLE_FIELDS.section:
                return params.data[field].title;
            case TABLE_FIELDS.price:
                return `${params.data[field].replace('.', ',')} ${CURRENCY}`;
            case TABLE_FIELDS.product:
                return params.data[field].name;
            case TABLE_FIELDS.orderStatus:
                return t(ORDER_STATUS[params.data.status]);
            case TABLE_FIELDS.type:
                return t(
                    camelCase(logActivityTypes?.object[params.data[field]]),
                    logActivityTypes?.object[params.data[field]]
                );
            default:
                return params.data[field];
        }
    };

    const editDataFromTable = (itemId, dataId) => {
        switch (dataType) {
            case DATA_TYPES.section:
                return navigate(getSectionsLink(dataId, itemId));
            case DATA_TYPES.groupParticipant:
                return navigate(getGroupParticipantsLink(dataId, itemId));
            default:
                return navigate(getEditLink(tableName, itemId));
        }
    };

    const deleteDataFromTable = (itemId) => deleteTableData(apiUrl ?? dataType, itemId);

    const redirectPage = (data) => () => redirectToSubpage(data);

    const redirectToSubpage = (data) => {
        switch (dataType) {
            case DATA_TYPES.question:
                return navigate(getShowLink(NAV_LINKS.questions, data.id));
            case DATA_TYPES.participantGroup:
                return navigate(getGroupParticipantsLink(data.id));
            case DATA_TYPES.category:
                return navigate(getSectionsLink(data.id));
            case DATA_TYPES.package:
                return {
                    quiz: () => navigate(getQuizLink(data.id)),
                    training: () => navigate(getTrainingLink(data.id)),
                };
            case DATA_TYPES.product:
                return () => navigate(getShowLink(NAV_LINKS.products, data.id));
            case DATA_TYPES.organization:
                return () => navigate(getOrganizationPackagesLink(data.id));
            default:
                return '';
        }
    };

    const getCellWidth = (field) => {
        switch (field) {
            case TABLE_FIELDS.id:
                return COLUMN_WIDTHS.id;
            case TABLE_FIELDS.image:
                return COLUMN_WIDTHS.image;
            case TABLE_FIELDS.price:
                return COLUMN_WIDTHS.price;
            case TABLE_FIELDS.orderStatus:
                return COLUMN_WIDTHS.orderStatus;
            case TABLE_FIELDS.createdAt:
                return COLUMN_WIDTHS.date;
            case TABLE_FIELDS.type:
                return COLUMN_WIDTHS.type;
            case TABLE_FIELDS.user:
                return COLUMN_WIDTHS.user;
            default:
                return null;
        }
    };

    const getCellStyle = (params, head) => {
        switch (head.field) {
            case TABLE_FIELDS.status:
                return setCellStyle(params);
            default:
                return {};
        }
    };

    const isScoringTable = (field) => Object.values(TABLE_FIELDS.difficultyLevels).includes(field);

    const generateColumnDefs = () => {
        const colDefs = tableHead.map((head) => ({
            field: head.field,
            headerName: t(head.headerName ?? head.field),
            minWidth: getCellWidth(head.field),
            maxWidth: getCellWidth(head.field),
            headerTooltip: t(head.headerName ?? head.field),
            tooltipValueGetter: (params) => getTooltipValue(params, head.field),
            valueGetter: (params) => getCellValue(params, head.field),
            filterParams: () => getFilter(head.field),
            comparator: getComparator(),
            cellStyle: (params) => getCellStyle(params, head),
            filter:
                !isScoringTable(head.field) &&
                head.field !== TABLE_FIELDS.creationDate &&
                head.field !== TABLE_FIELDS.round &&
                head.field !== TABLE_FIELDS.image &&
                head.field !== TABLE_FIELDS.link &&
                head.field !== TABLE_FIELDS.price,
            sortable:
                !isScoringTable(head.field) &&
                head.field !== TABLE_FIELDS.organizations &&
                head.field !== TABLE_FIELDS.participantGroups &&
                head.field !== TABLE_FIELDS.status &&
                head.field !== TABLE_FIELDS.grades &&
                head.field !== TABLE_FIELDS.image &&
                head.field !== TABLE_FIELDS.orderStatus &&
                head.field !== TABLE_FIELDS.round &&
                head.field !== TABLE_FIELDS.difficulty &&
                head.field !== TABLE_FIELDS.link,
            ...(head.field === TABLE_FIELDS.image && {
                cellRenderer: MediaImage,
                cellRendererParams: (params) => ({
                    src: params.data.image,
                }),
                cellStyle: setImageCell,
            }),
            ...(isScoringTable(head.field) && {
                cellRenderer: PointsPill,
                cellRendererParams: (params) => ({
                    data: params.data,
                    field: params.colDef.field,
                }),
                cellStyle: null,
            }),
            ...(head.field === TABLE_FIELDS.link && {
                cellStyle: setCenterCellText,
            }),
        }));

        colDefs.unshift({
            field: TABLE_FIELDS.ordinaryNumber,
            headerName: t(camelCase(TABLE_FIELDS.ordinaryNumber)),
            valueGetter: (params) => params.node.rowIndex + 1,
            minWidth: COLUMN_WIDTHS.ordinaryNumber.small,
            maxWidth: COLUMN_WIDTHS.ordinaryNumber.small,
            pinned: 'left',
            sortable: false,
            filter: false,
            ...(dataType === DATA_TYPES.media && {
                cellStyle: setCenterCellText,
            }),
        });

        const isTableMenuVisible = () =>
            (permissions?.manage &&
                dataType !== DATA_TYPES.quizScoring &&
                dataType !== DATA_TYPES.logActivity &&
                dataType !== DATA_TYPES.quizResults &&
                dataType !== DATA_TYPES.order) ||
            (permissions?.view && dataType === DATA_TYPES.organization) ||
            (permissions?.view && dataType === DATA_TYPES.product) ||
            (permissions?.manage && dataType === DATA_TYPES.order && user?.role === USER_ROLES.superAdmin);

        isTableMenuVisible() &&
            colDefs.push({
                field: TABLE_FIELDS.tableMenu,
                headerName: '',
                cellRenderer: TableMoreMenu,
                cellRendererParams: (params) => ({
                    dataType,
                    dataId: params.data.id,
                    permissions,
                    redirectToSubpage: redirectPage(params.data),
                    showModal: () => showModal(params),
                    editData: () => editDataFromTable(params.data.id, dataId),
                    deleteData: () =>
                        setDeleteModal({
                            open: true,
                            action: () => deleteElement(params),
                            data: params.data,
                        }),
                    importFromFile: (e) => uploadFile(e, params),
                    exportToFile: () => exportToPdf(params),
                    addImage: () => {
                        editorContext.handleAddImage({ src: params.data.link });
                    },
                    isAddImage: !!editorContext?.handleAddImage,
                    copyLink: () => copyLink(params.data.link),
                }),
                maxWidth: COLUMN_WIDTHS.tableMenu,
                minWidth: COLUMN_WIDTHS.tableMenu,
                cellStyle: setCenterCell,
                sortable: false,
                filter: false,
            });

        return colDefs;
    };

    const showModal = (params) => {
        setModal({ open: true, data: params.data });
    };

    const hideModal = () => {
        setModal({ open: false, data: null });
    };

    const copyLink = (link) => {
        navigator.clipboard.writeText(link);
        dispatch(openAlertSuccess('copyLinkInfo'));
    };

    const deleteElement = (params) => {
        switch (dataType) {
            case DATA_TYPES.media:
                return changeMedia(MANAGE_DATA.delete, params.data.id);
            case DATA_TYPES.question:
                return handleDeleteQuestion(params.data.id, params.data);
            default:
                return deleteDataFromTable(params.data.id);
        }
    };

    const setCellStyle = (params) => (params.value === t(TEST_STATUS.finished) ? { color: 'green' } : {});

    const isPaginationEnabled = () =>
        dataType !== DATA_TYPES.media &&
        dataType !== DATA_TYPES.quizScoring &&
        dataType !== DATA_TYPES.groupParticipant;

    const uploadFile = (e, params) => {
        const { data, api } = params;
        api.showLoadingOverlay();
        const payload = {
            file: e.target.files[0],
            group_id: data.id,
            organization_id: data.organization_id,
        };

        importUserParticipants(payload)
            .then((res) => {
                if (res.success) {
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => dispatch(openAlertError()))
            .finally(() => api.hideOverlay());
    };

    const exportToPdf = (params) => {
        const { data, api } = params;

        api.showLoadingOverlay();

        switch (dataType) {
            case DATA_TYPES.participantGroup:
                exportUserParticipants(data?.id)
                    .then((res) => {
                        if (res?.error) {
                            dispatch(openAlertError(res.error));
                        } else {
                            const { organization_name: organizationName, group_name: groupName, data } = res;

                            const fileName = `${underscoreText(organizationName)}-${underscoreText(
                                groupName
                            )}-${underscoreText(formatDate(Date.now()))}`;

                            generatePdfFile(
                                fileName,
                                <ParticipantsPdf
                                    group={`${organizationName} - ${groupName}`}
                                    translate={t}
                                    data={data}
                                />
                            )
                                .then((res) => {
                                    if (res) {
                                        dispatch(openAlertSuccess('groupExportSuccess'));
                                    } else {
                                        dispatch(openAlertError('groupExportError'));
                                    }
                                })
                                .catch(() => dispatch(openAlertError('groupExportError')))
                                .finally(() => {
                                    api.hideOverlay();
                                });
                        }
                    })
                    .catch(() => dispatch(openAlertError()));
                break;
            case DATA_TYPES.quizResults:
                const organizationName = organizations.filter((org) => org.id === values?.organization)[0]
                    ?.short_name;

                const fileName = `${data?.first_name}_${data?.last_name}-${underscoreText(
                    organizationName
                )}-${String(values?.month).padStart(2, '0')}-${values?.year}`;

                generatePdfFile(fileName, <ParticipantResultPdf data={data} />)
                    .then((res) => {
                        if (res) {
                            dispatch(openAlertSuccess('resultExportSuccess'));
                        } else {
                            dispatch(openAlertError('resultExportError'));
                        }
                    })
                    .catch(() => dispatch(openAlertError('resultExportError')))
                    .finally(() => api.hideOverlay());
                break;
            default:
                api.hideOverlay();
        }
    };

    const transformFiltersToUrlParams = (filters) => new URLSearchParams(filters).toString();

    const getMedia = () =>
        getData(DATA_TYPES.media, ALL_DATA)
            .then((res) => {
                if (res.data) {
                    setTableData(
                        res.data.map((item) => ({
                            id: item,
                            image: getImageLink(item),
                            link: getImageLink(item),
                        }))
                    );
                } else {
                    setTableData([]);
                }
            })
            .catch(() => dispatch(openAlertError()))
            .finally(() => setIsFetchingData(false));

    const getTableData = (page, paginationSize, filters) => {
        setIsFetchingData(true);
        const filterParams = transformFiltersToUrlParams(filters);
        switch (dataType) {
            case DATA_TYPES.groupParticipant:
                return getParticipantsByGroup(dataId, filterParams)
                    .then((res) => {
                        if (res.error) {
                            navigate(NAV_LINKS.notFound);
                        } else {
                            setItemName(`${res.group_organization_name} / ${res.participant_group_name}`);
                            setTableData(res.data);
                        }
                    })
                    .catch(() => dispatch(openAlertError()))
                    .finally(() => setIsFetchingData(false));
            case DATA_TYPES.section:
                return getSectionsByCategory(dataId, paginationSize, filterParams)
                    .then((res) => {
                        if (res.error) {
                            navigate(NAV_LINKS.notFound);
                        } else {
                            setItemName(res.category.name);
                            setTableData(res.data.data);
                            setTotalCount(res.data.total);
                            setTotalPages(res.data.last_page);
                        }
                    })
                    .catch(() => dispatch(openAlertError()))
                    .finally(() => setIsFetchingData(false));
            case DATA_TYPES.quiz:
                return (async () => {
                    try {
                        const quizList = await getQuestionList(
                            PACKAGE_PARTS.quiz,
                            dataId,
                            paginationSize,
                            page,
                            filterParams
                        );

                        if (quizList.error) {
                            navigate(NAV_LINKS.notFound);
                            return;
                        }

                        setItemName(quizList?.package_name);
                        setTableData(quizList.data.data.map((item) => item.question));
                        setTotalCount(quizList.data.total);
                        setTotalPages(quizList.data.last_page);
                    } catch (error) {
                        console.error(error);
                        dispatch(openAlertError());
                        navigate(NAV_LINKS.notFound);
                    } finally {
                        setIsFetchingData(false);
                    }
                })();
            case DATA_TYPES.training:
                return (async () => {
                    try {
                        const trainingList = await getQuestionList(
                            PACKAGE_PARTS.training,
                            dataId,
                            paginationSize,
                            page,
                            filterParams
                        );

                        if (trainingList.error) {
                            navigate(NAV_LINKS.notFound);
                            return;
                        }

                        setItemName(trainingList?.package_name);
                        setTableData(trainingList.data.data.map((item) => item.question));
                        setTotalCount(trainingList.data.total);
                        setTotalPages(trainingList.data.last_page);
                    } catch (error) {
                        console.error(error);
                        dispatch(openAlertError());
                        navigate(NAV_LINKS.notFound);
                    } finally {
                        setIsFetchingData(false);
                    }
                })();
            case DATA_TYPES.media:
                return getMedia();
            case DATA_TYPES.quizScoring:
                return getData(DATA_TYPES.quizScoring)
                    .then((res) => {
                        if (res.error) {
                            navigate(NAV_LINKS.notFound);
                        } else {
                            const data = Object.values(res.data).map((round, index) => {
                                const roundIndex = index + 1;
                                const roundName = `${roundIndex}${
                                    ROUNDS_INFO[roundIndex] ? ` (${t(ROUNDS_INFO[roundIndex])})` : ``
                                }`;

                                const renamedDiff = renameDifficulty(round);
                                if (!('gain' in renamedDiff)) {
                                    return {
                                        round: roundName,
                                        gain: renamedDiff,
                                    };
                                }
                                return { round: roundName, ...renamedDiff };
                            });
                            setTableData(data);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        dispatch(openAlertError());
                    })
                    .finally(() => setIsFetchingData(false));
            case DATA_TYPES.quizResults:
                return null;
            default:
                return getData(dataType, page, paginationSize, filterParams)
                    .then((res) => {
                        if (res.error) {
                            handleError(res.error);
                        } else {
                            const data = handleResponse(res);
                            setTableData(data);
                            setTotalCount(res.total);
                            setTotalPages(res.last_page);
                        }
                    })
                    .catch(() => dispatch(openAlertError()))
                    .finally(() => setIsFetchingData(false));
        }
    };

    const handleResponse = (res) =>
        dataType === DATA_TYPES.order
            ? res.data.map((data) => ({
                  ...data,
                  [TABLE_FIELDS.orderStatus]: ORDER_STATUS[data.status],
              }))
            : res.data;

    const deleteTableData = async (type, id, callback) => {
        try {
            const res = await deleteData(type.includes(DATA_TYPES.user) ? DATA_TYPES.user : type, id);
            callback && (await callback(res));

            if (res.error) {
                throw new Error(res.error);
            }

            dispatch(openAlertSuccess(res.success));
            setTotalCount((totalCount) => totalCount - 1);
            if (pageNumber === 1) {
                getTableData(pageNumber, paginationPageSize, filters);
            } else {
                moveToFirstPage();
            }
        } catch (error) {
            const handleCascadeDeleteError = (errorMessage) =>
                errorMessage.includes('SQLSTATE[23000]') ? 'responseError.cascadeDelete' : errorMessage;

            console.error(error);
            dispatch(openAlertError(handleCascadeDeleteError(error.message)));
        }
    };

    const setImageCell = () => ({ ...setCenterCell, padding: 6 });

    const setCenterCellText = () => ({
        display: 'flex',
        alignItems: 'center',
    });

    const handleDeleteQuestion = async (id, modalData) => {
        try {
            await deleteTableData(DATA_TYPES.question, id, (res) => {
                if (res.error) {
                    if (res.data) {
                        setModal({ open: true, data: modalData });
                        throw new Error(res.error);
                    }
                    throw new Error(res.error);
                }
                setModal({ open: false, data: null });
            });
        } catch (error) {
            console.error(error);
            dispatch(openAlertError(error.message));
        }
    };

    const handleOrderChange = async (formEvent, formMethods) => {
        const { setSubmitting } = formMethods;

        if (modal.open) {
            try {
                const res = await updateData('commerce/order/status', modal.data.id, formEvent);
                if (res.error) throw new Error(res.error);
                dispatch(openAlertSuccess());
                setTableData((prev) =>
                    prev.map((item) =>
                        item.id === modal.data.id ? { ...item, status: formEvent.status } : item
                    )
                );
            } catch (error) {
                console.error(error);
                dispatch(openAlertError());
            } finally {
                setSubmitting(false);
                hideModal();
            }
        }
    };

    const getAction = () => {
        switch (dataType) {
            case DATA_TYPES.question:
                return handleDeleteQuestion;
            case DATA_TYPES.order:
                return handleOrderChange;
            default:
                return () => console.warn('No action for this data type.');
        }
    };

    const handleError = (errorMessage) => {
        if (camelCase(errorMessage) === HTTP_ERRORS.unauthorized.text) {
            navigate(NAV_LINKS.unauthorized, { replace: true });
        } else {
            dispatch(openAlertError(errorMessage));
            setTableData([]);
            setModal((prev) => ({ ...prev, open: false }));
        }
    };

    const moveToNextPage = () => {
        isFrontendFilter() && gridApi?.paginationGoToNextPage();
        setPageNumber((pageNumber) => (pageNumber < totalPages ? pageNumber + 1 : totalPages));
    };

    const moveToPreviousPage = () => {
        isFrontendFilter() && gridApi?.paginationGoToPreviousPage();
        setPageNumber((pageNumber) => (pageNumber > 1 ? pageNumber - 1 : 1));
    };

    const moveToLastPage = () => {
        isFrontendFilter() && gridApi?.paginationGoToLastPage();
        setPageNumber(totalPages);
    };

    const moveToFirstPage = () => {
        isFrontendFilter() && gridApi?.paginationGoToFirstPage();
        setPageNumber(1);
    };

    useEffect(() => {
        fetchAdditionalData();
    }, []);

    useEffect(() => {
        if (dataType === DATA_TYPES.quizResults) {
            getQuiz(dataId, elementId)
                .then((res) => {
                    if (res.error) {
                        navigate(NAV_LINKS.notFound);
                    } else {
                        setItemName(res?.data?.package?.name);
                    }
                })
                .catch(() => dispatch(openAlertError()))
                .finally(() => setIsFetchingInitial(false));
        }
    }, []);

    useEffect(() => {
        dataType !== DATA_TYPES.quizResults && getTableData(pageNumber, paginationPageSize, filters);
    }, [pageNumber, paginationPageSize, filters]);

    useEffect(() => {
        setPageNumber(1);
    }, [paginationPageSize]);

    useEffect(() => {
        if (location?.state?.isSuccess) {
            typeof window !== 'undefined' && window.history.replaceState(null, '');
        }
    }, [location]);

    useEffect(() => {
        if (gridApi && isFrontendFilter()) {
            setTotalPages(gridApi.paginationGetTotalPages());
            setTotalCount(gridApi.paginationGetRowCount());
        }
    }, [tableData, paginationPageSize, gridApi]);

    useEffect(() => {
        if (gridApi) {
            if (columnDefs.length) {
                if (currentBrowserWidth >= DESKTOP_WIDTH) {
                    gridApi.sizeColumnsToFit();
                } else {
                    setTimeout(() => {
                        isTableContentFit() && gridApi.sizeColumnsToFit();
                    }, 100);
                }
            }
        }
    }, [gridApi, columnDefs, currentBrowserWidth]);

    useEffect(() => {
        if (gridApi) {
            if (isFetchingData || isFetchingAdditionalData) {
                gridApi.showLoadingOverlay();
            } else if (!tableData.length) {
                gridApi.showNoRowsOverlay();
            } else {
                gridApi.hideOverlay();
            }
        }
    }, [gridApi, tableData, isFetchingData, isFetchingAdditionalData]);

    const fetchAdditionalData = () => {
        if (
            dataType === DATA_TYPES.question ||
            dataType === DATA_TYPES.section ||
            dataType === DATA_TYPES.package ||
            dataType === DATA_TYPES.quiz ||
            dataType === DATA_TYPES.quizResults ||
            dataType === DATA_TYPES.training ||
            dataType === DATA_TYPES.logActivity
        ) {
            setIsFetchingAdditionalData(true);
            switch (dataType) {
                case DATA_TYPES.logActivity:
                    return getLogActivityTypes()
                        .then((res) => {
                            if (res.error) {
                                dispatch(openAlertError());
                            } else {
                                setLogActivityTypes({
                                    array: res,
                                    object: Object.fromEntries(
                                        res.map((activityType) => [activityType.id, activityType.type])
                                    ),
                                });
                            }
                        })
                        .catch(() => dispatch(openAlertError()))
                        .finally(() => setIsFetchingAdditionalData(false));
                case DATA_TYPES.quizResults:
                    return getData(DATA_TYPES.organization, ALL_DATA)
                        .then((res) => {
                            if (res.error) {
                                handleError(res.error);
                            } else {
                                setOrganizations(res.data);
                            }
                        })
                        .catch(() => dispatch(openAlertError()))
                        .finally(() => setIsFetchingAdditionalData(false));
                default:
                    return Promise.all([
                        getData(DATA_TYPES.category, ALL_DATA),
                        getData(DATA_TYPES.grade, ALL_DATA),
                    ])
                        .then(([categoriesRes, gradesRes]) => {
                            if (categoriesRes.error || gradesRes.error) {
                                dispatch(openAlertError());
                            } else {
                                setCategories(categoriesRes.data);
                                setGrades(gradesRes.data);
                            }
                        })
                        .catch(() => dispatch(openAlertError()))
                        .finally(() => setIsFetchingAdditionalData(false));
            }
        }
    };

    const changePaginationSize = (e) => {
        isFrontendFilter() && gridApi?.paginationSetPageSize(+e.target.value);
        setPaginationPageSize(+e.target.value);
    };

    const isFrontendFilter = () =>
        dataType === DATA_TYPES.quizResults &&
        values?.year === currentDate.getFullYear() &&
        values?.month === currentDate.getMonth() + 1;

    const getComparator = () => (isFrontendFilter() ? '' : () => 0);

    const getTextMatcher = () => (isFrontendFilter() ? '' : () => true);

    const getFilter = (field) => {
        switch (field) {
            case TABLE_FIELDS.sex:
                return selectFilter(field, Object.keys(USER_SEX));
            case TABLE_FIELDS.role:
                return selectFilter(field, USER_ORGANIZATIONS_ROLES);
            case TABLE_FIELDS.difficulty:
                return selectFilter(field, QUESTIONS_DIFFICULTY);
            case TABLE_FIELDS.category:
                return selectFilter(field, categories);
            case TABLE_FIELDS.grade:
                return selectFilter(field, grades);
            case TABLE_FIELDS.status:
                return selectFilter(field, TEST_STATUSES);
            case TABLE_FIELDS.orderStatus:
                return selectFilter(field, LIST_ORDER_STATUS);
            case TABLE_FIELDS.type:
                return selectFilter(field, logActivityTypes?.array);
            default:
                return {
                    suppressAndOrCondition: true,
                    textMatcher: getTextMatcher(),
                    filterOptions: ['contains'],
                    buttons: ['reset'],
                };
        }
    };

    const selectFilter = (field, data) => {
        const getDisplayKey = (item) => {
            switch (field) {
                case TABLE_FIELDS.sex:
                    return USER_SEX[item];
                case TABLE_FIELDS.role:
                    return USER_ROLES[item];
                case TABLE_FIELDS.status:
                    return item;
                case TABLE_FIELDS.orderStatus:
                    return ORDER_STATUS_NAMES[item];
                default:
                    return Object.keys(item).length ? item?.id : item;
            }
        };

        const getDisplayName = (item) => {
            switch (field) {
                case TABLE_FIELDS.organizations:
                case TABLE_FIELDS.organization:
                case TABLE_FIELDS.organizationName:
                    return item.short_name;
                case TABLE_FIELDS.participantGroups:
                case TABLE_FIELDS.participantGroupUser:
                case TABLE_FIELDS.category:
                case TABLE_FIELDS.grade:
                    return item.name;
                case TABLE_FIELDS.difficulty:
                    return t(getQuestionDifficultyName(item));
                case TABLE_FIELDS.type:
                    return t(camelCase(item.type), item.type);
                default:
                    return t(item);
            }
        };

        const filterData = data.map((item) => ({
            displayKey: getDisplayKey(item),
            displayName: getDisplayName(item),
            predicate: () => true,
            numberOfInputs: 0,
        }));

        return {
            buttons: ['reset'],
            closeOnApply: true,
            suppressAndOrCondition: true,
            filterOptions: isMultiSelectField(field)
                ? [
                      TABLE_FILTERS.empty,
                      {
                          displayKey: TABLE_FILTERS.contains,
                          displayName: TABLE_FILTERS.contains,
                          predicate: () => true,
                      },
                      ...filterData,
                  ]
                : [TABLE_FILTERS.empty, ...filterData],
        };
    };

    const isQuizTrainingType = () => dataType === DATA_TYPES.quiz || dataType === DATA_TYPES.training;

    const changeKeys = (filterType, key, tableOption) => {
        switch (key) {
            case TABLE_FIELDS.organizations:
            case TABLE_FIELDS.organization:
            case TABLE_FIELDS.organizationName:
                return FILTER_FIELDS.organizationShortName;
            case TABLE_FIELDS.participantGroups:
            case TABLE_FIELDS.participantGroupUser:
                return FILTER_FIELDS.groupName;
            case TABLE_FIELDS.category:
                return FILTER_FIELDS.category[filterType];
            case TABLE_FIELDS.grade:
            case TABLE_FIELDS.grades:
                return FILTER_FIELDS.grade[filterType];
            case TABLE_FIELDS.user:
                return FILTER_FIELDS.userFullName;
            case TABLE_FIELDS.createdAt:
                if (tableOption === TABLE_OPTIONS.filter) {
                    return FILTER_FIELDS.createdAt;
                }
                return TABLE_FIELDS.id;
            case TABLE_FIELDS.section:
                return FILTER_FIELDS.sectionTitle;
            case TABLE_FIELDS.content:
                return isQuizTrainingType() ? FILTER_FIELDS.questionContent : TABLE_FIELDS.content;
            case TABLE_FIELDS.product:
                return FILTER_FIELDS.productName;
            case TABLE_FIELDS.orderStatus:
                return FILTER_FIELDS.orderStatus;
            default:
                return key;
        }
    };

    const checkFilterType = (v) => {
        switch (dataType) {
            case DATA_TYPES.orderStatus:
            case DATA_TYPES.quiz:
            case DATA_TYPES.training:
                return v?.filter ? FILTER_TYPES.question : FILTER_TYPES.id;
            default:
                return v?.filter ? FILTER_TYPES.name : FILTER_TYPES.id;
        }
    };

    const onFilterChanged = () => {
        const filters = mapKeys(gridApi.getFilterModel(), (v, k) =>
            camelCase(changeKeys(checkFilterType(v), k, TABLE_OPTIONS.filter))
        );
        setFilters(
            Object.fromEntries(
                Object.keys(filters).map((field) => [field, filters[field].filter ?? filters[field].type])
            )
        );

        setPageNumber(1);
    };

    const onSortChanged = (e) => {
        const sortedColumn = e.columnApi.getColumnState().filter((column) => column.sort)[0];

        if (sortedColumn) {
            setFilters((filters) => ({
                ...filters,
                orderBy: camelCase(changeKeys(FILTER_TYPES.name, sortedColumn.colId, TABLE_OPTIONS.sort)),
                direction: sortedColumn.sort.toUpperCase(),
            }));
        } else {
            const currentFilters = { ...filters };
            delete currentFilters.orderBy;
            delete currentFilters.direction;
            setFilters(currentFilters);
        }
    };

    const navigateToAddForm = () => {
        switch (dataType) {
            case DATA_TYPES.section:
                return navigate(`${NAV_LINKS.categories}/${dataId}/sections/add`);
            case DATA_TYPES.quiz:
                return navigate(`${NAV_LINKS.packages}/${dataId}/quiz/questions`);
            case DATA_TYPES.training:
                return navigate(`${NAV_LINKS.packages}/${dataId}/training/questions`);
            default:
                return navigate(`/${kebabCase(tableName)}/add`);
        }
    };

    const getTableName = () => {
        if (!itemName) return t(tableName);

        switch (dataType) {
            case DATA_TYPES.groupParticipant:
                return `${t(itemName)} - ${t('participants')}`;
            case DATA_TYPES.section:
                return `${t(itemName)} - ${t('sections')}`;
            case DATA_TYPES.quiz:
                return `${t(itemName)} - ${t('quiz')}`;
            case DATA_TYPES.training:
                return `${t(itemName)} - ${t('training')}`;
            case DATA_TYPES.quizResults:
                return `${t(itemName)} - ${t('quizResults')}`;
            default:
                return t(tableName);
        }
    };

    const isMultiSelectField = (field) => field === TABLE_FIELDS.category || field === TABLE_FIELDS.grade;

    const onFilterOpened = (e) => {
        if (isMultiSelectField(e.column.colId)) {
            setIsMultiSelect(true);
        } else {
            setIsMultiSelect(false);
        }
    };

    const isContainerWithButtons = () =>
        dataType === DATA_TYPES.groupParticipant ||
        dataType === DATA_TYPES.section ||
        dataType === DATA_TYPES.quiz ||
        dataType === DATA_TYPES.quizResults ||
        dataType === DATA_TYPES.training;

    const gridRef = useRef(null);

    const getTableSettings = () => {
        switch (dataType) {
            case DATA_TYPES.groupParticipant:
                return {
                    height: '90%',
                    width: '100%',
                    buttonsPosition: '1.5rem',
                };
            case DATA_TYPES.quizScoring:
                if (DESKTOP_WIDTH > currentBrowserWidth) {
                    return {
                        height: 'auto',
                        width: '90%',
                        buttonsPosition: '1.5rem',
                    };
                }
                return {
                    height: 'auto',
                    width: '70%',
                    buttonsPosition: '1.5rem',
                };
            case DATA_TYPES.section:
            case DATA_TYPES.quiz:
            case DATA_TYPES.training:
            case DATA_TYPES.quizResults:
                return {
                    height: '85%',
                    width: '100%',
                    buttonsPosition: '4.5rem',
                };
            default:
                return {
                    height: '100%',
                    width: '100%',
                    buttonsPosition: '',
                };
        }
    };

    const changeMedia = (type, media) =>
        manageMedia(type, media)
            .then((res) => {
                if (res.success) {
                    getMedia();
                    dispatch(openAlertSuccess(`${type}${MEDIA_ALERTS.success}`));
                } else {
                    dispatch(openAlertError(res));
                }
            })
            .catch(() => dispatch(openAlertError(`${type}${MEDIA_ALERTS.error}`)));

    const getButtonTitle = () => {
        switch (dataType) {
            case DATA_TYPES.quiz:
            case DATA_TYPES.training:
                return 'changeQuestions';
            default:
                return '';
        }
    };

    const navigateToQuizParameters = () => navigate(getQuizParametersLink(dataId));

    const navigateToQuizResults = () => navigate(getQuizResultsLink(dataId));

    useEffect(() => {
        if (!tableData.length) return;
        setColumnDefs(generateColumnDefs());
    }, [tableData, i18n.language, categories, grades, logActivityTypes, filters, isMultiSelect, values]);

    const getSelectSettings = (field) => {
        switch (field) {
            case QUIZ_RESULTS_CONFIG.organization:
                return {
                    values: organizations,
                    width: 400,
                };
            case QUIZ_RESULTS_CONFIG.year:
                return { values: years, width: 175 };
            case QUIZ_RESULTS_CONFIG.month:
                return {
                    values: months,
                    width: 200,
                };
            default:
                return {
                    values: [],
                    type: '',
                };
        }
    };

    const generateSelectFields = () =>
        Object.keys(QUIZ_RESULTS_CONFIG).map((field) => (
            <SelectField
                loading={field === QUIZ_RESULTS_CONFIG.organization && isFetchingAdditionalData}
                key={field}
                field={field}
                label={t(field)}
                values={values}
                selectValues={getSelectSettings(field)?.values}
                handleChange={(e, value) => {
                    setFieldValue(field, value?.id ?? '');
                    setFieldTouched(field, true, false);
                }}
                handleBlur={handleBlur}
                autocomplete
                errors={errors}
                touched={touched}
                maxWidth={getSelectSettings(field)?.width}
                multiple={false}
            />
        ));

    const resetTableData = () => {
        setTableData([]);
        setTotalPages(0);
        setTotalCount(0);
    };

    const fetchQuizResults = () => {
        setIsFetchingData(true);
        getQuizResults(dataId, values, pageNumber, paginationPageSize, transformFiltersToUrlParams(filters))
            .then((res) => {
                if (res.error) {
                    dispatch(openAlertError());
                } else {
                    setTableData(res.data);
                    if (res?.total && res?.last_page) {
                        setTotalCount(res?.total);
                        setTotalPages(res?.last_page);
                    }
                }
            })
            .catch(() => dispatch(openAlertError()))
            .finally(() => setIsFetchingData(false));
    };

    const isDataValid = () =>
        isValid && Object.values(values).every((val) => val !== '') && Object.keys(touched).length;

    useEffect(() => {
        if (isDataValid()) {
            resetTableData();
            setTableKey((key) => key + 1);
            fetchQuizResults();
        }
    }, [dataId, values, isValid]);

    useEffect(() => {
        if (isDataValid() && !isFrontendFilter()) {
            fetchQuizResults();
        }
    }, [pageNumber, paginationPageSize, filters]);

    const getText = (dataType, data) =>
        dataType === DATA_TYPES.administrator ||
        dataType === DATA_TYPES.participant ||
        dataType === DATA_TYPES.organizationUser
            ? t(data?.email ? 'userDeleteTextIdEmail' : 'userDeleteTextId', {
                  id: data?.id,
                  email: data?.email,
              })
            : t('elementDeleteText', { id: data?.id });

    return isFetchingInitial ? (
        <LoadingOverlay fullScreen />
    ) : (
        <>
            <ConfirmationModal
                title={t('elementDelete')}
                text={getText(dataType, deleteModal?.data)}
                open={deleteModal?.open}
                action={deleteModal?.action}
                handleClose={() => setDeleteModal({ open: false, action: null, data: null })}
            />
            <TableContainer withPagination={dataType !== DATA_TYPES.groupParticipant}>
                <TableModal
                    dataType={dataType}
                    modalData={modal.data}
                    open={modal.open}
                    handleClose={hideModal}
                    handleError={handleError}
                    handleAction={getAction()}
                />
                {dataType === DATA_TYPES.media ? (
                    <TableNameContainer>
                        <TableName>{t('imageGallery')}</TableName>
                    </TableNameContainer>
                ) : (
                    <SectionTitle tableMargin withMargin={!permissions?.manage} title={getTableName()} />
                )}
                <TableButtonsContainer
                    justifyContent={dataType === DATA_TYPES.quizResults ? 'flex-start' : 'flex-end'}
                >
                    {permissions?.manage &&
                        dataType !== DATA_TYPES.groupParticipant &&
                        dataType !== DATA_TYPES.quizScoring &&
                        dataType !== DATA_TYPES.logActivity && (
                            <>
                                {dataType === DATA_TYPES.quizResults && (
                                    <FormikProvider value={formik}>
                                        <Form style={{ width: '100%' }}>
                                            <Stack spacing={3} display={'flex'} direction={'row'}>
                                                {generateSelectFields()}
                                            </Stack>
                                        </Form>
                                    </FormikProvider>
                                )}
                                {dataType === DATA_TYPES.quiz && (
                                    <>
                                        <AddButton
                                            title={'showResults'}
                                            onClick={navigateToQuizResults}
                                            icon={'carbon:result'}
                                        />
                                        <AddButton
                                            title={'editParameters'}
                                            onClick={navigateToQuizParameters}
                                        />
                                    </>
                                )}
                                {dataType !== DATA_TYPES.quizResults && (
                                    <AddButton
                                        title={getButtonTitle()}
                                        imageInput={dataType === DATA_TYPES.media}
                                        icon={
                                            dataType === DATA_TYPES.quiz || dataType === DATA_TYPES.training
                                                ? 'ic:baseline-change-circle'
                                                : null
                                        }
                                        onClick={
                                            dataType === DATA_TYPES.media
                                                ? (e) => changeMedia(MANAGE_DATA.upload, e.target.files[0])
                                                : navigateToAddForm
                                        }
                                    />
                                )}
                            </>
                        )}
                </TableButtonsContainer>
                <TableWrapper
                    className="ag-theme-material"
                    isMultiSelect={isMultiSelect}
                    height={getTableSettings().height}
                    width={getTableSettings().width}
                    showOverlay={!isFetchingData && !isFetchingAdditionalData}
                    dataType={dataType}
                >
                    <AgGridReact
                        key={tableKey}
                        defaultColDef={{
                            resizable: true,
                            autoHeight: true,
                            filterParams: {
                                buttons: ['reset'],
                                closeOnApply: true,
                            },
                        }}
                        onSortChanged={!isFrontendFilter() && onSortChanged}
                        onFilterChanged={!isFrontendFilter() && onFilterChanged}
                        onFilterOpened={!isFrontendFilter() && onFilterOpened}
                        rowData={tableData}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        tooltipShowDelay={0}
                        localeText={AG_GRID_LOCALE[i18n.language]}
                        rowDragManaged
                        animateRows
                        suppressDragLeaveHidesColumns
                        loadingOverlayComponent={LoadingOverlay}
                        domLayout={dataType === DATA_TYPES.quizScoring ? 'autoHeight' : 'normal'}
                        ref={gridRef}
                        {...(dataType === DATA_TYPES.quizResults && {
                            pagination: true,
                            paginationPageSize,
                            suppressPaginationPanel: true,
                        })}
                    />
                    {isPaginationEnabled() && (
                        <PaginationPanel
                            paginationSizes={PAGINATION_SIZES}
                            paginationPageSize={paginationPageSize}
                            changePaginationSize={changePaginationSize}
                            totalCount={totalCount}
                            pageNumber={pageNumber}
                            totalPages={totalPages}
                            moveToNextPage={moveToNextPage}
                            moveToPreviousPage={moveToPreviousPage}
                            moveToLastPage={moveToLastPage}
                            moveToFirstPage={moveToFirstPage}
                        />
                    )}
                    {isContainerWithButtons() && (
                        <ButtonsContainer buttonsPosition={getTableSettings().buttonsPosition}>
                            <FormButtons
                                formType={FORM_TYPES.show}
                                navLink={getNavLink(dataType)}
                                isFormValid
                            />
                        </ButtonsContainer>
                    )}
                </TableWrapper>
            </TableContainer>
        </>
    );
};

Table.propTypes = {
    apiUrl: PropTypes.string,
    tableName: PropTypes.string.isRequired,
    tableHead: PropTypes.array.isRequired,
    dataType: PropTypes.string.isRequired,
};

export default Table;
