import { Stack, TextField } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import {
    DATA_TYPES,
    DIFFICULTIES,
    FORM_TYPES,
    NAV_LINKS,
    NUMBER_REGEX,
    ROUNDS_INFO,
} from '../../../utils/constants';
import { getDataById, updateData } from '../../../utils/fetchData';
import { getObjectDiff, getQuizLink } from '../../../utils/helpers';
import Form from '../../Form/Form';
import FormButtons from '../../Form/FormButtons/FormButtons';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';
import { Block, ParamsBlock, ParamsText } from './QuizParametersForm.styles';

const QuizParametersForm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState([]);
    const [validationSchema, setValidationSchema] = useState();
    const [quizId, setQuizId] = useState();
    const [packageName, setPackageName] = useState('');
    const { id: packageId } = useParams();

    useEffect(() => {
        getDataById(DATA_TYPES.quizParameters, packageId)
            .then((res) => {
                if (res.error) {
                    navigate(NAV_LINKS.notFound);
                } else {
                    setData(res.data);
                    setQuizId(res.quiz_id);
                    setPackageName(res?.package_name);
                }
            })
            .catch(() => navigate(NAV_LINKS.notFound));
    }, []);

    useEffect(() => {
        if (data?.length) {
            const quizParams = {};
            const validationParams = {};
            data.forEach((quizParam) => {
                const fieldName = getFieldName(quizParam.id);
                Object.assign(quizParams, {
                    [fieldName]: String(quizParam.quantity),
                });
                Object.assign(validationParams, {
                    [fieldName]: Yup.number()
                        .typeError(t('numberValidation'))
                        .moreThan(0, t('quantityValidation'))
                        .lessThan(1001, t('quantityValidationMax'))
                        .required(t('numberValidation')),
                });
            });
            setValues(quizParams);
            setInitialValues(quizParams);
            setValidationSchema(Yup.object().shape(validationParams));
        }
    }, [data]);

    const formik = useFormik({
        initialValues: {},
        validationSchema,
        onSubmit: (values) => updateValues(quizId, values),
    });

    const {
        errors,
        touched,
        isValid,
        values,
        setValues,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
    } = formik;

    const getFieldName = (id) => `${id}_quantity`;

    const updateValues = (id, payload) =>
        updateData(DATA_TYPES.quizParameters, id, getPayload(initialValues, payload))
            .then((res) => {
                if (res.success) {
                    navigate(getQuizLink(packageId));
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                dispatch(openAlertError());
            })
            .finally(() => setSubmitting(false));

    const getPayload = (initialPayload, payload) => {
        const changedData = getObjectDiff(initialPayload, payload);
        return {
            quantities: Object.keys(changedData).map((key) => ({
                id: +key.split('_')[0],
                quantity: +changedData[key],
            })),
        };
    };

    const isFormValid = () => isValid && Object.keys(touched).length && !isEqual(initialValues, values);

    const onChange = (...rest) => {
        const [event] = rest;
        if (NUMBER_REGEX.test(event.target.value) || !event.target.value) {
            handleChange(...rest);
        }
    };

    const renderTextFields = (round) =>
        data
            .filter((param) => param.round === round)
            .map((quizParam) => {
                const name = getFieldName(quizParam.id);
                return (
                    <TextField
                        key={name}
                        id={name}
                        name={name}
                        label={t(DIFFICULTIES[quizParam.difficulty])}
                        value={values[name] ?? ''}
                        onChange={onChange}
                        onBlur={handleBlur}
                        helperText={touched[name] && errors[name]}
                        error={Boolean(touched[name] && errors[name])}
                        onInput={() => setFieldTouched(name, true, true)}
                        sx={{ mr: 1 }}
                    />
                );
            });

    const renderTextBlocks = () =>
        [...new Set(data.map((params) => params.round))].map((round) => (
            <Block key={round}>
                <ParamsText>{`${t('round')} ${round} ${
                    ROUNDS_INFO[round] ? `(${t(ROUNDS_INFO[round])})` : ``
                }`}</ParamsText>
                <ParamsBlock>{renderTextFields(round)}</ParamsBlock>
            </Block>
        ));

    return !data.length ? (
        <LoadingOverlay fullScreen />
    ) : (
        <FormikProvider value={formik}>
            <Form
                title={`${packageName ? `${packageName} - ` : ''}${t('questionsQuantity')}`}
                onSubmit={handleSubmit}
            >
                <Stack spacing={3} m={4}>
                    {renderTextBlocks()}
                    <FormButtons
                        navLink={getQuizLink(packageId)}
                        formType={FORM_TYPES.edit}
                        isFormValid={!!isFormValid()}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                    />
                </Stack>
            </Form>
        </FormikProvider>
    );
};

export default QuizParametersForm;
