import styled from 'styled-components';

export const ModalContainer = styled.div`
    width: 60%;
    height: 80%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    padding: 2rem;
    padding-bottom: 3rem;

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        width: 80%;
    }
`;

export const ActionIcon = styled.img`
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
    top: 12px;
    right: 12px;
`;
