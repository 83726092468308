import { Modal } from '@mui/material';
import PropTypes from 'prop-types';
import { FORM_TYPES } from '../../utils/constants';
import FormButtons from '../Form/FormButtons/FormButtons';
import { ModalContainer, ModalText, ModalTitle } from './ConfirmationModal.styles';

const ConfirmationModal = ({ title, text, open, handleClose, action }) => {
    const handleAction = () => {
        action();
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <ModalContainer>
                <ModalTitle>{title}</ModalTitle>
                <ModalText>{text}</ModalText>
                <FormButtons
                    formType={FORM_TYPES.delete}
                    isFormValid
                    disabled={false}
                    onBack={handleClose}
                    onSubmit={handleAction}
                />
            </ModalContainer>
        </Modal>
    );
};

ConfirmationModal.defaultProps = {
    action: null,
};

ConfirmationModal.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    action: PropTypes.func,
};

export default ConfirmationModal;
