import { NAV_LINKS } from './constants';

const MENU_OPTIONS = [
    {
        label: 'home',
        linkTo: NAV_LINKS.dashboard,
    },
    {
        label: 'profile',
        linkTo: NAV_LINKS.userProfile,
    },
];

export default MENU_OPTIONS;
