import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parseHtml from 'html-react-parser';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { HtmlWrapper } from './HtmlPreview.styles';

const HtmlPreview = ({ editorState, width, isContainer }) => {
    const [html, setHtml] = useState('');

    useEffect(() => {
        setHtml(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }, [editorState]);

    return (
        <HtmlWrapper width={width} isContainer={isContainer}>
            {parseHtml(html)}
        </HtmlWrapper>
    );
};

HtmlPreview.propTypes = {
    editorState: PropTypes.object.isRequired,
    width: PropTypes.number,
    isContainer: PropTypes.bool,
};

export default HtmlPreview;
