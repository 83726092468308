import { Button, ClickAwayListener, IconButton, InputAdornment, TextField, useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import getNavConfig from '../../utils/navConfig';
import Iconify from '../Iconify/Iconify';
import { StyledSearchbar, StyledSlide } from './Searchbar.styles';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
    top: 0,
    left: 0,
    zIndex: 99,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: APPBAR_MOBILE,
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    padding: theme.spacing(0, 3),
    boxShadow: theme.customShadows.z8,
    backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
    [theme.breakpoints.up('md')]: {
        height: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

const Searchbar = () => {
    const [isOpen, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.userReducer);
    const navConfig = getNavConfig(user.role);
    const theme = useTheme();

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const search = () => {
        const path = navConfig.filter(
            (navItem) => t(navItem.title).toLowerCase() === searchValue.toLowerCase()
        )[0]?.path;

        if (path) {
            handleClose();
            navigate(path);
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                {!isOpen && (
                    <IconButton onClick={handleOpen}>
                        <Iconify icon="eva:search-fill" width={20} height={20} />
                    </IconButton>
                )}

                <StyledSlide direction="down" in={isOpen} mountOnEnter unmountOnExit>
                    <SearchbarStyle>
                        <StyledSearchbar
                            disableClearable
                            freeSolo
                            options={navConfig.map((navItem) => t(navItem.title))}
                            onChange={(e, value) => setSearchValue(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('search')}
                                    size={'small'}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            search();
                                        }
                                    }}
                                    onChange={({ target }) => setSearchValue(target.value)}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify
                                                    icon="eva:search-fill"
                                                    sx={{
                                                        color: 'text.disabled',
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option, inputValue, {
                                    requireMatchAll: true,
                                });
                                const parts = parse(option, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontSize: theme.typography.autocomplete.fontSize,
                                                        fontWeight: part.highlight
                                                            ? theme.typography.fontWeightBold
                                                            : theme.typography.fontWeightRegular,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                        <Button variant="contained" size="small" onClick={search}>
                            {t('search')}
                        </Button>
                    </SearchbarStyle>
                </StyledSlide>
            </div>
        </ClickAwayListener>
    );
};

export default Searchbar;
