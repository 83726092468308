import styled from 'styled-components';

const TABLE_OFFSET = 50;

export const TableContainer = styled.div`
    width: 100%;
    height: 95%;

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        width: calc(100% - ${TABLE_OFFSET}px);
        margin-left: ${TABLE_OFFSET / 2}px;
    }
`;
