import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const StyledIconButton = styled(IconButton)`
    width: 40px;
    height: 40px;

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        width: 35px;
        height: 35px;
    }
`;
