import { Stack, TextField } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { camelCase, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import { DATA_TYPES, FORM_TYPES, NAV_LINKS } from '../../../utils/constants';
import { createData, getDataById, updateData } from '../../../utils/fetchData';
import { getErrorLink, makeTitle } from '../../../utils/helpers';
import Form from '../../Form/Form';
import FormButtons from '../../Form/FormButtons/FormButtons';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';

const SimpleForm = ({ dataType, formType, category }) => {
    const SIMPLE_SCHEMA = { name: '' };
    const SIMPLE_FORM_FIELDS = { name: 'name' };
    const [isFetching, setIsFetching] = useState(formType === FORM_TYPES.edit);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState(SIMPLE_SCHEMA);
    const { id: dataId } = useParams();

    useEffect(() => {
        if (formType === FORM_TYPES.edit) {
            getDataById(dataType, dataId)
                .then((res) => {
                    if (res.error) {
                        navigate(getErrorLink(camelCase(res.error)));
                    } else {
                        setValues(res);
                        setData(res);
                    }
                })
                .catch(() => navigate(NAV_LINKS.notFound))
                .finally(() => setIsFetching(false));
        }
    }, []);

    const DataSchema = Yup.object().shape({
        name: Yup.string().min(4, t('nameMinCharacters')).required(t('nameRequired')),
    });

    const formik = useFormik({
        initialValues: SIMPLE_SCHEMA,
        validationSchema: DataSchema,
        onSubmit: (values) => (formType === FORM_TYPES.add ? addData(values) : updateValues(dataId, values)),
    });

    const getLink = () => {
        switch (dataType) {
            case DATA_TYPES.category:
                return NAV_LINKS.categories;
            case DATA_TYPES.grade:
                return NAV_LINKS.grades;
            default:
                return NAV_LINKS.app;
        }
    };

    const {
        errors,
        touched,
        isValid,
        values,
        setValues,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
    } = formik;

    const addData = (payload) => {
        createData(dataType, payload)
            .then((res) => {
                if (res.success) {
                    navigate(getLink());
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                dispatch(openAlertError());
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const updateValues = (id, payload) => {
        updateData(dataType, id, payload)
            .then((res) => {
                if (res.success) {
                    navigate(getLink());
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                dispatch(openAlertError());
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const isFormValid = () => isValid && Object.keys(touched).length && !isEqual(data, values);

    return isFetching ? (
        <LoadingOverlay fullScreen />
    ) : (
        <FormikProvider value={formik}>
            <Form title={makeTitle({ formType, category })} onSubmit={handleSubmit}>
                <Stack spacing={3} m={4}>
                    <TextField
                        key={SIMPLE_FORM_FIELDS.name}
                        id={SIMPLE_FORM_FIELDS.name}
                        name={SIMPLE_FORM_FIELDS.name}
                        label={t(SIMPLE_FORM_FIELDS.name)}
                        value={values[SIMPLE_FORM_FIELDS.name] ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched[SIMPLE_FORM_FIELDS.name] && errors[SIMPLE_FORM_FIELDS.name]}
                        error={Boolean(touched[SIMPLE_FORM_FIELDS.name] && errors[SIMPLE_FORM_FIELDS.name])}
                        onInput={() => setFieldTouched(SIMPLE_FORM_FIELDS.name, true, true)}
                    />
                    <FormButtons
                        navLink={getLink()}
                        formType={formType}
                        isFormValid={!!isFormValid()}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                    />
                </Stack>
            </Form>
        </FormikProvider>
    );
};

SimpleForm.propTypes = {
    dataType: PropTypes.string.isRequired,
    formType: PropTypes.oneOf([FORM_TYPES.add, FORM_TYPES.edit]).isRequired,
};

export default SimpleForm;
