import { TextField } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import { FORM_TYPES, NAV_LINKS } from '../../../utils/constants';
import { requestNewPassword } from '../../../utils/fetchData';
import Form from '../../Form/Form';
import { StyledStack } from '../../Form/Form.styles';
import FormButtons from '../../Form/FormButtons/FormButtons';

const RecoveryPasswordForm = () => {
    const dispatch = useDispatch();

    const RECOVERY_PASSWORD_FORM_FIELDS = {
        email: 'email',
    };

    const { t } = useTranslation();

    const RecoveryPasswordSchema = Yup.object().shape({
        email: Yup.string().email(t('emailRequirements')).required(t('emailRequired')),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: RecoveryPasswordSchema,
        onSubmit: (data) => recoverAccount(data.email),
    });

    const recoverAccount = (email) => {
        requestNewPassword(email)
            .then((res) => {
                if (res.success) {
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => {
                dispatch(openAlertError());
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const {
        errors,
        touched,
        isValid,
        setFieldTouched,
        handleSubmit,
        handleBlur,
        getFieldProps,
        isSubmitting,
        setSubmitting,
    } = formik;

    const isFormValid = () => isValid && Object.keys(touched).length;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <StyledStack spacing={6}>
                    <TextField
                        id={RECOVERY_PASSWORD_FORM_FIELDS.email}
                        name={RECOVERY_PASSWORD_FORM_FIELDS.email}
                        fullWidth
                        autoComplete={RECOVERY_PASSWORD_FORM_FIELDS.email}
                        type={RECOVERY_PASSWORD_FORM_FIELDS.email}
                        label={t(RECOVERY_PASSWORD_FORM_FIELDS.email)}
                        onBlur={handleBlur}
                        {...getFieldProps(RECOVERY_PASSWORD_FORM_FIELDS.email)}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        onInput={() => setFieldTouched(RECOVERY_PASSWORD_FORM_FIELDS.email, true, true)}
                    />

                    <FormButtons
                        navLink={NAV_LINKS.login}
                        formType={FORM_TYPES.send}
                        isFormValid={!!isFormValid()}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                    />
                </StyledStack>
            </Form>
        </FormikProvider>
    );
};

export default RecoveryPasswordForm;
