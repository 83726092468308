import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import React from 'react';

const Checkbox = ({ field, label, disabled, handleChange, values }) => (
    <FormControlLabel
        sx={{
            width: 'fit-content',
            userSelect: 'none',
        }}
        control={<MuiCheckbox checked={values[field]} color="primary" />}
        label={label}
        key={field}
        id={field}
        name={field}
        disabled={disabled}
        onChange={handleChange}
    />
);

export default Checkbox;
