import { FormControl } from '@mui/material';
import styled, { css } from 'styled-components';

export const SelectFormControl = styled(({ hideOptions, ...props }) => <FormControl {...props} />)`
    width: 100%;

    ${({ hideOptions }) =>
        hideOptions &&
        css`
            & svg {
                display: none;
            }
        `}
`;
