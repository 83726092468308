import { DATA_TYPES, TABLE_SETTINGS } from '../../../utils/constants';
import Table from '../../Table/Table';

const QuestionsTable = () => (
    <Table
        dataType={DATA_TYPES.question}
        tableName={TABLE_SETTINGS.question.name}
        tableHead={TABLE_SETTINGS.question.tableHead}
    />
);

export default QuestionsTable;
