import styled, { css } from 'styled-components';
import { DATA_TYPES } from '../../utils/constants';
import { PAGINATION_PANEL_POSITION } from '../PaginationPanel/PaginationPanel.styles';

const borderRight = css`
    border-right: 1px solid ${({ theme }) => theme.palette.tableBorder} !important;

    &:last-child {
        border-right: 0 !important;
    }
`;

const styledCells = css`
    .ag-header-container {
        .ag-header-cell {
            ${borderRight};
        }
    }

    .ag-center-cols-clipper {
        .ag-cell {
            ${borderRight};
        }
    }
`;

export const TableWrapper = styled.div`
    width: 100%;
    height: ${({ height }) => height ?? '100%'};
    width: ${({ width }) => width ?? '100%'};
    border: 1px solid ${({ theme }) => theme.palette.tableBorder};
    position: relative;

    .ag-overlay-no-rows-center {
        opacity: ${({ showOverlay }) => (showOverlay ? 1 : 0)};
    }

    .ag-icon-grip {
        cursor: -webkit-grabbing;
    }

    .ag-filter {
        width: 250px;
    }

    .ag-filter-apply-panel-button {
        cursor: pointer;
        box-shadow: none !important;
    }

    .ag-filter-apply-panel-button:hover {
        opacity: 0.8;
    }

    .ag-cell-focus {
        user-select: text;
        border: 1px solid transparent !important;
    }

    .ag-select-list {
        max-height: 400px !important;
    }

    .ag-select-list-item[aria-posinset='1'] {
        color: #a9a9a9;
    }

    .ag-header-icon {
        cursor: pointer;
    }

    ${({ dataType }) =>
        dataType === DATA_TYPES.quizScoring &&
        css`
            border: none;
            & > div:nth-child(1) {
                outline: 1px solid ${({ theme }) => theme.palette.tableBorder};
            }
            .ag-cell:not([col-id='round'], [col-id='ordinary_number']) {
                padding: 0;
            }
            .ag-cell-wrapper,
            .ag-cell-value {
                height: 100%;
            }
        `}

    ${({ dataType }) =>
        (dataType === DATA_TYPES.quiz || dataType === DATA_TYPES.training) &&
        css`
            .ag-checkbox-input-wrapper.ag-checked::after {
                color: ${({ theme }) => theme.palette.primary.main};
            }
        `}

    ${({ isMultiSelect }) =>
        isMultiSelect &&
        css`
            .ag-select-list-item[aria-posinset='2'] {
                color: #696969;
                border-bottom: 1px solid #696969;
                border-bottom-style: dashed;
            }

            .ag-select-list-item[aria-posinset='2'] {
                span::before {
                    content: '🔍 ';
                }
            }
        `}

    ${styledCells};
`;

export const FormTableWrapper = styled.div`
    height: ${({ height }) => height ?? '535px'};
    width: 100%;
    max-width: ${({ width }) => width ?? '90%'};
    border: 1px solid ${({ theme }) => theme.palette.tableBorder};
    border-radius: 8px;
    overflow: hidden;

    & > div {
        overflow: auto;
    }

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        max-width: 100%;
    }

    .ag-pinned-left-cols-container,
    .ag-center-cols-container {
        div:last-child {
            border-bottom: none;
        }
    }

    .ag-icon-grip {
        cursor: -webkit-grabbing;
    }

    .ag-cell-wrapper {
        line-height: 1.5rem;
        word-break: initial;
    }

    .ag-cell-focus {
        user-select: text;
    }

    .ag-cell {
        display: flex;
        flex-direction: column;
    }

    .ag-filter-apply-panel-button {
        cursor: pointer;
        box-shadow: none !important;
    }

    .ag-filter-apply-panel-button:hover {
        opacity: 0.8;
    }

    .ag-cell-edit-wrapper {
        margin-top: -50px;
    }

    .ag-selection-checkbox {
        margin: 0 !important;
    }

    .ag-cell-not-selectable {
        border: 1px solid transparent !important;
        user-select: none;
    }

    .ag-cell-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ag-cell-center-justify {
        display: flex;
        justify-content: center;
    }

    ${({ editable, isPreview }) =>
        !editable &&
        css`
            width: 100%;
            .ag-cell-focus {
                user-select: text;
                border: 1px solid transparent !important;
            }

            .ag-row-hover {
                background-color: ${isPreview} =>
                    isPreview ? 'transparent' : ${({ theme }) => theme.palette.primary.lighter}};
            }
        `}

    ${styledCells};
`;

export const TableContainer = styled.div`
    width: 100%;
    height: ${({ withPagination }) =>
        withPagination ? `calc(100% - ${1.2 * PAGINATION_PANEL_POSITION}px)` : `100%`};

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        width: 95%;
        margin: 0 auto;
    }
`;

export const TableButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-end'};
    flex-wrap: wrap;
    padding: 1rem 0;
`;

export const TableNameContainer = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        margin-left: 0;
    }
`;

export const TableName = styled.p`
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 1rem;
    font-weight: bold;
`;

export const ButtonsContainer = styled.div`
    width: ${({ width }) => width ?? '90%'};

    ${({ buttonsPosition }) =>
        buttonsPosition &&
        css`
            margin-top: ${buttonsPosition};
        `}

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        width: 100%;
    }
`;

export const FieldMarginWrapper = styled.div`
    margin-right: 1rem;
`;
