import Autocomplete from './Autocomplete';
import Button from './Button';
import Card from './Card';
import CssBaseline from './CssBaseline';
import Input from './Input';
import Paper from './Paper';
import Tooltip from './Tooltip';
import Typography from './Typography';

const ComponentsOverrides = (theme) =>
    Object.assign(
        Card(theme),
        Input(theme),
        Paper(theme),
        Button(theme),
        Tooltip(theme),
        Typography(theme),
        CssBaseline(theme),
        Autocomplete(theme)
    );

export default ComponentsOverrides;
