import { useTranslation } from 'react-i18next';
import { PaginationContainer, PaginationSpan, StyledPaginationPanel } from './PaginationPanel.styles';
import PaginationSelect from './PaginationSelect/PaginationSelect';

const PaginationPanel = ({
    paginationSizes,
    paginationPageSize,
    changePaginationSize,
    pageNumber,
    totalCount,
    totalPages,
    moveToNextPage,
    moveToPreviousPage,
    moveToLastPage,
    moveToFirstPage,
}) => {
    const PAGINATION_BUTTONS = {
        previous: 'previous',
        next: 'next',
        styles: {
            enabled: 'enabled',
            disabled: 'disabled',
        },
    };
    const { t } = useTranslation();

    const getStyle = (type) => {
        if (type === PAGINATION_BUTTONS.previous) {
            if (pageNumber === 1) {
                return PAGINATION_BUTTONS.styles.disabled;
            }
            return PAGINATION_BUTTONS.styles.enabled;
        }
        if (pageNumber === totalPages) {
            return PAGINATION_BUTTONS.styles.disabled;
        }
        return PAGINATION_BUTTONS.styles.enabled;
    };

    return (
        <StyledPaginationPanel>
            <PaginationSelect
                paginationSizes={paginationSizes}
                selectedPaginationSize={paginationPageSize}
                changePaginationSize={changePaginationSize}
            />
            <PaginationContainer className={'ag-paging-panel ag-unselectable'}>
                <PaginationSpan className={'ag-paging-row-summary-panel'} hideOnMobile>
                    <PaginationSpan className={'ag-paging-row-summary-panel-number'}>
                        {totalCount ? (pageNumber - 1) * paginationPageSize + 1 : 0}
                    </PaginationSpan>
                    <PaginationSpan>{` ${t('to')} `}</PaginationSpan>
                    <PaginationSpan className={'ag-paging-row-summary-panel-number'}>
                        {pageNumber * paginationPageSize > totalCount
                            ? totalCount
                            : pageNumber * paginationPageSize}
                    </PaginationSpan>
                    <PaginationSpan>{` ${t('from')} `}</PaginationSpan>
                    <PaginationSpan className={'ag-paging-row-summary-panel-number'}>
                        {totalCount}
                    </PaginationSpan>
                </PaginationSpan>

                <PaginationSpan className={'ag-paging-page-summary-panel'}>
                    <PaginationContainer
                        className={`ag-paging-button ag-${getStyle(PAGINATION_BUTTONS.previous)}`}
                    >
                        <PaginationSpan
                            onClick={moveToFirstPage}
                            className={'ag-icon ag-icon-first'}
                        ></PaginationSpan>
                    </PaginationContainer>

                    <PaginationContainer
                        className={`ag-paging-button ag-${getStyle(PAGINATION_BUTTONS.previous)}`}
                    >
                        <PaginationSpan
                            onClick={moveToPreviousPage}
                            className={'ag-icon ag-icon-previous'}
                        ></PaginationSpan>
                    </PaginationContainer>

                    <PaginationSpan className={'ag-paging-description'}>
                        {`${t('page')} ${!totalCount ? totalCount : pageNumber} ${t('from')} ${
                            totalCount ? totalPages : 0
                        }`}
                    </PaginationSpan>

                    <PaginationContainer
                        className={`ag-paging-button ag-${getStyle(PAGINATION_BUTTONS.next)}`}
                    >
                        <PaginationSpan
                            onClick={moveToNextPage}
                            className={'ag-icon ag-icon-next'}
                        ></PaginationSpan>
                    </PaginationContainer>

                    <PaginationContainer
                        className={`ag-paging-button ag-${getStyle(PAGINATION_BUTTONS.next)}`}
                    >
                        <PaginationSpan
                            onClick={moveToLastPage}
                            className={'ag-icon ag-icon-last'}
                        ></PaginationSpan>
                    </PaginationContainer>
                </PaginationSpan>
            </PaginationContainer>
        </StyledPaginationPanel>
    );
};

PaginationPanel.defaultProps = {
    pageNumber: 0,
    totalCount: 0,
};

export default PaginationPanel;
