const LANGUAGES = [
    {
        value: 'pl',
        label: 'polish',
        icon: '/static/icons/flag_pl.svg',
    },
    {
        value: 'en',
        label: 'english',
        icon: '/static/icons/flag_en.svg',
    },
];

export default LANGUAGES;
