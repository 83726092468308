import React, { useRef } from 'react';
import { OVERLAY_TYPES } from '../../utils/constants';
import TableBase from './TableBase/TableBase';
import DefaultOverlay from './TableOverlays/DefaultOverlay';
import MinContentOverlay from './TableOverlays/MinContentOverlay';
import ModalOverlay from './TableOverlays/ModalOverlay';
import UserPackagesOverlay from './TableOverlays/UserPackagesOverlay';

const Overlay = ({ overlayType, rowHeight, alignContent, children, forwardedRef }) => {
    const checkOverlayType = () => {
        switch (overlayType) {
            case OVERLAY_TYPES.modal:
                return ModalOverlay;
            case OVERLAY_TYPES.minContent:
                return MinContentOverlay;
            case OVERLAY_TYPES.userPackages:
                return UserPackagesOverlay;
            default:
                return null;
        }
    };

    return (
        <DefaultOverlay
            rowHeight={rowHeight}
            alignContent={alignContent}
            as={checkOverlayType()}
            ref={forwardedRef}
        >
            {children}
        </DefaultOverlay>
    );
};

const CustomTable = ({ overlayType, rowHeight, alignContent, ...props }) => {
    const overlayRef = useRef(null);
    return (
        <Overlay
            forwardedRef={overlayRef}
            rowHeight={rowHeight}
            alignContent={alignContent}
            overlayType={overlayType}
        >
            <TableBase overlayRef={overlayRef} {...props} />
        </Overlay>
    );
};

export default CustomTable;
