const AG_GRID_LOCALE = {
    pl: {
        filterOoo: '',
        contains: 'Szukaj...',
        noRowsToShow: 'Brak danych do wyświetlenia',
        loadingOoo: 'Trwa wczytywanie danych...',
        errorLoadingData: 'Wystąpił błąd podczas pobierania danych.',
        empty: 'Wybierz',
        resetFilter: 'Wyczyść',
    },
    en: {
        filterOoo: '',
        contains: 'Search...',
        noRowsToShow: 'No rows to show',
        loadingOoo: 'Loading data...',
        errorLoadingData: 'There was an error retrieving the data.',
        empty: 'Choose',
        resetFilter: 'Clear',
    },
};

export default AG_GRID_LOCALE;
