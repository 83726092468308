import { Button } from '@mui/material';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { WIDTHS } from '../../../../utils/constants';

const QuestionDeletionHeader = ({ modalData, tableData, handleAction, handleClose }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    return (
        <>
            <span className="questionContent">{modalData?.content ?? ''}</span>
            <div className="buttons">
                <Button
                    variant={'contained'}
                    disabled={!!tableData.length || isDisabled}
                    color="error"
                    onClick={async () => {
                        setIsDisabled(true);
                        await handleAction(modalData.id, modalData);
                        setIsDisabled(false);
                    }}
                    sx={{
                        width: 'fit-content',
                        borderColor: (theme) => theme.palette.error.main,
                        borderWidth: 2,
                        borderStyle: 'solid',
                        ':disabled': {
                            borderColor: 'transparent',
                        },
                        ':hover': {
                            borderColor: (theme) => theme.palette.error.dark,
                        },
                    }}
                >
                    {t('removeQuestion')}
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        borderWidth: '2px',
                        width: WIDTHS.button,
                        ':hover': {
                            borderWidth: '2px',
                        },
                    }}
                    onClick={handleClose}
                >
                    {t('close')}
                </Button>
            </div>
        </>
    );
};

QuestionDeletionHeader.propTypes = {
    modalData: PropTypes.object.isRequired,
    tableData: PropTypes.array.isRequired,
    handleAction: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default QuestionDeletionHeader;
