import { compareObjectVals } from 'crud-object-diff';
import { t } from 'i18next';
import { groupBy, isNull, kebabCase, mapValues, omit, range } from 'lodash';
import {
    CATEGORIES,
    CURRENCY,
    DATA_TYPES,
    DIFFICULTIES,
    DIFFICULTY_NAMES,
    HTTP_ERRORS,
    INFO_MESSAGE,
    LOCAL_STORAGE_KEY,
    NAV_LINKS,
    POINTS_INFO,
    USER_ROLES,
    YOUTUBE_LINK_REGEX,
} from './constants';

export const getToken = () => localStorage.getItem(LOCAL_STORAGE_KEY.token);

export const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

export const getMainPageLink = () => (getToken() ? NAV_LINKS.dashboard : NAV_LINKS.login);

export const isComplexUserData = (user) => user?.first_name && user?.last_name;

export const getTableFieldsObject = (tableHead) =>
    Object.fromEntries(tableHead.map((head) => [head.field, head.headerName ?? head.field]));

export const formatDate = (date, fullDate) => {
    try {
        if (!date && typeof date !== 'string') return null;
        const formattedDate = new Date(new Date(date) - new Date(date).getTimezoneOffset() * 60000)
            .toISOString()
            .split('T');

        return fullDate ? `${formattedDate[0]} ${formattedDate[1].split('.')[0]}` : formattedDate[0];
    } catch {
        return null;
    }
};

export const underscoreText = (text) => text.replace(/[: -]/g, '_');

export const getYoutubeVideoId = (url) => url.match(YOUTUBE_LINK_REGEX)?.at(1) ?? '';

export const getErrorLink = (error) => {
    switch (error) {
        case HTTP_ERRORS.notFound.text:
            return NAV_LINKS.notFound;
        case HTTP_ERRORS.unauthorized.text:
            return NAV_LINKS.unauthorized;
        default:
            return NAV_LINKS.notFound;
    }
};

export const getEditLink = (tableName, itemId) => `/${kebabCase(tableName)}/${itemId}/edit`;

export const getShowLink = (category, itemId) => `${category}/${itemId}/show`;

export const getGroupParticipantsLink = (itemId, elementId) =>
    `${NAV_LINKS.participantGroups}/${itemId}/participants${elementId ? `/${elementId}/edit` : ''}`;

export const getSectionsLink = (itemId, elementId) =>
    `${NAV_LINKS.categories}/${itemId}/sections${elementId ? `/${elementId}/edit` : ''}`;

export const getQuizLink = (packageId) => `${NAV_LINKS.packages}/${packageId}/quiz`;

export const getQuizResultsLink = (packageId) => `${NAV_LINKS.packages}/${packageId}/quiz/results`;

export const getQuizParametersLink = (packageId) => `${NAV_LINKS.packages}/${packageId}/quiz/parameters`;

export const getTrainingLink = (packageId) => `${NAV_LINKS.packages}/${packageId}/training`;

export const getOrganizationPackagesLink = (organizationId) =>
    `${NAV_LINKS.organizations}/${organizationId}/packages`;

export const getNavLink = (dataType, elementId) => {
    switch (dataType) {
        case DATA_TYPES.account:
            return NAV_LINKS.userProfile;
        case DATA_TYPES.administrator:
            return NAV_LINKS.administrators;
        case DATA_TYPES.organization:
            return NAV_LINKS.organizations;
        case DATA_TYPES.organizationUser:
            return NAV_LINKS.organizationUsers;
        case DATA_TYPES.participant:
            return elementId ? getGroupParticipantsLink(elementId) : NAV_LINKS.participants;
        case DATA_TYPES.participantGroup:
        case DATA_TYPES.groupParticipant:
            return NAV_LINKS.participantGroups;
        case DATA_TYPES.question:
            return NAV_LINKS.questions;
        case DATA_TYPES.category:
        case DATA_TYPES.section:
            return NAV_LINKS.categories;
        case DATA_TYPES.grade:
            return NAV_LINKS.grades;
        case DATA_TYPES.quiz:
        case DATA_TYPES.training:
            return NAV_LINKS.packages;
        case DATA_TYPES.quizScoring:
        case DATA_TYPES.quizResults:
            return -1;
        default:
            return NAV_LINKS.dashboard;
    }
};

export const getPermissions = (category, role) => {
    const getViewManagePermissions = (viewPermissions, managePermissions) => ({
        view: viewPermissions,
        manage: managePermissions,
    });

    switch (role) {
        case USER_ROLES.superAdmin:
            return getViewManagePermissions(true, true);
        case USER_ROLES.organizationAdmin:
            switch (category) {
                case CATEGORIES.organizations:
                    return getViewManagePermissions(true, false);
                case CATEGORIES.userPackage:
                    return getViewManagePermissions(true, true);
                case CATEGORIES.organizationUsers:
                    return getViewManagePermissions(true, true);
                case CATEGORIES.participants:
                    return getViewManagePermissions(true, true);
                case CATEGORIES.participantGroups:
                    return getViewManagePermissions(true, true);
                case CATEGORIES.products:
                    return getViewManagePermissions(true, false);
                case CATEGORIES.orders:
                    return getViewManagePermissions(true, false);
                case CATEGORIES.logActivity:
                    return getViewManagePermissions(true, false);
                default:
                    return getViewManagePermissions(false, false);
            }
        default:
            return getViewManagePermissions(false, false);
    }
};

export const isTableContentFit = () => {
    const containerWidth = document.getElementsByClassName('ag-center-cols-container')[0]?.scrollWidth;

    const viewportWidth = document.getElementsByClassName('ag-center-cols-viewport')[0]?.scrollWidth;

    return containerWidth < viewportWidth;
};

export const getQuestionDifficultyName = (difficulty) => {
    switch (difficulty) {
        case 3:
            return DIFFICULTY_NAMES.easy;
        case 5:
            return DIFFICULTY_NAMES.medium;
        case 7:
            return DIFFICULTY_NAMES.difficult;
        default:
            return difficulty;
    }
};
export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getArrayDiff = (source, target) => {
    const arr = [];

    source.map((item, index) => {
        let isChange = false;
        const newObject = {};
        Object.keys(item).map((name) => {
            if (item[name] !== target[index][name]) {
                newObject.id = target[index].id;
                newObject[name] = target[index][name];
                isChange = true;
            }
        });
        if (isChange) {
            arr.push(newObject);
        }
    });

    return arr;
};

export const getObjectDiff = (source, target) =>
    Object.fromEntries(Object.entries(target).filter(([key, val]) => key in source && source[key] !== val));

export const uniqueEntriesArray = (array) => {
    const set = new Set(array);
    return [...set].sort((a, b) => a - b);
};

export const setCenterCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
});

export const getFieldTooltip = (field) => `${field}${INFO_MESSAGE}`;

export const renameDifficulty = (difficultyObject) =>
    Object.fromEntries(
        Object.entries(difficultyObject).map((diff) => {
            if (diff[0] === POINTS_INFO.gain || diff[0] === POINTS_INFO.loss) {
                return [diff[0], renameDifficulty(diff[1])];
            }

            return [DIFFICULTIES[diff[0]], diff[1]];
        })
    );

export const groupRows = (data, groupByName) =>
    mapValues(groupBy(data, groupByName), (clist) => clist.map((groupItem) => omit(groupItem, groupByName)));

export const ignoreLogs = (logs) => {
    const consoleWarn = console.warn;
    console.warn = function filterWarnings(msg, ...args) {
        if (!logs.some((entry) => msg.includes(entry))) {
            consoleWarn(msg, ...args);
        }
    };
};

export const formatPrice = (price) => `${price?.replace('.', ',') ?? '0,00'} ${CURRENCY}`;

export const compareObjects = (source, target, idField) => {
    const { createdVals, updatedVals, deletedVals } = compareObjectVals([source, target], idField);

    return {
        ...(createdVals?.length ? { create: createdVals?.map(({ idField, ...vals }) => vals) } : {}),
        ...(updatedVals?.length ? { update: updatedVals } : {}),
        ...(deletedVals?.length ? { delete: deletedVals?.map((val) => val[idField] ?? val) } : {}),
    };
};

export const preparePayload = (initialValues, updatedValues, compareProp, idField) => {
    const comparePropDiff = compareObjects(initialValues[compareProp], updatedValues[compareProp], idField);

    delete initialValues[compareProp];
    delete updatedValues[compareProp];

    return {
        ...comparePropDiff,
        ...getObjectDiff(initialValues, updatedValues),
    };
};

export const makeTitle = ({ formType, category }) => `${t(category, { context: formType })}`;

export const getCharCode = (i) => String.fromCharCode(i + 97);

export const getYears = () =>
    range(2023, new Date().getFullYear() + 1).map((year) => ({
        id: year,
        name: String(year),
    }));

export const getMonths = () => [
    { name: t('january'), id: 1 },
    { name: t('february'), id: 2 },
    { name: t('march'), id: 3 },
    { name: t('april'), id: 4 },
    { name: t('may'), id: 5 },
    { name: t('june'), id: 6 },
    { name: t('july'), id: 7 },
    { name: t('august'), id: 8 },
    { name: t('september'), id: 9 },
    { name: t('october'), id: 10 },
    { name: t('november'), id: 11 },
    { name: t('december'), id: 12 },
];

export const isValidUrl = (urlString) => {
    try {
        return Boolean(new URL(urlString));
    } catch (e) {
        return false;
    }
};

export const handleMedia = (mediaPath) => {
    if (isNull(mediaPath) || !mediaPath) return '';
    const isUrl = isValidUrl(mediaPath);
    return isUrl ? mediaPath : `${process.env.REACT_APP_IMAGE_URL}${mediaPath}`;
};
