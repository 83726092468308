import { Button, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { TABLE_FIELDS } from '../../../../utils/constants';

const BUTTON_TYPES = {
    [TABLE_FIELDS.deleteButtonQuiz]: 'quiz',
    [TABLE_FIELDS.deleteButtonTraining]: 'training',
};

const isVisible = (data, field) => {
    const type = BUTTON_TYPES[field];
    const [isData] = data.data.filter((item) => item.type === type);
    return !!isData;
};

const DeleteButton = ({ onClick, text, data, field }) => {
    const [visible, setVisible] = useState(isVisible(data, field));
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        setVisible(isVisible(data, field));
    });

    return visible ? (
        <Button
            color={'error'}
            disabled={isLoading}
            onClick={() => {
                setIsLoading(true);
                onClick(data, field, setIsLoading);
            }}
        >
            {isLoading ? <ClipLoader size={15} color={theme.palette.common.red} /> : text}
        </Button>
    ) : (
        <span> - </span>
    );
};

export default DeleteButton;
