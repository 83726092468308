import { Button, Container, Typography } from '@mui/material';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Page from '../../components/Page/Page';
import { getMainPageLink } from '../../utils/helpers';
import { ContentStyle, ErrorContainer } from './ErrorPage.styles';

const ErrorPage = ({ error }) => {
    const { t } = useTranslation();
    const customError = useLocation()?.state?.error;

    return (
        <Page>
            <Container>
                <ContentStyle>
                    <Typography variant="h3" paragraph>
                        {t(`${error.text}PageTitle`)}
                    </Typography>

                    <Typography sx={{ color: 'text.secondary', mt: 2 }}>
                        {t(camelCase(customError) || `${error.text}PageText`)}
                    </Typography>

                    <ErrorContainer>{error.code}</ErrorContainer>

                    <Button to={getMainPageLink()} size="medium" variant="contained" component={RouterLink}>
                        {t('backToMainPage')}
                    </Button>
                </ContentStyle>
            </Container>
        </Page>
    );
};

ErrorPage.propTypes = {
    error: PropTypes.shape({
        text: PropTypes.string.isRequired,
        code: PropTypes.number.isRequired,
    }).isRequired,
};

export default ErrorPage;
