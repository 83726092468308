import { Stack } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import { ALL_DATA, DATA_TYPES, FORM_TYPES, NAV_LINKS, ORDER_STATUS_NAMES } from '../../../utils/constants';
import { createData, getData } from '../../../utils/fetchData';
import { formatPrice, makeTitle } from '../../../utils/helpers';
import Form from '../../Form/Form';
import { FlexContainer, MarginContainer, TextInfo, TextInfoBold } from '../../Form/Form.styles';
import FormButtons from '../../Form/FormButtons/FormButtons';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';
import SelectField from '../../SelectField/SelectField';

const OrderForm = ({ formType, category }) => {
    const ORDER_FORM_FIELDS = {
        organization: 'organization',
        product: 'product',
    };

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isFetching, setIsFetching] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (formType !== FORM_TYPES.add) {
            navigate(NAV_LINKS.notFound);
            dispatch(openAlertError(t('notFound')));
            return;
        }
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const organizations = await getData(DATA_TYPES.organization, ALL_DATA);
            const products = await getData(DATA_TYPES.product, ALL_DATA);

            if (organizations.error || products.error) {
                dispatch(openAlertError(t('error')));
                throw new Error('errorFetchingData');
            }
            setOrganizations(organizations.data);
            if (organizations.data.length === 1) {
                setFieldValue(ORDER_FORM_FIELDS.organization, organizations.data[0]?.id);
            }
            setProducts(products.data);
        } catch (error) {
            navigate(NAV_LINKS.notFound);
            dispatch(openAlertError(t(camelCase(error.message))));
            console.error(error);
        } finally {
            setIsFetching(false);
        }
    };

    const onSubmit = async (values) => {
        const payload = {
            status: ORDER_STATUS_NAMES.new,
            product_id: values.product,
            organization_id: values.organization,
        };
        try {
            const res = await createData(DATA_TYPES.order, payload);
            if (res.error) {
                dispatch(openAlertError(t(res.error)));
            } else {
                dispatch(openAlertSuccess(t('orderCreated')));
                navigate(NAV_LINKS.orders);
            }
        } catch (error) {
            dispatch(openAlertError(t(camelCase(error.message))));
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    const validationSchema = Yup.object().shape({
        organization: Yup.number().required(t('organizationIdRequired')),
        product: Yup.number().required(t('productRequired')),
    });

    const ORDER_SCHEMA = {
        organization: '',
        product: '',
    };

    const ORDER_FIELDS = Object.fromEntries(Object.keys(ORDER_SCHEMA).map((field) => [field, field]));

    const formik = useFormik({
        initialValues: ORDER_SCHEMA,
        validationSchema,
        onSubmit,
    });

    const {
        errors,
        touched,
        isValid,
        values,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        setSubmitting,
    } = formik;

    const getSelectValues = (field) => {
        switch (field) {
            case ORDER_FIELDS.organization:
                return organizations;
            case ORDER_FIELDS.product:
                return products;
            default:
                return [];
        }
    };

    const isFormValid = () => isValid && !!Object.keys(touched).length;

    const generateSelectFields = () =>
        Object.keys(ORDER_SCHEMA).map((field) => {
            if (field === ORDER_FORM_FIELDS.organization && organizations.length === 1) {
                return null;
            }

            return (
                <SelectField
                    key={field}
                    field={field}
                    label={t(field)}
                    values={values}
                    selectValues={getSelectValues(field)}
                    handleChange={(e, value) => {
                        setFieldValue(field, value?.id ?? '');
                        setFieldTouched(field, true, false);
                    }}
                    autocomplete
                    errors={errors}
                    touched={touched}
                />
            );
        });

    const generatePriceInfo = () => {
        const product =
            values[ORDER_FIELDS.product] &&
            products.find((product) => product.id === values[ORDER_FIELDS.product]);

        return (
            <TextInfo>
                <TextInfoBold>{`${t('toPay')}: `}</TextInfoBold>
                {formatPrice(product?.price)}
            </TextInfo>
        );
    };

    return isFetching ? (
        <LoadingOverlay fullScreen />
    ) : (
        <FormikProvider value={formik}>
            <FlexContainer>
                <Form title={makeTitle({ formType, category })} onSubmit={handleSubmit}>
                    <Stack spacing={3} m={4} style={{ marginBottom: '1.5rem' }}>
                        {generateSelectFields()}
                        {generatePriceInfo()}
                    </Stack>
                    <MarginContainer style={{ margin: '0 2rem' }}>
                        <FormButtons
                            navLink={NAV_LINKS.orders}
                            formType={formType}
                            isFormValid={isFormValid()}
                            disabled={isSubmitting}
                            isLoading={isSubmitting}
                        />
                    </MarginContainer>
                </Form>
            </FlexContainer>
        </FormikProvider>
    );
};

OrderForm.propTypes = {
    formType: PropTypes.oneOf([FORM_TYPES.add]).isRequired,
};

export default OrderForm;
