import styled from 'styled-components';

export const SectionTitleWrapper = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    background-color: white;
    margin-left: ${({ tableMargin }) => (tableMargin ? '-2rem' : 0)};
    padding: 1rem;
    padding-right: 5rem;
    border-left: 0.25rem solid ${({ theme }) => theme.palette.primary.main};
    margin-bottom: ${({ withMargin }) => (withMargin ? '1.5rem' : 0)};

    ${({ theme }) => theme.mediaBreakpoints.laptop} {
        margin-left: 0;
    }
`;

export const SectionParagraph = styled.p`
    font-family: ${({ theme }) => theme.typography.secondaryFontFamily};
    margin-bottom: ${({ withMargin }) => (withMargin ? '1.5rem' : 0)};
    letter-spacing: 0.05rem;
    font-size: 1rem;
    padding-left: 1rem;
`;
