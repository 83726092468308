import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { RootStyle, SimpleBarStyle } from './Scrollbar.styles';

const Scrollbar = ({ children, sx, ...other }) => {
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    if (isMobile) {
        return (
            <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
                {children}
            </Box>
        );
    }

    return (
        <RootStyle>
            <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} {...other}>
                {children}
            </SimpleBarStyle>
        </RootStyle>
    );
};

Scrollbar.propTypes = {
    children: PropTypes.node.isRequired,
    sx: PropTypes.object,
};

export default Scrollbar;
