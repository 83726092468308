import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
    page: {
        backgroundColor: '#FFF',
    },
    body: {
        padding: 20,
        display: 'flex',
        flexDirection: 'row',
    },
    infoContainer: {
        flex: 1,
        fontSize: 14,
    },
});
