import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    .ag-theme-material {
        --ag-checkbox-checked-color: ${({ theme }) => theme.palette.primary.main};
        --ag-checkbox-unchecked-color: ${({ theme }) => theme.palette.primary.dark};
        --ag-font-family: ${({ theme }) => theme.typography.fontFamily};
        --ag-material-primary-color: ${({ theme }) => theme.palette.primary.main};
        --ag-row-hover-color: ${({ theme }) => theme.palette.primary.autocomplete};
        --ag-selected-row-background-color: ${({ theme }) => theme.palette.primary.autocomplete};
    }

    html {
        scroll-behavior: smooth;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) =>
            theme.palette.primary.autocomplete} inset !important;
    };

    ::-moz-selection { 
        color:white;
        background: ${({ theme }) => theme.palette.primary.dark};
    }

    ::selection {
        color:white;
        background: ${({ theme }) => theme.palette.primary.dark};
    }

    body {
        margin: 0;
        box-sizing: border-box;
        font-family: ${({ theme }) => theme.typography.fontFamily};
    }

    a {
        color: white;
        text-decoration: none;
    }
    
    div[data-rmiz-modal-overlay="visible"] {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }

    .custom_tooltip_class {
        background-color: ${({ theme }) => theme.palette.common.white};
        padding: 0.5rem 1rem;
        border-radius: 2px;
        box-shadow: 10px 10px 10px -10px rgba(66, 68, 90, 1);
        z-index: 9999999;
        position: absolute;
        font-size: 0.8rem;
    }

    .MuiPickersPopper-root {
        z-index: 99999;
    }

`;

export default GlobalStyle;
