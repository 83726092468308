import styled, { css } from 'styled-components';

export const HtmlWrapper = styled.div`
    ${({ isContainer }) =>
        isContainer &&
        css`
            height: 400px;
            background-color: ${({ theme }) => theme.palette.common.white};
            border: 1px solid ${({ theme }) => theme.palette.tableBorder};
            border-radius: 8px;
            padding: 10px;
            overflow-y: scroll;
            p:empty {
                height: 1.5rem;
            }
        `};
`;
