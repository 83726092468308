import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, TextField } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { FormikProvider, useFormik } from 'formik';
import { camelCase, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import useBrowserWidth from '../../../hooks/useBrowserWidth';
import {
    ALL_DATA,
    ANSWER_SCHEMA,
    COLUMN_WIDTHS,
    DATA_TYPES,
    DIFFICULTIES,
    FORM_TYPES,
    NAV_LINKS,
    NUMBER_REGEX,
    QUESTIONS_DIFFICULTY,
    QUESTION_FORM_FIELDS,
} from '../../../utils/constants';
import htmlToEditorState from '../../../utils/draft-js/htmlToEditorState';
import { getData, getDataById, getSectionsByCategory, manageQuestion } from '../../../utils/fetchData';
import {
    getArrayDiff,
    getCharCode,
    getQuestionDifficultyName,
    handleMedia,
    isValidUrl,
    makeTitle,
} from '../../../utils/helpers';
import AG_GRID_LOCALE from '../../../utils/tableTranslations';
import CustomTable from '../../CustomTable/CustomTable';
import Form from '../../Form/Form';
import {
    Field,
    FieldContainer,
    FlexContainer,
    MarginBlock,
    MarginContainer,
    TableWrapper,
    TitleContainer,
    WidthContainer,
} from '../../Form/Form.styles';
import FormButtons from '../../Form/FormButtons/FormButtons';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';
import MediaInput from '../../MediaInput/MediaInput';
import SectionTitle from '../../SectionTitle/SectionTitle';
import SelectField from '../../SelectField/SelectField';
import { FormTableWrapper } from '../../Table/Table.styles';
import WarningInfo from '../../WarningInfo/WarningInfo';
import Wysiwyg from '../../Wysiwyg/Wysiwyg';
import AnswerCheckbox from './AnswerCheckbox/AnswerCheckbox';
import { Container } from './QuestionForm.styles';

const QuestionForm = ({ formType, category }) => {
    const INFO_FIELDS = {
        description: 'description',
        data: 'data',
    };

    const ID_FIELDS = {
        contentHtml: 'content_html',
        section: 'section_id',
        grade: 'grade_id',
        category: 'category_id',
        [QUESTION_FORM_FIELDS.explanation_media]: 'explanation_media',
        [QUESTION_FORM_FIELDS.question_media]: 'question_media',
    };

    const DEFAULT_ANSWER = {
        [ANSWER_SCHEMA.content]: '',
        [ANSWER_SCHEMA.media]: '',
        [ANSWER_SCHEMA.isCorrect]: false,
    };

    const DEFAULT_ANSWERS = [
        { ...DEFAULT_ANSWER },
        { ...DEFAULT_ANSWER },
        { ...DEFAULT_ANSWER },
        { ...DEFAULT_ANSWER },
    ];

    const QUESTION_SCHEMA = {
        content_html: '',
        queestion_media: '',
        content: '',
        time: '',
        difficulty: 3,
        question_type: 1,
        question_style: 1,
        status: 1,
        grade_id: '',
        category_id: '',
        section_id: '',
        answers: DEFAULT_ANSWERS,
        explanation: '',
        explanation_media: '',
    };

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [isFetching, setIsFetching] = useState(formType === FORM_TYPES.edit);
    const [isFetchingAdditionalData, setIsFetchingAdditionalData] = useState({
        categories: true,
        grades: true,
        sections: false,
    });
    const [grades, setGrades] = useState([]);
    const [categories, setCategories] = useState([]);
    const [sections, setSections] = useState([]);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [data, setData] = useState({});
    const [initialAnswers, setInitialAnswers] = useState([]);
    const [initialStateEditor, setInitialStateEditor] = useState({});
    const { id: questionId } = useParams();
    const [currentlyEditing, setCurrentlyEditing] = useState(false);

    useEffect(() => {
        if (formType === FORM_TYPES.edit || formType === FORM_TYPES.show) {
            getDataById(DATA_TYPES.question, questionId)
                .then((res) => {
                    if (res.error) {
                        navigate(NAV_LINKS.notFound);
                    } else {
                        const {
                            created_at,
                            updated_at,
                            id,
                            category,
                            grade,
                            section,
                            explanation,
                            content_html,
                            question_media,
                            explanation_media,
                            ...resData
                        } = res;

                        const answersList = res.answers.map((answer) => ({
                            id: answer.id,
                            content_html: answer.content_html,
                            media: handleMedia(answer?.media),
                            is_correct: Boolean(answer.is_correct),
                        }));

                        const answerData = {
                            ...resData,
                            content_html: content_html?.replaceAll('\r\n', '\n') ?? '',
                            explanation: explanation?.replaceAll('\r\n', '\n') ?? '',
                            question_media: handleMedia(question_media),
                            explanation_media: handleMedia(explanation_media),
                            answers: answersList,
                        };
                        setInitialStateEditor({
                            content_html: htmlToEditorState(answerData.content_html),
                            explanation: htmlToEditorState(answerData.explanation),
                            answers: answersList.map((answer) => htmlToEditorState(answer.content_html)),
                        });
                        setValues(answerData);
                        setData(answerData);
                        setInitialAnswers(
                            answersList.map((answer) => ({
                                id: answer.id,
                                content_html: answer.content_html,
                                is_correct: Boolean(answer.is_correct),
                                media: answer.media,
                            }))
                        );
                    }
                })
                .catch(() => navigate(NAV_LINKS.notFound))
                .finally(() => setIsFetching(false));
        }

        Promise.all([getData(DATA_TYPES.category, ALL_DATA), getData(DATA_TYPES.grade, ALL_DATA)])
            .then(([categoriesRes, gradesRes]) => {
                if (categoriesRes.error || gradesRes.error) {
                    navigate(NAV_LINKS.questions);
                    dispatch(openAlertError());
                } else {
                    setCategories(categoriesRes.data);
                    setGrades(gradesRes.data);
                }
            })
            .catch(() => {
                navigate(NAV_LINKS.notFound);
                dispatch(openAlertError());
            })
            .finally(() =>
                setIsFetchingAdditionalData((data) => ({
                    ...data,
                    categories: false,
                    grades: false,
                }))
            );
    }, []);
    const DataSchema = Yup.object().shape({
        content_html: Yup.string().required(),
        question_media: Yup.string().nullable(),
        content: Yup.string()
            .min(4, t('questionContentMinCharacters'))
            .max(255, t('questionContentMaxCharacters'))
            .required(t('questionContentRequired')),
        difficulty: Yup.number()
            .min(1, t('difficultyRangeValidation'))
            .max(10, t('difficultyRangeValidation'))
            .required(t('difficultyRequired')),
        time: Yup.number()
            .positive(t('timeValidation'))
            .required(t('timeRequired'))
            .typeError(t('timeValidation'))
            .max(3600, t('timeMax')),
        question_type: Yup.number().required(t('questionTypeRequired')),
        question_style: Yup.number().required(t('questionStyleRequired')),
        grade_id: Yup.number().required(t('gradeRequired')),
        category_id: Yup.number().required(t('categoryRequired')),
        section_id: Yup.number().required(t('sectionRequired')),
        status: Yup.number(),
        answers: Yup.array().of(
            Yup.object()
                .shape({
                    id: Yup.number(),
                    content_html: Yup.string().required(),
                    media: Yup.string().nullable(),
                    is_correct: Yup.boolean().required(),
                })
                .required()
        ),
        explanation: Yup.string(),
        explanation_media: Yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: QUESTION_SCHEMA,
        validationSchema: DataSchema,
    });
    const onSubmit = (redirect) =>
        manageData(values, formType === FORM_TYPES.add ? '' : questionId, redirect);

    const {
        errors,
        touched,
        isValid,
        values,
        setValues,
        setFieldTouched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
    } = formik;

    const manageData = (payload, id, redirect) => {
        setSubmitting(true);
        let putPayload = {};
        const { answers, ...questionData } = payload;
        const changedFields = Object.keys(questionData).filter(
            (element) => payload[element] !== data[element]
        );
        const handleFieldName = (field) => {
            if (field === QUESTION_FORM_FIELDS.explanation_media)
                return ID_FIELDS[QUESTION_FORM_FIELDS.explanation_media];
            if (field === QUESTION_FORM_FIELDS.question_media)
                return ID_FIELDS[QUESTION_FORM_FIELDS.question_media];
            return field;
        };

        changedFields.forEach((field) =>
            Object.assign(putPayload, { [handleFieldName(field)]: questionData[field] })
        );

        const changedAnswers = getArrayDiff(initialAnswers, answers).map((answer) => {
            const url = answer.media;
            if (!isValidUrl(url)) return answer;
            const { pathname, origin } = new URL(url);
            if (origin === new URL(process.env.REACT_APP_IMAGE_URL).origin) {
                return { ...answer, media: `/${pathname.split('/').pop()}` };
            }
            return answer;
        });

        console.log({ changedAnswers, initialAnswers, answers });
        if (changedAnswers.length) {
            putPayload = {
                answers: changedAnswers,
                ...putPayload,
            };
        }

        manageQuestion(id ? putPayload : payload, id)
            .then((res) => {
                if (res.success) {
                    navigate(`${NAV_LINKS.questions}${redirect ? `/${res.data.id}/edit` : ''}`);
                    dispatch(openAlertSuccess(res.success));
                } else {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch((error) => {
                console.error(error);
                dispatch(openAlertError());
            })
            .finally(() => setSubmitting(false));
    };

    const isCorrectAnswerChecked = () => values.answers.filter((answer) => answer.is_correct).length;

    const isFormCorrect = () =>
        isValid &&
        Object.keys(touched).length &&
        values.answers.length &&
        (!isEqual(values, data) || !isEqual(values.answers, initialAnswers));

    const isFormValid = () => isFormCorrect() && isCorrectAnswerChecked();

    const createTextField = (field) => (
        <TextField
            key={field}
            id={field}
            name={field}
            label={t(camelCase(QUESTION_FORM_FIELDS[field]))}
            value={values[field] ?? ''}
            onChange={(e) => {
                if (field === QUESTION_FORM_FIELDS.time) {
                    const val = e.target.value;
                    if (NUMBER_REGEX.test(val) || !val) {
                        setFieldValue(field, Number(val));
                    }
                } else {
                    handleChange(e);
                }
            }}
            onBlur={handleBlur}
            helperText={touched[field] && errors[field]}
            error={Boolean(touched[field] && errors[field])}
            onInput={() => setFieldTouched(field, true, true)}
            {...(QUESTION_FORM_FIELDS[field] === QUESTION_FORM_FIELDS.content
                ? {
                      multiline: true,
                      rows: 4,
                  }
                : {})}
        />
    );

    const getSelectValues = (type) => {
        switch (type) {
            case QUESTION_FORM_FIELDS.difficulty:
                return QUESTIONS_DIFFICULTY.map((difficulty) => ({
                    id: difficulty,
                    name: t(DIFFICULTIES[difficulty]),
                }));
            case QUESTION_FORM_FIELDS.grade_id:
                return grades;
            case QUESTION_FORM_FIELDS.category_id:
                return categories;
            case QUESTION_FORM_FIELDS.section_id:
                return sections;
            default:
                return [];
        }
    };

    const DESKTOP_WIDTH = 1366;
    const currentBrowserWidth = useBrowserWidth();
    const [gridApi, setGridApi] = useState(null);

    useEffect(() => {
        if (gridApi) {
            if (currentBrowserWidth >= DESKTOP_WIDTH) {
                gridApi.api.sizeColumnsToFit();
            }
        }
    }, [gridApi, currentBrowserWidth]);

    const createSelectField = (field, selectValues) => (
        <SelectField
            loading={field === ID_FIELDS.section && sectionLoading}
            key={field}
            field={field}
            label={QUESTION_FORM_FIELDS[field]}
            touched={touched}
            errors={errors}
            values={values}
            selectValues={selectValues}
            handleChange={(e, value) => {
                if (field === QUESTION_FORM_FIELDS.difficulty) {
                    handleChange(e);
                } else {
                    setFieldValue(field, value?.id ?? '');
                }
                formType === FORM_TYPES.add && setFieldTouched(field, true, false);
            }}
            handleBlur={handleBlur}
            autocomplete={field !== QUESTION_FORM_FIELDS.difficulty}
            disabled={!isCategoryAndGradeSelected() && field === ID_FIELDS.section}
        />
    );

    const getFieldType = () => {
        const {
            content_html,
            difficulty,
            question_style,
            question_type,
            grade_id,
            category_id,
            section_id,
            answers,
            media,
            explanation,
            explanation_media,
            question_media,
            ...textFields
        } = QUESTION_FORM_FIELDS;

        return {
            text: Object.keys(textFields),
            select: Object.keys({
                difficulty,
                grade_id,
                category_id,
                section_id,
            }),
        };
    };

    const getValue = (field) => {
        switch (field) {
            case ID_FIELDS.grade:
            case ID_FIELDS.category:
                return getSelectValues(QUESTION_FORM_FIELDS[field]).find(
                    (selectValue) => selectValue.id === values[field]
                )?.name;
            case ID_FIELDS.section:
                return sections.filter((section) => section.id === values[field])[0]?.title;
            case QUESTION_FORM_FIELDS.difficulty:
                return t(getQuestionDifficultyName(values[field]));
            default:
                return values[field];
        }
    };

    const generateInfoContainer = () => {
        const ROW_HEIGHT = 60;
        const fields = getFieldType();
        const valuesObj = {};

        [...fields.text, ...fields.select].forEach((question) => {
            Object.assign(valuesObj, {
                [QUESTION_FORM_FIELDS[question]]: getValue(question),
            });
        });
        const values = Object.keys(valuesObj).map((value) => ({
            description: t(value),
            data: valuesObj[value],
        }));

        const COLUMN_DEFS = [
            {
                field: INFO_FIELDS.description,
                headerName: t(INFO_FIELDS.description),
                headerTooltip: t(INFO_FIELDS.description),
                width: getColumnWidth(INFO_FIELDS.description),
                pinned: 'left',
                cellClass: 'ag-cell-center-justify',
            },
            {
                field: INFO_FIELDS.data,
                headerName: t(INFO_FIELDS.data),
                headerTooltip: t(INFO_FIELDS.data),
                width: getColumnWidth(INFO_FIELDS.data),
                cellClass: 'ag-cell-center-justify',
            },
        ];
        return (
            <FormTableWrapper
                className="ag-theme-material"
                height={`${(values.length + 1) * ROW_HEIGHT - 1}px`}
                isPreview
            >
                <AgGridReact
                    defaultColDef={{
                        sortable: false,
                        resizable: true,
                        autoHeight: true,
                        filter: false,
                        wrapText: true,
                    }}
                    rowData={values}
                    onGridReady={setGridApi}
                    rowHeight={ROW_HEIGHT}
                    columnDefs={COLUMN_DEFS}
                    tooltipShowDelay={0}
                    localeText={AG_GRID_LOCALE[i18n.language]}
                    suppressDragLeaveHidesColumns
                    stopEditingWhenCellsLoseFocus
                    loadingOverlayComponent={LoadingOverlay}
                />
            </FormTableWrapper>
        );
    };

    const generateTextFields = () => getFieldType().text.map((field) => createTextField(field));

    const generateSelectFields = () =>
        getFieldType().select.map((field) =>
            createSelectField(field, getSelectValues(QUESTION_FORM_FIELDS[field]))
        );

    const generateWysiwyg = (props) => {
        const wysiwygId = props.node.id;
        const initialStates = {
            [QUESTION_FORM_FIELDS.answers]: Object.keys(initialStateEditor).length
                ? initialStateEditor.answers.at(wysiwygId)
                : null,
            [QUESTION_FORM_FIELDS.content_html]: initialStateEditor.content_html,
            [QUESTION_FORM_FIELDS.explanation]: initialStateEditor.explanation,
        };

        return (
            <Wysiwyg
                initialState={initialStates[props.field]}
                height={350}
                isPreview={formType === FORM_TYPES.show}
                isEdit={formType === FORM_TYPES.edit}
                isCustomTable
                setCurrentlyEditing={setCurrentlyEditing}
                currentlyEditing={currentlyEditing}
                field={props.field}
                {...props}
            />
        );
    };

    const getColumnWidth = (field) => {
        const COLUMN_OFFSET = 2;
        switch (field) {
            case ANSWER_SCHEMA.ordinaryNumber:
                return isEditForm() && values.question_type !== 1
                    ? COLUMN_WIDTHS.ordinaryNumber.large
                    : COLUMN_WIDTHS.ordinaryNumber.small;
            case ANSWER_SCHEMA.media:
                return COLUMN_WIDTHS.media;
            case ANSWER_SCHEMA.content:
                return COLUMN_WIDTHS.content;
            case ANSWER_SCHEMA.isCorrect:
                return COLUMN_WIDTHS.isCorrect - COLUMN_OFFSET;
            case INFO_FIELDS.description:
                return COLUMN_WIDTHS.description;
            case INFO_FIELDS.data:
                return COLUMN_WIDTHS.data - COLUMN_OFFSET;
            default:
                return null;
        }
    };

    useEffect(() => {
        setFieldTouched(QUESTION_FORM_FIELDS.answers, true);
    }, [values.answers]);

    const isCategoryAndGradeSelected = () => values.grade_id && values.category_id;

    useEffect(() => {
        setSections([]);
        setSectionLoading(false);
        if (!data.section_id) {
            setFieldValue(ID_FIELDS.section, '');
        }
        setFieldTouched(ID_FIELDS.section, '', false);

        if (isCategoryAndGradeSelected()) {
            setSectionLoading(true);
            getSectionsByCategory(values.category_id, ALL_DATA, `grade_id=${values.grade_id}`)
                .then((res) => {
                    if (res.error) {
                        navigate(NAV_LINKS.questions);
                        dispatch(openAlertError(res.error));
                    } else {
                        setSections(res.data.data);
                    }
                })
                .catch(() => dispatch(openAlertError()))
                .finally(() => setSectionLoading(false));
        }
    }, [values.grade_id, values.category_id]);

    const WysiwygHeaderNames = {
        [QUESTION_FORM_FIELDS.content_html]: t('contentQuestionHtml'),
        [QUESTION_FORM_FIELDS.explanation]: t('explanationHtml'),
        [QUESTION_FORM_FIELDS.answers]: t('contentAnswerHtml'),
    };

    const getMediaValue = (params, field) => {
        switch (field) {
            case QUESTION_FORM_FIELDS.content_html: {
                return handleMedia(values[ID_FIELDS[QUESTION_FORM_FIELDS.question_media]]);
            }
            case QUESTION_FORM_FIELDS.explanation:
                return handleMedia(values[ID_FIELDS[QUESTION_FORM_FIELDS.explanation_media]]);
            case QUESTION_FORM_FIELDS.answers:
                return handleMedia(
                    values[QUESTION_FORM_FIELDS.answers][params.rowIndex][QUESTION_FORM_FIELDS.media]
                );
            default:
                return '';
        }
    };
    const generateColumnDefs = (field) => [
        field === QUESTION_FORM_FIELDS.answers && {
            field: ANSWER_SCHEMA.ordinaryNumber,
            headerName: t(camelCase(ANSWER_SCHEMA.ordinaryNumber)),
            valueGetter: (params) => getCharCode(params.rowIndex).toUpperCase(),
            width: getColumnWidth(ANSWER_SCHEMA.ordinaryNumber),
            minWidth: getColumnWidth(ANSWER_SCHEMA.ordinaryNumber),
        },
        {
            field: ANSWER_SCHEMA.content,
            headerName: WysiwygHeaderNames[field],
            headerTooltip: WysiwygHeaderNames[field],
            minWidth: getColumnWidth(ANSWER_SCHEMA.content),
            width: getColumnWidth(ANSWER_SCHEMA.content),
            cellRenderer: generateWysiwyg,
            cellRendererParams: () => ({
                onSubmit: setWysiwygData,
                field,
            }),
        },
        {
            field: ANSWER_SCHEMA.media,
            headerName: t(camelCase(ANSWER_SCHEMA.media)),
            headerTooltip: t(camelCase(ANSWER_SCHEMA.media)),
            minWidth: getColumnWidth(ANSWER_SCHEMA.media),
            cellRenderer: (params) => <MediaInput {...params} />,
            cellRendererParams: (params) => ({
                field,
                mediaValue: getMediaValue(params, field),
                onSubmit: (...args) => setMedia(...args, field),
                elementId: params.rowIndex,
                isPreview: formType === FORM_TYPES.show,
                margin: 'auto',
            }),
        },
        field === QUESTION_FORM_FIELDS.answers && {
            field: ANSWER_SCHEMA.isCorrect,
            headerName: t('correctAnswerShort'),
            headerTooltip: t('correctAnswer'),
            width: getColumnWidth(ANSWER_SCHEMA.isCorrect),
            minWidth: getColumnWidth(ANSWER_SCHEMA.isCorrect),
            cellRenderer: (params) => <AnswerCheckbox {...params} />,
            cellRendererParams: (params) => ({
                onChange: setAnswerCorrectness,
                isCorrect: !!values.answers[params.rowIndex].is_correct,
                disabled: formType === FORM_TYPES.show,
                ...params,
            }),
        },
    ];

    const BUTTONS_SX = {
        maxWidth: 300,
        ml: 4,
    };

    const setWysiwygData = (valueIndex, content_html, field) => {
        switch (field) {
            case QUESTION_FORM_FIELDS.content_html: {
                const fieldName = ID_FIELDS[field];
                setFieldValue(fieldName, content_html);
                break;
            }
            case QUESTION_FORM_FIELDS.explanation:
                setFieldValue(field, content_html);
                break;
            case QUESTION_FORM_FIELDS.answers:
                setFieldValue(
                    field,
                    values[QUESTION_FORM_FIELDS.answers].map((answer, index) =>
                        valueIndex === index ? { ...answer, content_html } : answer
                    )
                );
                break;
            default:
                console.warn('Unknown field');
                break;
        }
    };

    const setMedia = (answerIndex, media, field) => {
        const getPathName = (url) => {
            if (!isValidUrl(url)) return url;
            const { pathname, origin } = new URL(url);
            if (origin === new URL(process.env.REACT_APP_IMAGE_URL).origin) {
                return `/${pathname.split('/').pop()}`;
            }
            return url;
        };

        switch (field) {
            case QUESTION_FORM_FIELDS.content_html:
                setFieldValue(ID_FIELDS[QUESTION_FORM_FIELDS.question_media], getPathName(media));
                break;

            case QUESTION_FORM_FIELDS.explanation:
                setFieldValue(ID_FIELDS[QUESTION_FORM_FIELDS.explanation_media], getPathName(media));
                break;
            case QUESTION_FORM_FIELDS.answers:
                setFieldValue(
                    field,
                    values[field].map((answer, index) =>
                        answerIndex === index ? { ...answer, media: getPathName(media) } : answer
                    )
                );
                break;
            default:
                console.warn('Unknown field');
                break;
        }
    };

    const setAnswerCorrectness = (answerIndex, is_correct) => {
        setFieldValue(
            QUESTION_FORM_FIELDS.answers,
            values.answers.map((answer, index) =>
                answerIndex === index ? { ...answer, is_correct } : { ...answer, is_correct: false }
            )
        );
    };

    const isEditForm = () => formType === FORM_TYPES.edit || formType === FORM_TYPES.add;

    const checkSelected = () =>
        values.answers.map((item, index) => ({ ...item, index })).filter((item) => item.is_correct)[0];

    const isDataFetching = () => isFetching || !Object.values(isFetchingAdditionalData).every((val) => !val);

    return isDataFetching() ? (
        <LoadingOverlay fullScreen />
    ) : (
        <FormikProvider value={formik}>
            <FlexContainer>
                {formType === FORM_TYPES.show && (
                    <>
                        <MarginContainer>
                            <TitleContainer>
                                <SectionTitle tableMargin title={makeTitle({ formType, category })} />
                            </TitleContainer>
                        </MarginContainer>
                        <MarginContainer>
                            <FieldContainer>
                                <Field>{t('detailedInfo')}</Field>
                            </FieldContainer>
                        </MarginContainer>
                        <TableWrapper>{generateInfoContainer()}</TableWrapper>
                    </>
                )}
                {isEditForm() && (
                    <Form title={makeTitle({ formType, category })} onSubmit={handleSubmit}>
                        <Stack spacing={3} m={4}>
                            {generateTextFields()}
                            {generateSelectFields()}
                        </Stack>
                    </Form>
                )}
                <MarginContainer>
                    <FieldContainer>
                        <Field>{t(QUESTION_FORM_FIELDS.content_html)}</Field>
                    </FieldContainer>
                    <FormTableWrapper
                        editable={isEditForm()}
                        height={'min-content'}
                        width={'950px'}
                        isPreview={formType === FORM_TYPES.show}
                    >
                        <CustomTable
                            columnDefs={generateColumnDefs(QUESTION_FORM_FIELDS.content_html)}
                            rowData={
                                formType === FORM_TYPES.add
                                    ? { [ANSWER_SCHEMA.content]: '', [ANSWER_SCHEMA.media]: '' }
                                    : {
                                          [ANSWER_SCHEMA.content]: data.content_html,
                                          [ANSWER_SCHEMA.media]: data.question_media,
                                      }
                            }
                            tooltipShowDelay={0}
                        />
                    </FormTableWrapper>
                </MarginContainer>
                <MarginContainer>
                    <Container>
                        <Field>{t(QUESTION_FORM_FIELDS.answers)}</Field>
                        <FieldContainer>
                            {values.question_type !== 1 && (
                                <Button variant="contained" sx={BUTTONS_SX} startIcon={<AddIcon />}>
                                    {t('addNextAnswer')}
                                </Button>
                            )}
                        </FieldContainer>
                        <FormTableWrapper
                            editable={isEditForm()}
                            height={'min-content'}
                            width={'950px'}
                            isPreview={formType === FORM_TYPES.show}
                        >
                            <CustomTable
                                columnDefs={generateColumnDefs(QUESTION_FORM_FIELDS.answers)}
                                rowData={formType === FORM_TYPES.add ? DEFAULT_ANSWERS : data.answers}
                                tooltipShowDelay={0}
                                selected={checkSelected()}
                            />
                        </FormTableWrapper>
                        <WarningInfo
                            displayWarning={isFormCorrect() && !isCorrectAnswerChecked()}
                            warningText={'fillCorrectAnswerWarning'}
                            width={'950px'}
                        />
                    </Container>
                    <Container>
                        <FieldContainer>
                            <Field>{t(QUESTION_FORM_FIELDS.explanation)}</Field>
                        </FieldContainer>
                        <FormTableWrapper
                            editable={isEditForm()}
                            height={'min-content'}
                            width={'950px'}
                            isPreview={formType === FORM_TYPES.show}
                        >
                            <CustomTable
                                columnDefs={generateColumnDefs(QUESTION_FORM_FIELDS.explanation)}
                                rowData={
                                    formType === FORM_TYPES.add
                                        ? { [ANSWER_SCHEMA.content]: '', [ANSWER_SCHEMA.media]: '' }
                                        : {
                                              [ANSWER_SCHEMA.content]: data.explanation,
                                              [ANSWER_SCHEMA.media]: data.explanation_media,
                                          }
                                }
                                tooltipShowDelay={0}
                            />
                        </FormTableWrapper>
                        <WidthContainer width={'950px'}>
                            <MarginBlock>
                                <FormButtons
                                    formType={formType}
                                    isFormValid={!!isFormValid() && !currentlyEditing}
                                    onSubmit={() => onSubmit(false)}
                                    navLink={isEditForm() ? NAV_LINKS.questions : -1}
                                    isLoading={currentlyEditing || isSubmitting}
                                    disabled={isSubmitting}
                                />
                            </MarginBlock>
                        </WidthContainer>
                    </Container>
                </MarginContainer>
            </FlexContainer>
        </FormikProvider>
    );
};

QuestionForm.propTypes = {
    formType: PropTypes.oneOf([FORM_TYPES.add, FORM_TYPES.edit, FORM_TYPES.show]).isRequired,
};

export default QuestionForm;
