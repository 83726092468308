import { Container, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import { Claws } from '../../components/Forms/LoginForm/LoginForm.styles';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Page from '../../components/Page/Page';
import { LOCAL_STORAGE_KEY, NAV_LINKS } from '../../utils/constants';
import { getUser } from '../../utils/fetchData';
import { getToken } from '../../utils/helpers';
import { ContentStyle, RootStyle } from '../Page.styles';

const LoginPage = () => {
    const { t } = useTranslation('');
    const navigate = useNavigate();
    const userToken = getToken();
    const clearUserInfo = () => localStorage.removeItem(LOCAL_STORAGE_KEY.user);
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();

    const disableLoading = () => setIsLoading(false);

    useEffect(() => {
        setIsLoading(true);
        if (userToken) {
            getUser()
                .then((res) => {
                    if (res.error) {
                        clearUserInfo();
                    } else {
                        navigate(NAV_LINKS.dashboard);
                    }
                })
                .catch(clearUserInfo)
                .finally(disableLoading);
        } else {
            disableLoading();
        }
    }, []);

    return (
        <Page>
            <RootStyle>
                <Container maxWidth="sm">
                    <ContentStyle>
                        {isLoading ? (
                            <LoadingSpinner color={theme.palette.common.red} size={60} loading />
                        ) : (
                            <>
                                <Typography variant="h4" gutterBottom>
                                    {t('signIn')}
                                </Typography>

                                <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                                    {t('signInText')}
                                </Typography>

                                <LoginForm />
                            </>
                        )}
                    </ContentStyle>
                </Container>
            </RootStyle>
            <Claws src="/static/claws.svg" alt="claws" />
        </Page>
    );
};

export default LoginPage;
