import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled, { css } from 'styled-components';
import { QUESTION_FORM_FIELDS } from '../../utils/constants';

const MARGIN = '8px';
const BORDER_RADIUS = '6px';

export const EditorWrapper = styled.div`
    ${({ htmlEditMode }) =>
        htmlEditMode &&
        css`
            .rdw-editor-main {
                display: none;
            }
        `};

    .rdw-editor-main {
        height: ${({ height }) => height}px;
        background-color: white;
        border: 1px solid ${({ theme }) => theme.palette.tableBorder};
        border-bottom-left-radius: ${BORDER_RADIUS};
        border-bottom-right-radius: ${BORDER_RADIUS};
        margin-top: -6px;
        .DraftEditor-root {
            height: auto;
        }
    }

    ${({ field }) => {
        switch (field) {
            case QUESTION_FORM_FIELDS.content_html:
                return css`
                    .rdw-editor-main {
                        color: ${({ theme }) => theme.palette.secondary.main};
                        font-size: 16px;
                        background-color: ${({ theme }) => theme.palette.primary.main};
                        .DraftEditor-editorContainer {
                            padding: 15px;
                        }
                    }
                `;
            case QUESTION_FORM_FIELDS.answers:
            case QUESTION_FORM_FIELDS.explanation:
            default:
                return css`
                    .rdw-editor-main {
                        color: ${({ theme }) => theme.palette.text.primary};
                        font-size: 14px;
                        background-color: ${({ theme }) => theme.palette.secondary.main};
                        .DraftEditor-editorContainer {
                            padding: 10px;
                        }
                    }
                `;
        }
    }}

    .rdw-option-wrapper {
        padding: 15px 10px !important;
    }

    .rdw-image-modal-url-input {
        width: 95%;
    }
    .rdw-image-modal-btn-section button {
        width: 100%;
        margin: 0;
    }
    .rdw-image-modal-btn-section {
        margin: 10px 0 0;
        display: flex;
        gap: 0.4rem;
    }
    .rdw-embedded-modal-url-input {
        width: 95%;
    }
    .rdw-embedded-modal-btn-section button {
        width: 100%;
        margin: 0;
    }
    .rdw-embedded-modal-btn-section {
        display: flex;
        gap: 0.4rem;
    }

    .rdw-embedded-modal-size {
        display: none;
    }

    .rdw-image-modal-header > span {
        width: min-content;
        align-items: start;
        white-space: nowrap;
    }
    .rdw-image-modal-header > span > span {
        width: 100%;
    }
    .rdw-embedded-modal-header > span {
        width: min-content;
        align-items: start;
        white-space: nowrap;
    }
    .rdw-embedded-modal-header > span > span {
        width: 100%;
    }

    .rdw-editor-toolbar {
        border: 1px solid ${({ theme }) => theme.palette.tableBorder};
        border-top-left-radius: ${BORDER_RADIUS};
        border-top-right-radius: ${BORDER_RADIUS};
    }

    .rdw-editor-main iframe {
        aspect-ratio: 16 / 9;
        width: 100%;
    }

    .rdw-fontfamily-dropdown {
        width: 200px;
    }

    .rdw-link-decorator-wrapper a {
        color: blue;
    }

    .rdw-fontfamily-placeholder {
        color: black;
        max-width: 100%;
    }

    .rdw-block-dropdown {
        width: 130px;
    }

    .rdw-embedded-modal {
        width: 270px;
    }

    .rdw-link-modal {
        height: 250px;
    }

    .rdw-fontsize-dropdown {
        width: 50px;
    }

    .rdw-dropdown-selectedtext {
        color: black;
    }

    .DraftEditor-editorContainer {
        margin: 0 ${MARGIN};
    }
`;
