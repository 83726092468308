import { Button, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Iconify from '../../Iconify/Iconify';

const AddButton = ({ onClick, imageInput, title, icon, size, disabled, tooltip }) => {
    const { t } = useTranslation();
    const ACCEPTED_FILE_TYPES = '.jpg, .jpeg, .png';

    return (
        <Tooltip title={t(tooltip)}>
            <span>
                <Button
                    disabled={disabled}
                    variant="contained"
                    size={size}
                    startIcon={<Iconify icon={icon ?? 'eva:plus-fill'} />}
                    sx={{
                        ml: 1,
                        borderColor: (theme) => theme.palette.primary.main,
                        borderWidth: 2,
                        borderStyle: 'solid',
                        ':hover': {
                            borderColor: (theme) => theme.palette.primary.dark,
                        },
                        ':disabled': {
                            borderColor: 'transparent',
                        },
                    }}
                    {...(imageInput
                        ? {
                              component: 'label',
                          }
                        : {
                              onClick,
                          })}
                >
                    {t(!title ? 'add' : title)}
                    {imageInput && (
                        <input hidden accept={ACCEPTED_FILE_TYPES} type="file" onChange={onClick} />
                    )}
                </Button>
            </span>
        </Tooltip>
    );
};

AddButton.defaultProps = {
    imageInput: false,
    title: 'add',
    icon: 'eva:plus-fill',
    size: 'small',
};

AddButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    imageInput: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.string,
    size: PropTypes.string,
};

export default AddButton;
