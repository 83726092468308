import { invert } from 'lodash';

export const COMPANY_NAME = 'PROFI COMPETENCE';
export const APP_NAME = 'Gryzli';

export const LANGUAGE_EN = 'en';
export const LANGUAGE_PL = 'pl';

export const DEBOUNCE_TIME = 800;
export const DESKTOP_WIDTH = 1200;

export const LOCAL_STORAGE_KEY = {
    language: 'language',
    user: 'user',
    token: 'token',
};

export const ALERT_TYPES = {
    success: 'success',
    error: 'error',
    warning: 'warning',
};

export const HTTP_ERRORS = {
    notFound: {
        text: 'notFound',
        code: 404,
    },
    unauthorized: {
        text: 'unauthorized',
        code: 401,
    },
};

export const NAV_LINKS = {
    login: '/login',
    notFound: '/404',
    unauthorized: '/401',
    recoverPassword: '/recover-password',
    dashboard: '/dashboard',
    userProfile: '/user-profile',
    editProfile: '/user-profile/edit',
    changePassword: '/user-profile/change-password',
    organizations: '/organizations',
    organizationUsers: '/organization-users',
    participants: '/participants',
    participantGroups: '/participant-groups',
    administrators: '/administrators',
    categories: '/categories',
    grades: '/grades',
    questions: '/questions',
    packages: '/packages',
    quizScoring: '/quiz-scoring',
    products: '/products',
    orders: '/orders',
    logActivity: '/log-activity',
};

export const ROUTES = {
    absolute: '/',
    asterisk: '*',
    admin: 'admin',
    dashboard: 'dashboard',
    app: 'app',
    login: 'login',
    notFound: '404',
    unauthorized: '401',
    setProfile: 'set-profile',
    resetPassword: 'reset-password',
    recoverPassword: 'recover-password',
    userProfile: {
        main: 'user-profile',
        edit: 'user-profile/edit',
        changePassword: 'user-profile/change-password',
    },
    participants: {
        main: 'participants',
        add: 'participants/add',
        edit: 'participants/:id/edit',
    },
    organizations: {
        main: 'organizations',
        add: 'organizations/add',
        edit: 'organizations/:id/edit',
        packages: 'organizations/:id/packages?/:packageId',
    },
    organizationUsers: {
        main: 'organization-users',
        add: 'organization-users/add',
        edit: 'organization-users/:id/edit',
    },
    participantGroups: {
        main: 'participant-groups',
        add: 'participant-groups/add',
        edit: 'participant-groups/:id/edit',
        participants: {
            main: 'participant-groups/:id/participants',
            edit: 'participant-groups/:groupId/participants/:id/edit',
        },
    },
    administrators: {
        main: 'administrators',
        add: 'administrators/add',
        edit: 'administrators/:id/edit',
    },
    questions: {
        main: 'questions',
        add: 'questions/add',
        edit: 'questions/:id/edit',
        show: 'questions/:id/show',
    },
    categories: {
        main: 'categories',
        add: 'categories/add',
        edit: 'categories/:id/edit',
        sections: {
            main: 'categories/:id/sections',
            add: 'categories/:id/sections/add',
            edit: 'categories/:id/sections/:sectionId/edit',
        },
    },
    grades: {
        main: 'grades',
        add: 'grades/add',
        edit: 'grades/:id/edit',
    },
    packages: {
        main: 'packages',
        add: 'packages/add',
        edit: 'packages/:id/edit',
        quizzes: {
            main: 'packages/:id/quiz',
            questions: 'packages/:id/quiz/questions',
            parameters: 'packages/:id/quiz/parameters',
            results: 'packages/:id/quiz/results',
        },
        trainings: {
            main: 'packages/:id/training',
            questions: 'packages/:id/training/questions',
        },
    },
    quizScoring: 'quiz-scoring',
    products: {
        main: 'products',
        add: 'products/add',
        edit: 'products/:id/edit',
        show: 'products/:id/show',
    },
    orders: {
        main: 'orders',
        add: 'orders/add',
    },
    logActivity: {
        main: 'log-activity',
    },
};

export const USER_ROLES = {
    superAdmin: 1,
    organizationAdmin: 2,
    organizationEmployee: 3,
    participant: 4,
};

export const ALL_DATA = 'all';

export const USER_ORGANIZATIONS_ROLES = ['organizationAdmin', 'organizationEmployee'];

export const USER_SEX = {
    male: 'M',
    female: 'F',
};

export const PERMISSIONS_TYPES = {
    view: 'view',
    manage: 'manage',
};

export const FORM_TYPES = {
    add: 'add',
    edit: 'edit',
    send: 'send',
    save: 'save',
    reset: 'reset',
    change: 'change',
    show: 'show',
    confirm: 'confirm',
    apply: 'apply',
    nextStep: 'nextStep',
    delete: 'delete',
};

export const TEST_STATUS = {
    new: 'new',
    inProgress: 'inProgress',
    finished: 'finished',
};

export const DATA_TYPES = {
    user: 'user',
    account: 'account',
    organization: 'organization',
    organizationUser: 'organization/user',
    participant: 'user/participant',
    administrator: 'user/superadmin',
    participantGroup: 'participant-group',
    groupParticipant: 'user/participant/group',
    category: 'category',
    grade: 'grade',
    question: 'question',
    section: 'section',
    package: 'package',
    userPackages: 'user/package',
    quiz: 'quiz/question',
    quizParameters: 'quiz/parameters',
    quizScoring: 'scoring',
    quizResults: 'results',
    training: 'training/question',
    media: 'media',
    product: 'commerce/product',
    order: 'commerce/order',
    logActivity: 'log-activity',
};

export const TABLE_FILTERS = {
    empty: 'empty',
    contains: 'contains',
};

export const FILTER_TYPES = {
    id: 'id',
    name: 'name',
    question: 'question',
};

export const MANAGE_DATA_TYPES = {
    attach: 'attach',
    detach: 'detach',
};

export const CATEGORIES = {
    dashboard: 'dashboard',
    organizations: 'organizations',
    participants: 'participants',
    administrators: 'administrators',
    organizationUsers: 'organizationUsers',
    participantGroups: 'participantGroups',
    categories: 'categories',
    grades: 'grades',
    questions: 'questions',
    sections: 'sections',
    packages: 'packages',
    quizzes: 'quizzes',
    quizScoring: 'quizScoring',
    trainings: 'trainings',
    images: 'images',
    scoring: 'scoring',
    results: 'results',
    products: 'products',
    orders: 'orders',
    changePassword: 'changePassword',
    userPackage: 'userPackage',
    logActivity: 'logActivity',
};

export const TABLE_SETTINGS = {
    administrator: {
        name: CATEGORIES.administrators,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'email',
            },
            {
                field: 'last_name',
                headerName: 'lastName',
            },
            {
                field: 'first_name',
                headerName: 'firstName',
            },
            {
                field: 'sex',
            },
        ],
    },
    organization: {
        name: CATEGORIES.organizations,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'name',
                headerName: 'organizationName',
            },
            {
                field: 'short_name',
                headerName: 'shortOrganizationName',
            },
            {
                field: 'nip',
            },
            {
                field: 'regon',
            },
        ],
    },
    participant: {
        name: CATEGORIES.participants,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'last_name',
                headerName: 'lastName',
            },
            {
                field: 'first_name',
                headerName: 'firstName',
            },
            {
                field: 'nickname',
            },
            {
                field: 'email',
            },
            {
                field: 'sex',
            },
            {
                field: 'organizations',
            },
            {
                field: 'participant_groups',
                headerName: 'participantGroups',
            },
        ],
    },
    organizationUser: {
        name: CATEGORIES.organizationUsers,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'email',
            },
            {
                field: 'last_name',
                headerName: 'lastName',
            },
            {
                field: 'first_name',
                headerName: 'firstName',
            },
            {
                field: 'sex',
            },
            {
                field: 'organizations',
            },
        ],
    },
    groupParticipant: {
        name: CATEGORIES.participantGroups,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'last_name',
                headerName: 'lastName',
            },
            {
                field: 'first_name',
                headerName: 'firstName',
            },
            {
                field: 'nickname',
            },
            {
                field: 'email',
            },
            {
                field: 'sex',
            },
        ],
    },
    participantGroup: {
        name: CATEGORIES.participantGroups,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'name',
            },
            {
                field: 'description',
            },
            {
                field: 'organization',
            },
        ],
    },
    category: {
        name: CATEGORIES.categories,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'name',
            },
        ],
    },
    grade: {
        name: CATEGORIES.grades,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'name',
            },
        ],
    },
    question: {
        name: CATEGORIES.questions,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'content',
                headerName: 'questionContent',
            },
            {
                field: 'difficulty',
            },
            {
                field: 'category',
            },
            {
                field: 'grade',
            },
            {
                field: 'section',
            },
        ],
    },
    section: {
        name: CATEGORIES.sections,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'title',
            },
            {
                field: 'grade',
            },
        ],
    },
    package: {
        name: CATEGORIES.packages,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'name',
            },
            {
                field: 'description',
            },
            {
                field: 'category',
            },
            {
                field: 'grade',
            },
        ],
    },
    quiz: {
        name: CATEGORIES.quizzes,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'content',
                headerName: 'questionContent',
            },
            {
                field: 'difficulty',
            },
            {
                field: 'category',
            },
            {
                field: 'grade',
            },
        ],
    },
    quizScoring: {
        name: CATEGORIES.quizScoring,
        tableHead: [
            {
                field: 'round',
            },
            {
                field: 'easyPlural',
            },
            {
                field: 'mediumPlural',
            },
            {
                field: 'difficultPlural',
            },
        ],
    },
    training: {
        name: CATEGORIES.trainings,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'content',
                headerName: 'questionContent',
            },
            {
                field: 'difficulty',
            },
            {
                field: 'category',
            },
            {
                field: 'grade',
            },
        ],
    },
    media: {
        name: CATEGORIES.images,
        tableHead: [
            {
                field: 'image',
                headerName: 'imagePreview',
            },
            {
                field: 'link',
            },
        ],
    },
    product: {
        name: CATEGORIES.products,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'name',
            },
            {
                field: 'price',
            },
        ],
    },
    order: {
        name: CATEGORIES.orders,
        tableHead: [
            {
                field: 'id',
            },
            {
                field: 'created_at',
                headerName: 'orderDate',
            },
            {
                field: 'product',
            },
            {
                field: 'organization',
            },
            {
                field: 'user',
                headerName: 'purchaser',
            },
            {
                field: 'orderStatus',
            },
        ],
    },
    logActivity: {
        name: CATEGORIES.logActivity,
        tableHead: [
            {
                field: 'created_at',
                headerName: 'creationDate',
            },
            {
                field: 'type',
            },
            {
                field: 'user',
            },
            {
                field: 'comment',
            },
        ],
    },
    quizResults: {
        name: CATEGORIES.results,
        tableHead: [
            {
                field: 'user_id',
                headerName: 'id',
            },
            {
                field: 'last_name',
                headerName: 'lastName',
            },
            {
                field: 'first_name',
                headerName: 'firstName',
            },
            {
                field: 'nickname',
            },
            {
                field: 'score',
            },
            {
                field: 'ranking',
            },
            {
                field: 'attempts',
            },
        ],
    },
};

export const QUESTIONS_DIFFICULTY = [3, 5, 7];

export const DIFFICULTY_NAMES = {
    easy: 'easy',
    medium: 'medium',
    difficult: 'difficult',
};

export const DIFFICULTIES = {
    3: 'easyPlural',
    5: 'mediumPlural',
    7: 'difficultPlural',
};

export const ROUNDS_INFO = {
    3: 'bonus',
    4: 'risky',
};

export const POINTS_INFO = {
    gain: 'gain',
    loss: 'loss',
};

export const ANSWER_SCHEMA = {
    ordinaryNumber: 'ordinary_number',
    id: 'id',
    content: 'content_html',
    media: 'media',
    order: 'order',
    isCorrect: 'is_correct',
    answers: 'answers',
};

export const LOADING_SPINNER_TYPES = { hash: 'hash', clip: 'clip' };

export const SELECT_FIELDS = {
    sex: 'sex',
    role: 'role',
};

export const YOUTUBE_LINK_EMBED = 'https://www.youtube.com/embed/';

export const YOUTUBE_LINK_REGEX =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

export const DECIMAL_REGEX = /^\d+(,\d{0,2})?$/;

export const NUMBER_REGEX = /^\d+$/;

export const IMAGE_LINK_REGEX = /\.(jpeg|jpg|png)$/;

export const MEDIA_LINK = 'media/';

export const CURRENCY = 'PLN';

export const QUESTION_FORMS = {
    add: 'questionAddForm',
    edit: 'questionEditForm',
    show: 'questionShowForm',
};

export const getImageLink = (url) => `${process.env.REACT_APP_IMAGE_URL}/${url.split('/').pop()}`;

export const MANAGE_DATA = {
    upload: 'upload',
    delete: 'delete',
};

export const MEDIA_ALERTS = {
    success: 'MediaSuccess',
    error: 'MediaError',
};

export const ACTION_BUTTONS = {
    htmlEditor: 'htmlEditor',
    imageGallery: 'imageGallery',
};

export const MEDIA_USED_MESSAGE = 'Media used';

export const INFO_MESSAGE = 'Info';

export const GRAMMATICAL_NUMBERS = {
    singular: 'Singular',
    plural: 'Plural',
};

export const COLUMN_WIDTHS = {
    ordinaryNumber: {
        large: 110,
        small: 65,
    },
    media: 250,
    content: 400,
    isCorrect: 110,
    description: 200,
    data: 1125,
    checkbox: 65,
    tableMoreMenu: 55,
    selection: 65,
    tableMenu: 50,
    packageName: 400,
    deleteButton: 150,
    price: 350,
    image: 150,
    id: 105,
    orderStatus: 150,
    expirationDate: 180,
    date: 200,
    user: 180,
    type: 280,
    groups: 400,
    fullName: 100,
    expiresAt: 100,
};

export const WIDTHS = {
    button: 120,
};

export const ORDER_STATUS = {
    1: 'new',
    2: 'awaitingPayment',
    3: 'completed',
    4: 'canceled',
};

export const ORDER_STATUS_NAMES = invert(ORDER_STATUS);

export const LIST_ORDER_STATUS = Object.values(ORDER_STATUS);

export const USER_PACKAGE_STATUS = {
    assigned: 1,
    inProgress: 2,
    completed: 3,
    canceled: 4,
};

export const TABLE_FIELDS = {
    id: 'id',
    role: 'role',
    sex: 'sex',
    ordinaryNumber: 'ordinary_number',
    tableMenu: 'table_menu',
    organizations: 'organizations',
    organization: 'organization',
    participantGroups: 'participant_groups',
    participantGroupUser: 'participant_group_user',
    organizationName: 'organization_name',
    groupName: 'group_name',
    difficulty: 'difficulty',
    difficultyLevels: {
        easy: 'easyPlural',
        medium: 'mediumPlural',
        difficult: 'difficultPlural',
    },
    round: 'round',
    category: 'category',
    grade: 'grade',
    grades: 'grades',
    question: 'question',
    author: 'author',
    user: 'user',
    status: 'status',
    image: 'image',
    link: 'link',
    section: 'section',
    content: 'content',
    price: 'price',
    product: 'product',
    orderStatus: 'orderStatus',
    selection: 'selection',
    firstName: 'first_name',
    lastName: 'last_name',
    fullName: 'full_name',
    nickname: 'nickname',
    email: 'email',
    groups: 'participant_groups',
    expirationDate: 'expiration_date',
    assignmentDate: 'assignment_date',
    expiresAt: 'expires_at',
    checkbox: 'checkbox',
    tableMoreMenu: 'table_more_menu',
    packageName: 'package_name',
    deleteButtonQuiz: 'delete_button_quiz',
    deleteButtonTraining: 'delete_button_training',
    type: 'type',
    createdAt: 'created_at',
};

export const OVERLAY_TYPES = {
    modal: 'modal',
    minContent: 'minContent',
    noRows: 'noRows',
    userPackages: 'userPackages',
};

export const TABLE_OPTIONS = {
    filter: 'filter',
    sort: 'sort',
};

export const PACKAGE_PARTS = {
    quiz: 'quiz',
    training: 'training',
};

export const QUESTION_FORM_FIELDS = {
    content: 'questionContent',
    content_html: 'contentHtml',
    difficulty: 'difficulty',
    time: 'time',
    question_type: 'questionType',
    question_style: 'questionStyle',
    grade_id: 'grade',
    category_id: 'category',
    section_id: 'section',
    media: 'media',
    answers: 'answers',
    explanation: 'explanation',
    explanation_media: 'explanationMedia',
    question_media: 'questionMedia',
};
