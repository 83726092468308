import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Modal, Tooltip } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ImageGalleryIcon from '../../../assets/icons/image-gallery.svg';
import {
    ACTION_BUTTONS,
    FORM_TYPES,
    IMAGE_LINK_REGEX,
    QUESTION_FORM_FIELDS,
    YOUTUBE_LINK_EMBED,
    YOUTUBE_LINK_REGEX,
} from '../../../utils/constants';
import { getYoutubeVideoId, isValidUrl } from '../../../utils/helpers';
import FormButtons from '../../Form/FormButtons/FormButtons';
import ActionButton from '../../Wysiwyg/ActionButton/ActionButton';
import ImageGallery from '../../Wysiwyg/ImageGallery/ImageGallery';
import {
    ButtonsContainer,
    InputWrapper,
    ModalContainer,
    StyledTab,
    StyledTextField,
} from './MediaModal.styles';

const MediaModal = ({ field, mediaValue, open, handleClose, onSubmit, elementId }) => {
    const { t } = useTranslation();
    const MIN_HEIGHT = 220;

    const MEDIA_TYPES = {
        image: {
            value: '1',
            name: 'image',
            label: t('imageLink'),
            info: t('uploadImage'),
            deleteInfo: t('deleteImageInfo'),
        },
        video: {
            value: '2',
            name: 'video',
            label: t('ytLink'),
            info: t('uploadVideo'),
            deleteInfo: t('deleteVideoInfo'),
            disabledInfo: t('disabledVideoInfo'),
        },
    };

    const DataSchema = Yup.object().shape({
        media: Yup.string()
            .test('image_link_validator', t('imageLinkValidator'), (val) => validateImageLink(val))
            .test('youtube_link_validator', t('ytLinkValidator'), (val) => validateYouTubeLink(val)),
    });

    const formik = useFormik({
        initialValues: { [field]: mediaValue },
        validationSchema: DataSchema,
    });

    const { errors, touched, values, isValid, setFieldTouched, setFieldValue, handleChange, handleBlur } =
        formik;

    const [mediaType, setMediaType] = useState(MEDIA_TYPES.image.value);
    const [isImageGalleryActive, setIsImageGalleryActive] = useState(false);

    const openImageGallery = () => setIsImageGalleryActive(true);

    const closeImageGallery = () => setIsImageGalleryActive(false);

    const handleTabChange = (_, type) => setMediaType(type);

    useEffect(() => {
        if (!values[field]) {
            setFieldTouched(field, false, false);
        }
    }, [values]);

    useEffect(() => {
        setFieldValue(field, mediaValue);
    }, [mediaValue]);

    const validateImageLink = (val) => {
        if (!val || mediaType === MEDIA_TYPES.video.value) {
            return true;
        }
        return (
            isValidUrl(val) &&
            new URL(val).origin === new URL(process.env.REACT_APP_IMAGE_URL).origin &&
            val.match(IMAGE_LINK_REGEX)
        );
    };

    const validateYouTubeLink = (val) => {
        if (!val || mediaType === MEDIA_TYPES.image.value) {
            return true;
        }

        return val.match(YOUTUBE_LINK_REGEX);
    };

    const isFormValid = () => !!(isValid && touched[field] && !errors[field] && values[field]);

    const confirmValue = () => {
        let mediaValue = values[field];
        if (!isEmpty(mediaValue) && mediaValue.match(YOUTUBE_LINK_REGEX)) {
            mediaValue = `${YOUTUBE_LINK_EMBED}${getYoutubeVideoId(mediaValue)}`;
        }

        onSubmit(elementId ?? field, mediaValue);
        handleClose();
    };

    const clearValues = () => {
        setFieldValue(field, mediaValue);
        setFieldTouched(field, false, false);
    };

    const closeModal = () => {
        handleClose(clearValues);
    };

    const renderButtonsContainer = () => (
        <ButtonsContainer marginBottom={'2rem'}>
            <FormButtons
                formType={FORM_TYPES.confirm}
                isFormValid={isFormValid()}
                onSubmit={confirmValue}
                onBack={closeModal}
            />
        </ButtonsContainer>
    );

    const renderTabLabel = (mediaType) => {
        const getToolTipValue = (mediaType) => {
            if (mediaType === MEDIA_TYPES.image.name && isTabDisabled(MEDIA_TYPES.video.value)) {
                return MEDIA_TYPES.video.deleteInfo;
            }

            if (mediaType === MEDIA_TYPES.video.name && isTabDisabled(MEDIA_TYPES.image.value)) {
                return field === QUESTION_FORM_FIELDS.answers
                    ? MEDIA_TYPES.video.disabledInfo
                    : MEDIA_TYPES.image.deleteInfo;
            }

            return '';
        };

        return (
            <Tooltip title={getToolTipValue(mediaType.name)}>
                <span>{mediaType.info}</span>
            </Tooltip>
        );
    };

    const isTabDisabled = (media) => {
        const isVideoDisabledForAnswer =
            field === QUESTION_FORM_FIELDS.answers && media !== MEDIA_TYPES.video.value;
        return isVideoDisabledForAnswer || (mediaType === media && values.media);
    };

    const generateTextField = (label) => (
        <InputWrapper>
            <StyledTextField
                key={field}
                id={field}
                name={field}
                label={label}
                value={values[field] ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched[field] && errors[field]}
                error={Boolean(touched[field] && errors[field])}
                onInput={() => setFieldTouched(field, true, true)}
            />
            {mediaType === MEDIA_TYPES.image.value && (
                <>
                    <ActionButton
                        key={ACTION_BUTTONS.imageGallery}
                        title={ACTION_BUTTONS.imageGallery}
                        isActive={isImageGalleryActive}
                        onClick={openImageGallery}
                        icon={ImageGalleryIcon}
                        width={35}
                        height={35}
                    />
                    <ImageGallery isOpen={isImageGalleryActive} handleClose={closeImageGallery} />
                </>
            )}
        </InputWrapper>
    );

    return (
        <Modal open={open} onClose={handleClose}>
            <FormikProvider value={formik}>
                <ModalContainer>
                    <TabContext value={mediaType}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange}>
                                <StyledTab
                                    sx={{ ml: 1 }}
                                    value={MEDIA_TYPES.image.value}
                                    disabled={isTabDisabled(MEDIA_TYPES.video.value)}
                                    label={renderTabLabel(MEDIA_TYPES.image)}
                                />
                                <StyledTab
                                    sx={{
                                        mr: 1,
                                    }}
                                    value={MEDIA_TYPES.video.value}
                                    disabled={isTabDisabled(MEDIA_TYPES.image.value)}
                                    label={renderTabLabel(MEDIA_TYPES.video)}
                                />
                            </TabList>
                        </Box>
                        <TabPanel
                            value={MEDIA_TYPES.image.value}
                            sx={{
                                minHeight: MIN_HEIGHT,
                            }}
                        >
                            {generateTextField(MEDIA_TYPES.image.label)}
                            {renderButtonsContainer()}
                        </TabPanel>
                        <TabPanel
                            value={MEDIA_TYPES.video.value}
                            sx={{
                                minHeight: MIN_HEIGHT,
                            }}
                        >
                            {generateTextField(MEDIA_TYPES.video.label)}
                            {renderButtonsContainer()}
                        </TabPanel>
                    </TabContext>
                </ModalContainer>
            </FormikProvider>
        </Modal>
    );
};

MediaModal.defaultProps = {
    elementId: null,
};

MediaModal.propTypes = {
    field: PropTypes.string.isRequired,
    mediaValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    elementId: PropTypes.number,
};

export default MediaModal;
