import styled from 'styled-components';

export const ModalContainer = styled.div`
    width: 90%;
    max-width: 600px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    border-radius: 8px;

    & > div {
        margin: 0;
    }
`;

export const ModalTitle = styled.p`
    font-weight: bold;
`;

export const ModalText = styled.p`
    font-family: ${({ theme }) => theme.typography.fontFamily};
    margin-top: 1rem;
    margin-bottom: 1.5rem;
`;
