import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { openAlertError, openAlertSuccess } from '../../../actions/alertActions';
import { FORM_TYPES, NAV_LINKS } from '../../../utils/constants';
import { changePassword, resetPassword } from '../../../utils/fetchData';
import { makeTitle } from '../../../utils/helpers';
import { FIELDS, useValidators } from '../../../utils/validators';
import Form from '../../Form/Form';
import { MarginInline } from '../../Form/Form.styles';
import FormButtons from '../../Form/FormButtons/FormButtons';
import Iconify from '../../Iconify/Iconify';

const PasswordForm = ({ token, formType, category }) => {
    const PASSWORD_FORM_FIELDS = {
        password: 'password',
        password_confirmation: 'password_confirmation',
        token: 'token',
    };

    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const validators = useValidators();

    const BASIC_PASSWORD_VALUES = {
        password: '',
        password_confirmation: '',
    };

    const BASIC_PASSWORD_SCHEMA = {
        password: validators[FIELDS.password].matches,
        password_confirmation: validators[FIELDS.password].confirmation,
    };

    const getPasswordSchema = {
        change: Yup.object().shape({
            ...BASIC_PASSWORD_SCHEMA,
        }),
        reset: Yup.object().shape({
            ...BASIC_PASSWORD_SCHEMA,
            token: Yup.string().required(),
        }),
    };

    const getInitialValues = {
        change: BASIC_PASSWORD_VALUES,
        reset: { ...BASIC_PASSWORD_VALUES, token },
    };

    const getPasswordFormType = () => (token ? FORM_TYPES.reset : FORM_TYPES.change);

    const confirmChanges = (payload) => {
        if (token) {
            resetPassword(payload)
                .then((res) => {
                    if (res.success) {
                        navigate(NAV_LINKS.login);
                        dispatch(openAlertSuccess(res.success));
                    } else {
                        dispatch(openAlertError(res.error));
                    }
                })
                .catch(() => {
                    setSubmitting(false);
                    dispatch(openAlertError());
                });
        } else {
            changePassword(payload)
                .then((res) => {
                    if (res.success) {
                        navigate(NAV_LINKS.userProfile);
                        dispatch(openAlertSuccess('passwordUpdated'));
                    } else {
                        dispatch(openAlertError(res.error));
                    }
                })
                .catch(() => {
                    dispatch(openAlertError());
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    const formik = useFormik({
        initialValues: getInitialValues[getPasswordFormType()],
        validationSchema: getPasswordSchema[getPasswordFormType()],
        onSubmit: confirmChanges,
    });

    const {
        errors,
        touched,
        isValid,
        setFieldTouched,
        handleSubmit,
        handleBlur,
        getFieldProps,
        isSubmitting,
        setSubmitting,
    } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const isFormValid = () => isValid && Object.keys(touched).length;

    const renderPasswordFields = () =>
        Object.keys(BASIC_PASSWORD_VALUES).map((passwordField) => (
            <TextField
                key={passwordField}
                id={passwordField}
                name={passwordField}
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label={t(camelCase(passwordField))}
                onBlur={handleBlur}
                {...getFieldProps(passwordField)}
                onInput={() => setFieldTouched(passwordField, true, true)}
                InputProps={
                    passwordField === PASSWORD_FORM_FIELDS.password
                        ? {
                              endAdornment: (
                                  <InputAdornment position="end">
                                      <IconButton onClick={handleShowPassword} edge="end">
                                          <Iconify
                                              icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                                          />
                                      </IconButton>
                                  </InputAdornment>
                              ),
                          }
                        : {}
                }
                error={Boolean(touched[passwordField] && errors[passwordField])}
                helperText={touched[passwordField] && errors[passwordField]}
            />
        ));

    return (
        <FormikProvider value={formik}>
            <Form
                title={makeTitle({ formType, category })}
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit}
            >
                <MarginInline size={2}>
                    <Stack mt={4} spacing={3}>
                        {renderPasswordFields()}
                        <FormButtons
                            navLink={NAV_LINKS.userProfile}
                            formType={FORM_TYPES.save}
                            isFormValid={!!isFormValid()}
                            disabled={isSubmitting}
                            isLoading={isSubmitting}
                        />
                    </Stack>
                </MarginInline>
            </Form>
        </FormikProvider>
    );
};

PasswordForm.propTypes = {
    token: PropTypes.string,
};

export default PasswordForm;
