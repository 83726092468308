import { Box, Divider, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { closeAlert, openAlertError } from '../../actions/alertActions';
import { LOCAL_STORAGE_KEY, NAV_LINKS } from '../../utils/constants';
import { logoutUser } from '../../utils/fetchData';
import { isComplexUserData } from '../../utils/helpers';
import MENU_OPTIONS from '../../utils/menuOptions';
import MenuPopover from '../MenuPopover/MenuPopover';
import UserAvatar from '../UserAvatar/UserAvatar';

const AccountPopover = () => {
    const [open, setOpen] = useState(null);
    const { t } = useTranslation();
    const anchorRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userReducer);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const clearData = () => {
        localStorage.removeItem(LOCAL_STORAGE_KEY.token);
        navigate(NAV_LINKS.login, { replace: true });
        handleClose();
    };

    const logout = () => {
        dispatch(closeAlert());
        logoutUser()
            .then((res) => {
                if (res.error) {
                    dispatch(openAlertError(res.error));
                }
            })
            .catch(() => dispatch(openAlertError()))
            .finally(clearData);
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.5),
                        },
                    }),
                }}
            >
                <UserAvatar size={'small'} name={user.first_name} surname={user.last_name} />
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    {isComplexUserData(user) && (
                        <Typography variant="subtitle2" noWrap>
                            {`${user.first_name} ${user.last_name}`}
                        </Typography>
                    )}
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem
                            key={option.label}
                            to={option.linkTo}
                            component={RouterLink}
                            onClick={handleClose}
                        >
                            {t(option.label)}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={logout} sx={{ m: 1 }}>
                    {t('logout')}
                </MenuItem>
            </MenuPopover>
        </>
    );
};

export default AccountPopover;
