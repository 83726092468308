import { DATA_TYPES, TABLE_SETTINGS } from '../../../utils/constants';
import Table from '../../Table/Table';

const ParticipantGroupsTable = () => (
    <Table
        dataType={DATA_TYPES.participantGroup}
        tableName={TABLE_SETTINGS.participantGroup.name}
        tableHead={TABLE_SETTINGS.participantGroup.tableHead}
    />
);

export default ParticipantGroupsTable;
