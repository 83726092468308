import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
`;

export const StyledMediaInput = styled.div`
    display: flex;
    gap: 2rem;
    align-items: center;
    margin-block: 1rem;
`;

export const WysiwygWrapper = styled.div`
    width: 90%;

    ${({ isPreview }) =>
        isPreview &&
        css`
            ul,
            ol {
                margin-left: 1.5rem;
            }
            a {
                color: ${({ theme }) => theme.palette.blue};
                text-decoration: underline;
            }
            width: 100%;
        `}
    ${({ mobile }) =>
        mobile &&
        css`
            max-width: 420px;
            width: 100%;
        `}

    textarea {
        resize: none;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 1.75rem;

    * {
        font-size: 1rem;
    }
`;
